import React, { Component } from "react";
import { AuthUserContext } from "../Session";
import {
  Container,
  Grid,
  TextField,
  FormControl,
  Fade,
  FormHelperText,
  LinearProgress,
} from "@material-ui/core";
import CustomButton from "../Button";
import Helpers from "../Helpers";
import AccountProfileFunction from "./AccountProfileFunction";
import ReusableModal from "./ReusableModal";

import PhoneInput from "react-phone-input-2";

class EditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.edit_account.firstName,
      lastName: props.edit_account.lastName,
      email: props.edit_account.email,
      mobileNumber: props.edit_account.mobileNumber,
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      mobileNumberError: "",
      isLoading: false,
      showModal: false,
      modalButton: "",
      modalTitle: "",
      modalContent: "",
    };
    this.helpers = new Helpers();
    this.accountProfileFunction = new AccountProfileFunction();
  }

  onChange = (event) => {
    const me = this;
    this.setState({ [event.target.name]: event.target.value }, () => {
      var result = this.helpers.validator(me.state, event.target.name);
      me.setState({ [result.name]: result.error });
    });
  };


  update = (type) => {
    if (type === "success") {
      window.location.reload();
    }
    this.setState({ showModal: false, isLoading: false });
  };

  submit = (e) => {
    e.preventDefault();
    const uid = this.context.uid;
    const { email, mobileNumber } = this.state;
    this.setState({ isLoading: true });
    this.accountProfileFunction
      .doUpdateAccount(uid, email, mobileNumber)
      .then((response) => {
        var modalButton = (
          <CustomButton
            type="button"
            text="Okay"
            buttonTheme="success"
            onClick={() => this.update("success")}
          />
        );
        if (response.data.ok) {
          this.setState({
            modalTitle: "Success",
            modalContent: "Thank you for updating your mobile number!",
            modalButton: modalButton,
            showModal: true,
          });
        } else {
          this.setState({
            modalButton: modalButton,
            modalTitle: "Warning!",
            modalContent: response.data.message,
            showModal: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        var modalButton = (
          <CustomButton
            type="button"
            text="Try Again"
            buttonTheme="danger"
            onClick={() => this.update("error")}
          />
        );
        this.setState({
          modalButton: modalButton,
          modalTitle: "Warning!",
          modalContent: error.message,
          showModal: true,
        });
      });
  };

  static contextType = AuthUserContext;

  render() {
    const {
      firstName,
      lastName,
      email,
      mobileNumber,
      firstNameError,
      lastNameError,
      mobileNumberError,
      emailError,
      showModal,
      modalTitle,
      modalContent,
      modalButton,
      isLoading,
    } = this.state;
    return (
      <>
        <ReusableModal
          show={showModal}
          title={modalTitle}
          content={modalContent}
          button={modalButton}
        />
        <form onSubmit={this.submit}>
          <Container>
            <h1>Edit Account</h1>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <label className="dark-label bold">First Name</label>
                <TextField
                  name="firstName"
                  value={firstName}
                  inputProps={{ style: { padding: "12px" } }}
                  variant="outlined"
                  fullWidth
                  required
                  placeholder="Your first name..."
                  autoComplete="off"
                  onChange={this.onChange}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Fade in={true}>
                  <FormHelperText style={{ color: "red" }}>
                    {firstNameError}
                  </FormHelperText>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <label className="dark-label bold">Last Name</label>
                <TextField
                  name="lastName"
                  value={lastName}
                  inputProps={{ style: { padding: "12px" } }}
                  variant="outlined"
                  fullWidth
                  required
                  placeholder="Your last name..."
                  autoComplete="off"
                  onChange={this.onChange}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {lastNameError}
                </FormHelperText>
              </Fade>
            </Grid>
            <br /> <br />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <label className="dark-label bold">Email Address</label>
                <TextField
                  type="email"
                  name="email"
                  value={email}
                  inputProps={{ style: { padding: "12px" } }}
                  variant="outlined"
                  fullWidth
                  required
                  placeholder="Your email address..."
                  autoComplete="off"
                  onChange={this.onChange}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Fade in={true}>
                  <FormHelperText style={{ color: "red" }}>
                    {emailError}
                  </FormHelperText>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <label className="dark-label bold">Mobile Number</label>
                <FormControl fullWidth>
                  <PhoneInput
                  disabled
                    country={"ph"}
                    masks={{
                      ph: "(...) ... ....",
                    }}
                    containerStyle={{
                      width: "100%",
                    }}
                    inputStyle={{
                      width: "100%",
                      padding: "8px 0px 8px 50px",
                    }}
                    placeholder="Mobile Number"
                    inputClass="mobile-number-input"
                    value={mobileNumber}
                    specialLabel=""
                  
                  />
                </FormControl>
                <Fade in={true}>
                  <FormHelperText style={{ color: "red" }}>
                    {mobileNumberError}
                  </FormHelperText>
                </Fade>
              </Grid>
            </Grid>
            <br /> <br />
            {!isLoading ? (
              <CustomButton
                text="Save Changes"
                type="submit"
                className="float-right"
              />
            ) : (
              <LinearProgress color="primary" />
            )}
          </Container>
        </form>
      </>
    );
  }
}

export default EditAccount;
