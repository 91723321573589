import axios from "axios";
import * as ROUTES from "../../constants/route";
import Helpers from "../Helpers";
import * as uuid from "uuid";

export default class Functions {
  constructor() {
    this.helpers = new Helpers();
  }

  doUploadDepositSlip = (data) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("req", JSON.stringify({ fundId: data.transactionId }));
      formData.append("picture", data.picture);
      axios
        .post(
          `${ROUTES.FUNCTION_BASE_URL}investments-investments/uploadDepositSlip`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  doRedeemInvestment = (data) => {
    const settlementDetails = data.accountDetails
      ? {
          fundCode: data.fundCode,
          shares: Number(data.shares),
          companyId: data.companyId,
          userUid: data.userUid,
          uuid: uuid.v4(),
          investmentUid: data.investmentUid,
          rateUid: data.rateUid,
          surveyData: data.surveyData,

          // bank settlement
          accountName: data.accountName,
          bankName: data.bankName,
          bankAccountNumber: data.bankAccountNumber,
          savingsAccount: data.savingAccount,
        }
      : {
          fundCode: data.fundCode,
          shares: Number(data.shares),
          companyId: data.companyId,
          userUid: data.userUid,
          uuid: uuid.v4(),
          investmentUid: data.investmentUid,
          rateUid: data.rateUid,
          surveyData: data.surveyData,
        };
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${ROUTES.FUNCTION_BASE_URL}investments-investments/redeemInvestment`,
          JSON.stringify(settlementDetails),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {

          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
