import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';

import mutualFundsImg from "../../assets/landing/guide-to-mutual-funds.png";
import longTermImg from "../../assets/landing/long-term-investing.png";
import firstMilImg from "../../assets/landing/first-mil.png";
import timeImg from "../../assets/landing/time-is-money.png";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import Button from '../ui/Button';


import { COLOR_SUCCESS, COLOR_PRIMARY } from "../../constants/colors";

const ArticleCarousel = () => {
  const responsive = {
    0: { items: 1 },
    768: { items: 3 }
  };
  const articles = [
    {
      imageSrc: firstMilImg,
      title: "Achieve your First Million!",
      subtitle: "Actionable Steps to Help You Become a Millionaire",
      href: "https://rampver.com/reads/article/achieve-your-first-million-actionable-steps.html"
    },
    {
      imageSrc: longTermImg,
      title: "Long-Term Investing: The Key to Financial Success",
      subtitle: "The Key to Financial Success",
      href: "https://rampver.com/reads/article/long-term-investing-key-financial-success.html"
    },
    {
      imageSrc: timeImg,
      title: "Time is Money!",
      subtitle: "Why Investing Can Pay Off in the Long Run",
      href: "https://rampver.com/reads/article/time-is-money.html"
    },
    {
      imageSrc: mutualFundsImg,
      title: "Your Guide to Mutual Funds",
      subtitle: "How Mutual Funds Really Work?",
      href: "https://rampver.com/reads/article/your-guide-to-mutual-funds.html"
    },
  ];

  return (
    <>
      <AliceCarousel
        autoHeight
        responsive={responsive}
        autoPlay
        autoPlayInterval={5000}
        prevButtonClassName
      >
        {articles.map((article, index) => (

          <Card key={index} style={{
            minHeight: '320px',
            margin: "0px 5px 25px"
          }}>
            <CardActionArea target="_blank" href={article.href}>
              <CardMedia component="img" alt={article.title} height={'auto'} width={'auto'} image={article.imageSrc} />
              <CardContent>
                <Typography gutterBottom variant="h6">
                  {article.title}
                </Typography>

                <Typography gutterBottom variant="subtitle2">
                  {article.subtitle}
                </Typography>

                <Button
                  target="_blank"
                  href={article.href}
                  variant={'text'}
                  size="small"
                  style={{
                    color: COLOR_PRIMARY
                  }}
                >
                  Read Article &gt;&gt;&gt;
                </Button>
              </CardContent>
            </CardActionArea>
          </Card>

        ))}
      </AliceCarousel>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          target="_blank" 
          href={`https://rampver.com/reads`}
          size="large"
          style={{
            backgroundColor: COLOR_SUCCESS,
            paddingInline: "4em"
          }}
        >
          Click here to see more articles
        </Button>
      </div>
    </>

  );
};

export default ArticleCarousel;