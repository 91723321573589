import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";

import recurringBG from "../../../assets/recurringPage/subheader.png";
import Automated from "../../../assets/recurringPage/Automated Investing.png";
import Compounding from "../../../assets/recurringPage/Compounding Returns Over Time.png";
import Peso from "../../../assets/recurringPage/Peso-cost Averaging.png";
import { RecurringLandingPage, header } from "./RecurringHelper";
import FAQsData from "./FAQsData";
import Button from "../../ui/Button";
import InvestmentForm from "./investmentForm";

export const RecurringPaymentInfo = ({ firebase, token, history }) => {
  const { state } = useLocation();
  const [dropDown, setDropDown] = useState(false);
  const [openFAQs, setOpenFAQs] = useState(false);

  const uid = firebase.db._delegate._authCredentials.currentUser.uid;

  const handleOpenFAQs = (e) => {
    e.preventDefault();
    setOpenFAQs(true);
  };
  const handleCloseFAQs = (e) => {
    e.preventDefault();
    setOpenFAQs(false);
  };

  const iconsImage = (image, title) => {
    return (
      <div className="flex items-center justify-center flex-col">
        <img src={image} alt={title} height={90} width={90} />
        <div className="font-poppins text-center font-bold text-xs text-white">
          {title}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const checkTier = async () => {
      const { stats } = (
        await firebase.db.collection("users").doc(uid).get()
      ).data();

      const tier = stats.tier ?? 3;
      if (tier < 3) history.replace("/404");
    };

    checkTier();
  }, [uid]);

  return (
    <div className="body-content">
      {openFAQs ? (
        <FAQsData close={handleCloseFAQs} />
      ) : (
        <>
          {!dropDown && (
            <>
              {header}
              <div
                style={{
                  position: "relative",
                  margin: "25px -45px 25px -45px",
                  background: `url('${recurringBG}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
                className="w-auto h-desktop 2xl:h-xxldesktop py-2 px-5 lg:pt-5 lg:px-10"
              >
                <div className=" font-poppins font-bold text-2xl lg:text-3xl text-white my-0">
                  Hassle-free investing.
                </div>

                <h2 className=" font-poppins font-light text-base lg:text-lg text-white max-w-xs">
                  Automate your investment journey with Rampver Online.
                </h2>
                <Grid
                  container
                  justify="center"
                  spacing={2}
                  className="relative top-0 xl:px-20 2xl:px-32 xl:top-2 2xl:top-4 flex justify-center"
                >
                  <Grid item lg={3} md={3} sm={4} xs={4}>
                    {iconsImage(Automated, "Automated Investing")}
                  </Grid>
                  <Grid item lg={3} md={3} sm={4} xs={4}>
                    {iconsImage(Peso, "Peso-cost Averaging")}
                  </Grid>
                  <Grid item lg={3} md={3} sm={4} xs={4}>
                    {iconsImage(Compounding, "Compounding Returns Over Time")}
                  </Grid>
                </Grid>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "20px -20px 0px -20px",
                }}
              >
                <span className=" font-poppins font-extrabold text-xl text-center">
                  Just register once and automatically add to your investment
                  every month!
                </span>
              </div>
              <div
                style={{
                  margin: "0px -45px 0px -45px",
                }}
              >
                <RecurringLandingPage>
                  Here’s how it works: Enter your investment schedule, register
                  your BPI Online account, and agree to the Terms & Conditions.
                  We will confirm your registration and book your investments
                  every month! Just make sure your BPI account is live and
                  funded.
                </RecurringLandingPage>

                <Grid
                  container
                  justify="center"
                  spacing={2}
                  style={{ marginTop: "20px" }}
                >
                  <Grid item lg={6} md={3} sm={6} xs={12}>
                    <div className=" text-right px-10 lg:px-0">
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        className=" w-full 2xl:w-64 xl:w-60 lg:w-40 top-4 "
                        style={{ border: "2px solid #246040" }}
                        onClick={handleOpenFAQs}
                      >
                        <div className="text-green-800 text-base font-bold">
                          View FAQs
                        </div>
                      </Button>
                    </div>
                  </Grid>

                  <Grid item lg={6} md={3} sm={6} xs={12}>
                    <div className=" text-left px-10 lg:px-0">
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className=" w-full 2xl:w-64 xl:w-60 lg:w-40 top-4  "
                        onClick={() => setDropDown(true)}
                      >
                        <div className="text-white text-base font-bold">
                          Proceed to Registration
                        </div>
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          )}

          {dropDown && (
            <InvestmentForm
              fromPayment={state === undefined ? "" : state.fromPayment}
              initialFund={state === undefined ? "" : state.fundValue}
              amount={state === undefined ? "" : state.investedValue}
              minInvested={state === undefined ? "" : state.minInitialValue}
              firebase={firebase}
              backDropDown={() => setDropDown(false)}
              token={token}
            />
          )}
        </>
      )}
    </div>
  );
};
