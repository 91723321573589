import React from "react";
import { Container } from "@material-ui/core";

function AccountOverview(props) {
  var renderAccountOverview = (
    <tr>
      <td colSpan="2">No Data Available</td>
    </tr>
  );
  if (props.account_overview !== undefined) {
    renderAccountOverview = props.account_overview.map((acc, index) => (
      <tr key={index}>
        <td>{acc.title}</td>
        <td>{acc.value}</td>
      </tr>
    ));
  }

  return (
    <>
      <Container>
        <h1>Account Overview</h1>
        <div className="fund-details">
          <table className="tbl-border-bottom">
            <tbody>{renderAccountOverview}</tbody>
          </table>
        </div>
      </Container>
    </>
  );
}

export default AccountOverview;
