import React from "react";

function AccordionContent(props) {
  const { table } = props;
  const renderTable = table.map((data, index) => {
    if (data.header === undefined) {
      return (
        <tr key={index}>
          <td>{data.title}</td>
          <td>{data.value}</td>
        </tr>
      );
    } else {
      return (
        <tr key={index}>
          <td colSpan="2" className="header">
            {data.title}
          </td>
        </tr>
      );
    }
  });
  return (
    <>
      <table className="my-application">
        <tbody>{renderTable}</tbody>
      </table>
    </>
  );
}

export default AccordionContent;
