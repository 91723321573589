import React, { Component } from "react";
import { Container, Grid, LinearProgress } from "@material-ui/core";
import CustomButton from "../Button";
import AlertBox from "../Alert";
import * as ROUTES from "../../constants/route";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Validators from "../Helpers/validators";

class ForgotPasswordWidget extends Component {
  constructor(props) {
    super(props);
    this.validators = new Validators();
  }

  componentDidMount() {
    this.validators.mount();
  }

  componentWillUnmount() {
    this.validators.unmount();
  }

  handleChange = (event) => {
    this.props.onPasswordChange(event);
  };

  render() {
    const {
      email,
      toggleAlert,
      severity,
      messageAlert,
      loading,
      submitDisabled,
    } = this.props.state;
    var props = this.props;
    return (
      <>
        <LinearProgress style={{ display: loading ? "block" : "none" }} />
        <Container component="main" maxWidth="xs">
          <div className="sign-paper">
            <ValidatorForm
              onSubmit={props.onSubmit()}
              ref={(r) => (this.form = r)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <label className="signup-title">Password Recovery</label>
                  <p className="sub-title">
                    Remembered it?&nbsp;
                    <a href={ROUTES.SIGN_IN}>Return to log in here!</a>
                  </p>
                  {toggleAlert && (
                    <AlertBox severity={severity} message={messageAlert} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label className="dark-label">Email Address</label>
                  <TextValidator
                    placeholder="Enter your email address"
                    onChange={props.onChange()}
                    name="email"
                    value={email}
                    validators={["required", "isEmail"]}
                    variant="outlined"
                    inputProps={{ style: { padding: "12px 0px 12px 12px" } }}
                    errorMessages={[
                      "This field is required.",
                      "Invalid Email Address",
                    ]}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <CustomButton
                    text="Send reset email"
                    disabled={submitDisabled}
                    type="submit"
                  />
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>
        </Container>
      </>
    );
  }
}

export default ForgotPasswordWidget;
