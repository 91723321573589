import React, { Component } from "react";
import { AuthUserContext } from "../Session";
import {
  Container,
  Grid,
  TextField,
  Divider,
  Fade,
  FormHelperText,
  LinearProgress,
} from "@material-ui/core";
import CustomButton from "../Button";
import Helpers from "../Helpers";
import ReusableModal from "./ReusableModal";

class EditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      password: "",
      confirmPassword: "",
      currentPasswordError: "",
      passwordError: "",
      confirmPasswordError: "",
      isLoading: false,
      showModal: false,
      modalButton: "",
      modalTitle: "",
      modalContent: "",
    };
    this.helpers = new Helpers();
  }

  static contextType = AuthUserContext;

  onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value }, () => {
        var result = this.helpers.validator(this.state, name);
        if (name === "password") {
            var confirm_pass = this.helpers.validator(this.state, "confirmPassword");
            this.setState({ [confirm_pass.name]: confirm_pass.error });
        }
        this.setState({ [result.name]: result.error });
    });
  };

  reAuthenticate = (currentPassword) => {
    var user = this.props.props.firebase.auth.currentUser;
    var cred = this.props.props.firebase.emailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(cred);
  };

  handleClose = () => {
    this.setState({ showModal: false, isLoading: false });
  };

  submit = (e) => {
    e.preventDefault();
    var me = this;
    const {
      currentPassword,
      password,
      currentPasswordError,
      passwordError,
      confirmPasswordError,
    } = this.state;
    const isValid =
      currentPasswordError === "" &&
      passwordError === "" &&
      confirmPasswordError === "";

    if (isValid) {
      var user = this.props.props.firebase.auth.currentUser;
      this.setState({ isLoading: true });
      this.reAuthenticate(currentPassword)
        .then(() => {
          return user.updatePassword(password);
        })
        .then(() => {
          var modalButton = (
            <CustomButton
              type="button"
              text="Okay"
              buttonTheme="success"
              onClick={me.handleClose}
            />
          );
          this.setState({
            modalButton: modalButton,
            modalTitle: "Success!",
            modalContent:
              "Looks good! You have successfully changed your password.",
            showModal: true,
            currentPassword: "",
            password: "",
            confirmPassword: "",
          });
        })
        .catch((error) => {
          let errorMessage = error.message;
          if (error.code === "auth/wrong-password")
            errorMessage = "You have entered an invalid password.";

          var modalButton = (
            <CustomButton
              type="button"
              text="Try Again"
              buttonTheme="danger"
              onClick={me.handleClose}
            />
          );
          this.setState({
            modalButton: modalButton,
            modalTitle: "Oops! Please try again.",
            modalContent: errorMessage,
            showModal: true,
            currentPassword: "",
            password: "",
            confirmPassword: "",
          });
        });
    }
  };

  render() {
    const {
      currentPassword,
      password,
      confirmPassword,
      currentPasswordError,
      passwordError,
      confirmPasswordError,
      showModal,
      modalTitle,
      modalContent,
      modalButton,
      isLoading,
    } = this.state;
    // console.log(showModal);
    return (
      <>
        <ReusableModal
          show={showModal}
          title={modalTitle}
          content={modalContent}
          button={modalButton}
        />
        <Container>
          <h1>Change Password</h1>
          <form onSubmit={this.submit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="dark-label bold">Current Password</label>
                <TextField
                  type="password"
                  name="currentPassword"
                  value={currentPassword}
                  inputProps={{ style: { padding: "12px" } }}
                  variant="outlined"
                  fullWidth
                  required
                  placeholder="Your current password..."
                  autoComplete="off"
                  onChange={this.onChange}
                />
                <Fade in={true}>
                  <FormHelperText style={{ color: "red" }}>
                    {currentPasswordError}
                  </FormHelperText>
                </Fade>
              </Grid>
            </Grid>
            <br /> <br />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="dark-label bold">New Password</label>
                <TextField
                  type="password"
                  name="password"
                  value={password}
                  inputProps={{ style: { padding: "12px" } }}
                  variant="outlined"
                  fullWidth
                  required
                  placeholder="Your current password..."
                  autoComplete="off"
                  onChange={this.onChange}
                />
                <Fade in={true}>
                  <FormHelperText style={{ color: "red" }}>
                    {passwordError}
                  </FormHelperText>
                </Fade>
              </Grid>
            </Grid>
            <br /> <br />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label className="dark-label bold">Confirm Password</label>
                <TextField
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  inputProps={{ style: { padding: "12px" } }}
                  variant="outlined"
                  fullWidth
                  required
                  placeholder="Confirm your password..."
                  autoComplete="off"
                  onChange={this.onChange}
                />
                <Fade in={true}>
                  <FormHelperText style={{ color: "red" }}>
                    {confirmPasswordError}
                  </FormHelperText>
                </Fade>
              </Grid>
            </Grid>
            <br /> <br />
            <Divider />
            <br /> <br />
            {!isLoading ? (
              <CustomButton text="Save Changes" type="submit" />
            ) : (
              <LinearProgress color="primary" />
            )}
          </form>
        </Container>
      </>
    );
  }
}

export default EditAccount;
