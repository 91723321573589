import React, { Component } from "react";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import NavigationMenus from "../NavigationMenus";
import {
  Breadcrumbs,
  Paper,
  Tabs,
  Tab,
  Typography,
  Link,
  Fade,
} from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import { AccountBalanceOutlined } from "@material-ui/icons";
import { TabPanel } from "./TabPanel";
import { AuthUserContext } from "../Session";
import FundCard from "./FundCard";
import { ModalAlertFunctional } from "./ModalAlert";

const InvestmentByCompanyPage = () => {
  return (
    <>
      <NavigationMenus content={<InvestmentByCompany />} />
    </>
  );
};

class InvestmentByCompanyBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      recommendedFunds: [],
      individualFunds: [],
      fundRates: [],
      company: null,
      disclaimerOpen: false,
      docId: "",
    };
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    window.scrollTo(0, 0);

    this.fetchUser();
  }

  fetchUser() {
    const uid = this.context.uid;
    const usersRef = this.props.firebase.db.collection("users");

    usersRef
      .doc(uid)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        this.setState({ user: data }, () => {
          this.fetchCompany();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchCompany = () => {
    const companyRef = this.props.firebase.db.collection("fundCompanies");
    let companyId = this.props.match.params.id;

    companyRef
      .doc(companyId)
      .get()
      .then((snapshot) => {
        let data = snapshot.data();
        data.id = snapshot.id;
        this.setState({ company: data }, () => {
          this.fetchAllFunds();
        });
      });
  };

  fetchAllFunds = () => {
    const { company } = this.state;
    const fundRef = this.props.firebase.db.collection("funds");
    fundRef
      .where("fundCompanyDetails.fundCompanyId", "==", company.id)
      .orderBy("usersInvested", "desc")
      .get()
      .then((snapshot) => {
        const data = snapshot.docs;
        return data;
      })
      .then((data) => {
        var funds = [];
        const fundRatesRef = this.props.firebase.db.collection("fundRates");

        fundRatesRef
          .orderBy("date", "asc")
          .limitToLast(1)
          .get()
          .then((snapshot) => {
            const fundRates = snapshot.docs[0].data();
            this.setState({ fundRates: fundRates });
          })
          .then(() => {
            data.forEach((fund) => {
              const fundName = fund.data().name;
              let fundId = fund.id;
              const { fundRates } = this.state;

              const fundRatePerFund = fundRates.fundDetails.find(
                (x) => x.fundName === fundName
              );

              if (fundRatePerFund !== undefined) {
                funds.push({
                  id: fundId,
                  name: fundName,
                  riskType: fund.data().riskType,
                  minInitialInvestment: fund.data().minInitialInvestment,
                  maxInitialInvestment: fund.data().maxInitialInvestment,
                  minAdditionalInvestment: fund.data().minAdditionalInvestment,
                  pricePerShare: fundRatePerFund.rate,
                  ytd: fundRatePerFund.ytd,
                });
              }

              this.setState({ individualFunds: funds }, () => {
                this.fetchRecommendedFunds();
              });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchRecommendedFunds = () => {
    const { user, individualFunds } = this.state;

    if (user !== null) {
      const fundPerRiskProle = [];
      if ("hasLongKYC" in user) {
        individualFunds.forEach((item) => {
          if (item.riskType === user.riskProfile) {
            fundPerRiskProle.push(item);
            this.setState({ recommendedFunds: fundPerRiskProle });
          }
        });
      } else {
        if (individualFunds.length > 3) {
          this.setState({ recommendedFunds: individualFunds.slice(0, 3) });
        } else {
          this.setState({ recommendedFunds: individualFunds });
        }
      }
    }
  };

  tabChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  handleInvestAgree = () => {
    this.setState({ disclaimerOpen: false }, () => {
      this.props.history.push(ROUTES.FUNDS + "/" + this.state.docId);
    });
  };

  handleInvestDisagree = () => {
    this.setState({ disclaimerOpen: false });
  };

  redirectFund = (id, fundRiskType) => {
    this.setState({ docId: id });
    const { user } = this.state;

    if (fundRiskType === user.riskProfile || user.riskProfile === undefined) {
      this.handleInvestAgree(id);
    } else {
      this.setState({ disclaimerOpen: true });
    }
  };

  stringToWhiteSpaceConverter = (text) => {
    let returnText = text.replace(/\/n /gi, `\n`);
    return returnText;
  };

  render() {
    document.body.style.backgroundColor = "white";
    const { tab, recommendedFunds, individualFunds, company, disclaimerOpen } =
      this.state;

    return (
      <>
        <ModalAlertFunctional
          function={() => this.handleInvestDisagree()}
          open={disclaimerOpen}
          validate={true}
          message="You are trying to select not recommended fund. Would you like to continue?"
          executeFuncOnAgree={this.handleInvestAgree}
        />
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textSecondary" href={ROUTES.DASHBOARD}>
            Dashboard
          </Link>
          <Link color="textSecondary" href={ROUTES.MY_INVESTMENTS}>
            Investing
          </Link>
          <Typography
            color="textPrimary"
            to={ROUTES.MY_INVESTMENTS}
            aria-current="page"
          >
            {company !== null ? company.name : ""}
          </Typography>
        </Breadcrumbs>
        <div className="body-content">
          <div className="inv-by-category">
            <div className="title">
              <div className="icon-category rampver-bgColor">
                <AccountBalanceOutlined fontSize="large" />
              </div>
              <div className="title-name">
                <h1>{company !== null ? company.name : ""}</h1>
                <span style={{ "white-space": "pre-line" }}>
                  {company !== null
                    ? this.stringToWhiteSpaceConverter(company.description)
                    : ""}
                </span>
              </div>
            </div>
            <br /> <br />
            <Paper square>
              <Tabs
                value={tab}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={this.tabChange}
                className="custom-tabs"
              >
                <Tab label="Recommended" />
                <Tab label="All Funds" />
              </Tabs>
              <TabPanel value={tab} index={0}>
                <Fade in={true}>
                  <FundCard
                    funds={recommendedFunds}
                    redirectFund={this.redirectFund}
                  />
                </Fade>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Fade in={true}>
                  <FundCard
                    funds={individualFunds}
                    redirectFund={this.redirectFund}
                  />
                </Fade>
              </TabPanel>
            </Paper>
          </div>
        </div>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const InvestmentByCompany = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(InvestmentByCompanyBase);

export default InvestmentByCompanyPage;

export { InvestmentByCompany };
