// https://v4.mui.com/components/snackbars/#snackbar
import { createContext, useState, ReactNode, SyntheticEvent } from "react";
import Alert from "@/components/ui/Alert";
import { Color } from "@material-ui/lab";

const ThemeContext = createContext({
  // todo: find a better way to do this without disabling eslint
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  notify: (message: string, severity: Color) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClose: () => {},
});

type Notification = {
  message: string;
  severity: Color;
};

export function NotificationProvider({ children }: { children: ReactNode }) {
  const [notification, setNotification] = useState<Notification | null>(null);

  const notify = (message: string, severity: Color) => {
    setNotification({ message, severity });
  };

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification(null);
  };

  return (
    <ThemeContext.Provider value={{ notify, handleClose }}>
      <>
        {children}
        <Alert
          open={!!notification}
          onClose={handleClose}
          severity={notification?.severity}
        >
          {notification?.message}
        </Alert>
      </>
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
