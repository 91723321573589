import React from "react";
import {
  Container,
  Divider,
  Link,
  makeStyles,
  Grid,
  SvgIcon,
  IconButton,
} from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import SECLogo from "../../contents/img/SEC.png";
import InsuLogo from "../../contents/img/Insurance.png";
import NPCLogo from "../../contents/img/NPC.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import MGIIcon from "../../contents/img/MGI_poweredby_logo1.png";

import { ReactComponent as ViberSVG } from "../../contents/img/Viber.svg";
import { ReactComponent as AMLCSVG } from "../../contents/img/AMLC.svg";

const useStyles = makeStyles(() => ({
  link: {
    color: "#C8932A",
    fontWeight: "bold",
  },
  iconWhite: {
    color: "#ffffff",
  },
}));
export const LoginRegistration = (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} />
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ textAlign: "center" }}
    >
      <b>Rampver Financials is registered with:</b>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <img
          src={SECLogo}
          alt="SEC-logo"
          style={{ width: "85px", height: "auto", marginRight: "15px" }}
        />
        <img
          src={InsuLogo}
          alt="Insurance-logo"
          style={{ width: "50px", height: "auto", marginRight:"10px" }}
        />
        <SvgIcon style={{ width: "70px", height: "auto", color: "black" }}>
          <AMLCSVG />
        </SvgIcon>
        <img
          src={NPCLogo}
          alt="NPC-logo"
          style={{ width: "100px", height: "auto" }}
        />
      </div>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} />
  </Grid>
);
const Registration = (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} />
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={6}
      xl={6}
      style={{ textAlign: "center" }}
    >
      <b>Rampver Financials is registered with:</b>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <img
          src={SECLogo}
          alt="SEC-logo"
          style={{ width: "85px", height: "auto", marginRight: "15px" }}
        />
        <img
          src={InsuLogo}
          alt="Insurance-logo"
          style={{ width: "50px", height: "auto" }}
        />
        <SvgIcon style={{ width: "70px", height: "auto", color: "black" }}>
          <AMLCSVG />
        </SvgIcon>
        <img
          src={NPCLogo}
          alt="NPC-logo"
          style={{ width: "100px", height: "auto" }}
        />
      </div>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} />
  </Grid>
);

function TermsAndConditions() {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ textAlign: "center", paddingTop: "10px" }}
    >
      <Link className={classes.link} href={ROUTES.TERMS_AND_CONDITIONS}>
        Terms and Conditions
      </Link>
      <span style={{ color: "#C8932A" }}> | </span>
      <Link className={classes.link} href={ROUTES.PRIVACY_POLICY}>
        Privacy Policy
      </Link>
      <br />
      <br />
      <a
        href="http://mobilegroupinc.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={MGIIcon}
          alt="MGI-logo"
          style={{ width: "130px", height: "auto" }}
        />
      </a>
    </Grid>
  );
}

function Socials() {
  const classes = useStyles();
  return (
    <div>
      <IconButton
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/rampverfinancials"
      >
        <FacebookIcon className={classes.iconWhite} />
      </IconButton>
      <IconButton
        target="_blank"
        rel="noopener noreferrer"
        href="https://instagram.com/rampverfinancials"
      >
        <InstagramIcon className={classes.iconWhite} />
      </IconButton>
      <IconButton
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.viber.com/"
      >
        <SvgIcon>
          <ViberSVG className={classes.iconWhite} />
        </SvgIcon>
      </IconButton>
    </div>
  );
}

function Footer({ isSignIn, color }) {
  let year = new Date();
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <>
      {isSignIn ? (
        <div style={{ paddingTop: 30 }}>
          <Divider />
          <Container maxWidth="lg">
            <div>
              <div className="copyright">
                <Link
                  className={classes.link}
                  href={ROUTES.TERMS_AND_CONDITIONS}
                >
                  Terms and Conditions
                </Link>
                {" | "}
                <Link className={classes.link} href={ROUTES.PRIVACY_POLICY}>
                  Privacy Policy
                </Link>
                <div style={{ color: color }}>
                  Copyright {year.getFullYear()} Rampver Financials. All rights
                  reserved.
                </div>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <div style={{ padding: "10px 0px 0px" }}>
          <Divider />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {Registration}
            </Grid>
            <TermsAndConditions />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ backgroundColor: "#246040", width: "100%" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                  <div
                    style={{
                      float: "left",
                      color: "white",
                      paddingTop: "10px",
                    }}
                  >
                    <span style={{ fontSize: desktop ? "16px" : "10px" }}>
                      {" "}
                      Rampver Financials {year.getFullYear()} | All rights
                      reserved.
                    </span>
                  </div>
                </Grid>

                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                  <div style={{ float: "right" }}>
                    <Socials />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default Footer;
