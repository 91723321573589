import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

var config;

const devconfig = {
  apiKey: process.env.REACT_APP_API_KEY_DEV,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
  databaseURL: process.env.REACT_APP_DATABASE_URL_DEV,
  projectId: process.env.REACT_APP_PROJECT_ID_DEV,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
};

const nonprodConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const stagingConfig = {
  apiKey: process.env.REACT_APP_API_KEY_STAGING,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_STAGING,
  databaseURL: process.env.REACT_APP_DATABASE_URL_STAGING,
  projectId: process.env.REACT_APP_PROJECT_ID_STAGING,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_STAGING,
  messagingSenderId: process.env.REACT_APP_MESSAGING_STAGING,
};

const liveconfig = {
  apiKey: process.env.REACT_APP_API_KEY_LIVE,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_LIVE,
  databaseURL: process.env.REACT_APP_DATABASE_URL_LIVE,
  projectId: process.env.REACT_APP_PROJECT_ID_LIVE,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_LIVE,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_LIVE,
};

if (process.env.REACT_APP_FIREBASE_CONFIG === "nonprod") {
  config = nonprodConfig;
} else if (process.env.REACT_APP_FIREBASE_CONFIG === "live") {
  config = liveconfig;
} else if(process.env.REACT_APP_FIREBASE_CONFIG === "staging") {
  config = stagingConfig;
} else {
  config = devconfig;
}

const initializedApp = initializeApp(config);
export const db = getFirestore(initializedApp);
export const auth = getAuth(initializedApp);

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    }
    app.auth().setPersistence(app.auth.Auth.Persistence.SESSION);

    this.auth = app.auth();

    this.db = app.firestore();

    this.storage = app.storage().ref();

    this.googleProvider = new app.auth.GoogleAuthProvider();

    this.emailAuthProvider = app.auth.EmailAuthProvider;

    this.googleProvider.addScope(
      "https://www.googleapis.com/auth/contacts.readonly"
    );
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSendEmailVerificationLink = () =>
    this.auth.currentUser.sendEmailVerification();

  doSigninWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    this.auth.signOut();
  };

  doSendPasswordResetEmail = (email) => this.auth.sendPasswordResetEmail(email);

  doConfirmPasswordReset = (code, newPassword) =>
    this.auth.confirmPasswordReset(code, newPassword);

  doVerifyPasswordResetCode = (code) =>
    this.auth.verifyPasswordResetCode(code);

  getCustomClaims = () => this.auth.currentUser.getIdTokenResult();

  handleVerifyEmail = (actionCode) => this.auth.applyActionCode(actionCode);
}

export function getAPIKey() {
  return config.apiKey;
}

export default Firebase;
