import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    [theme.breakpoints.up("md")]: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    [theme.breakpoints.down("md")]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="custom-tabs-vertical"
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const mdUp = useMediaQuery(useTheme().breakpoints.up("md"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabs = props.tabs.map((tab, index) => (
    <Tab label={tab.title} icon={tab.icon} {...a11yProps(index)} key={index} />
  ));

  const renderTabPanels = props.tabs.map((tab, index) => (
    <TabPanel value={value} index={index} key={index}>
      {tab.content}
    </TabPanel>
  ));

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <Tabs
            orientation={mdUp ? "vertical" : "horizontal"}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            className={classes.tabs}
            scrollButtons="on"
          >
            {renderTabs}
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          {renderTabPanels}
        </Grid>
      </Grid>
    </div>
  );
}
