import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import React from 'react'
import CloseIcon from "@material-ui/icons/Close";

import Logo from "../../../images/Ramp_Logo.png";

const TermsCondition = ({ closeTerm, submitTerm }) => {
  return (
    <div>
      <Dialog
        open={open}
        maxWidth={"md"}
        scroll={"paper"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <img
            className="w-1/3 px-0 pt-1 md:w-auto md:px-3 md:pt-3 lg:w-auto lg:px-6 lg:pt-6"
            src={Logo}
            alt="rampver-logo"
          />
          <IconButton
            aria-label="close"
            onClick={closeTerm}
            style={{ float: "right", bottom: "10px", right: "-10px" }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <div className="px-0 text-xl text-black font-bold md:text-2xl md:px-3 lg:text-3xl lg:px-3">
            Rampver Online Monthly Recurring Investment Terms:
          </div>
        </DialogTitle>
        <DialogContent style={{ margin: "0px 20px 0px 20px" }}>
          <div>
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html:
                  ' * {margin:0; padding:0; text-indent:0; }\n h1 { color: #256141; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 24pt; }\n p { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; margin:0pt; }\n h2 { color: #256141; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 14pt; }\n .s1 { color: #15C; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }\n h3 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 12pt; }\n li {display: block; }\n #l1 {padding-left: 0pt; }\n #l1> li>*:first-child:before {content: "● "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }\n',
              }}
            />
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              In order to submit your Monthly Recurring Investment registration,
              you must first read, understand, and agree to the Rampver Online
              Monthly Recurring Investment Terms.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <h2
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Definition of Terms
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ul id="l1">
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "114%",
                    textAlign: "left",
                  }}
                >
                  “Monthly Recurring Investment” refers to the automatic debit
                  arrangement between the client and Rampver wherein the client
                  authorizes Rampver to collect from his BPI account and the
                  debited amount is invested in the client’s preferred fund on
                  his preferred schedule.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "114%",
                    textAlign: "left",
                  }}
                >
                  “BPI Electronic Auto Debit Arrangement” or “BPI eADA” is a
                  fully digitized platform that enables BPI clients to enroll
                  their accounts for scheduled payments with Rampver Online, a
                  BPI partner biller.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "114%",
                    textAlign: "left",
                  }}
                >
                  “Investment Amount” refers to the client’s chosen investment
                  amount on a selected fund to be booked on a monthly basis.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  “Fund Name” refers to the client’s selected fund to invest in
                  on a monthly basis.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: "2pt",
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "114%",
                    textAlign: "justify",
                  }}
                >
                  “Investment Schedule” refers to the client’s selected day in a
                  month, whether it is the 1st or 16th of the month for Rampver
                  Online to collect the client’s investment amount from his BPI
                  account on a monthly basis.
                </p>
              </li>
            </ul>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <h2
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Monthly Recurring Investment Timelines
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              Rampver will automatically debit your BPI account based on your
              registered schedule either on the 1st or 16th of the month. Once
              we have verified that your BPI account has been debited the
              registered investment amount, we will process your investment on
              the same day (1st or 16th of the month).
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              However, if the investment schedule falls on a weekend or holiday,
              Rampver will process your investment on the next business day.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "117%",
                textAlign: "left",
              }}
            >
              Example: You have registered your monthly recurring investment to
              invest P10,000 in Philequity Fund Inc. every 16th of the month.
              September 16, 2023 is a Saturday. BPI
            </p>
            <p
              style={{
                paddingTop: "4pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              will debit your account on September 16, 2023, however the
              investment will be processed the following Monday, September 18,
              2023.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <h2
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Registration, Transaction, and Modification Limits
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              Monthly Recurring Investments follow the same transaction limits
              for Mutual Fund investments/subscriptions:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              Minimum – Php 1,000.00 per transaction or the minimum additional
              investment of your chosen fund
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "14pt",
                textAlign: "left",
              }}
            >
              Maximum – Php 500,000.00 per calendar day
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              In addition, you can set up to 10 Monthly Recurring Investment
              registrations i.e. monthly recurring investments to 10 different
              funds.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              className="s1"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              <a
                href="mailto:online@rampver.com"
                style={{
                  color: "black",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  textDecoration: "none",
                  fontSize: "12pt",
                }}
              >
                Modifications may be done through Rampver Online’s service email{" "}
              </a>
              <span
                style={{
                  color: "#15C",
                  fontFamily: "Arial, sans-serif",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  textDecoration: "underline",
                  fontSize: "12pt",
                }}
              >
                online@rampver.com
              </span>{" "}
              <span style={{ color: "#000" }}>
                and will have a maximum of 1 modification per registration per
                month.
              </span>
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <h2
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Suspension of Monthly Recurring Investment Registration
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              Your registered BPI Online Account must be funded prior to your
              scheduled auto-debit. We recommend it is always funded at least 3
              days before your scheduled auto-debit. If your account is unfunded
              by your scheduled auto-debit for 3 months, we reserve the right to
              suspend your Monthly Recurring Investment registration.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <h2
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Acknowledgement of Monthly Recurring Investment Terms
            </h2>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              I have read, understood, and agreed to the Monthly Recurring
              Investment Terms of Rampver Online. Likewise, I confirm all
              details indicated in my monthly recurring investment request are
              true and accurate. Hence, Rampver will not be held liable for any
              discrepancies on the final amount of my monthly recurring
              investment. Upon the booking of my monthly investment transaction,
              Rampver shall in no case be responsible for any further claims for
              services rendered for this investment request.
            </p>
            <p
              style={{
                paddingTop: "4pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left",
              }}
            >
              I agree and commit not to disparage Rampver, or any of its
              officers, directors or employees, in verbal, written, or any form
              of actual communication including but not limited to the internet
              or social media.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <h3
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}
            >
              Last Updated: December 12, 2023
            </h3>
          </div>
        </DialogContent>
        <DialogActions style={{ margin: "10px 40px" }}>
         
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TermsCondition 
