import React, { Component } from "react";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import NavigationMenus from "../NavigationMenus";
import OverlayAlertBox from "./OverlayAlertBox";
import {
  Breadcrumbs,
  Chip,
  Typography,
  Link,
  Grid,
  Paper,
  Button,
  LinearProgress,
} from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import { InfoOutlined, AttachFileOutlined } from "@material-ui/icons";
import { AuthUserContext } from "../Session";
import Functions from "./Functions";
import BankWidget from "./BankWidget";

const UploadDepositSlipPage = () => {
  return (
    <>
      <NavigationMenus content={<UploadDepositSlip />} />
    </>
  );
};

class UploadDepositSlipBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      fundName: "",
      amount: "",
      picture: null,
      hasDepositSlip: false,
      transactionId: "",
    };

    this.functions = new Functions();
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
      minimumFractionDigits: 2,
    });
    let transactionId = this.props.match.params.id;
    this.setState({ transactionId: transactionId });
    let uid = this.context.uid;
    const investmentRef = this.props.firebase.db.collection("transactions");
    investmentRef
      .where("clientDetails.userUid", "==", uid)
      .get()
      .then((snapshot) => {
        snapshot.docs.some((doc) => {
          if (doc.data().eventId === transactionId) {
            const data = doc.data();
            let hasDepositSlip = data.depositSlip === "" ? false : true;
            this.setState({
              amount: formatter.format(data.amount),
              hasDepositSlip: hasDepositSlip,
              fundName: data.fundDetails.fundName,
            });

            return true;
          } else {
            return false;
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deletePicture = () => {
    this.fileInput.value = "";
    this.setState({ picture: null });
  };

  handleChange = (event) => {
    if (event.target.name !== "picture") {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      let files = event.target.files;
      this.setState({ picture: files[0] });
    }
  };

  handleMoneyChange = (e) => {
    const newVal = e.target.value.replace(/[^\d]/, "");

    this.setState({
      amount: newVal,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, () => {
      this.functions
        .doUploadDepositSlip(this.state)
        .then(() => {
          this.setState({ loading: false, success: true });
        })
        .catch((err) => {
          this.setState({ loading: false, success: false }, () => {
            console.log(err);
          });
        });
    });
  };

  render() {
    document.body.style.backgroundColor = "white";
    const { success, amount, picture, loading, hasDepositSlip, fundName } =
      this.state;
    let transactionId = this.props.match.params.id;

    return (
      <>
        {success ? <OverlayAlertBox /> : ""}
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textSecondary" href={ROUTES.DASHBOARD}>
            Dashboard
          </Link>
          <Link color="textSecondary" href={ROUTES.MY_INVESTMENTS}>
            Funds
          </Link>
          <Link
            color="textSecondary"
            href={`${ROUTES.INVESTMENT_HISTORY}/${transactionId}`}
          >
            {fundName}
          </Link>
          <Typography color="textPrimary" aria-current="page">
            Upload Deposit Slip
          </Typography>
        </Breadcrumbs>
        <div className="body-content">
          <form onSubmit={this.onSubmit} className="payment-details">
            {hasDepositSlip ? (
              <div style={{ textAlign: "center" }}>
                <h1>You already have a deposit slip.</h1>
              </div>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <h1>Upload Deposit Slip</h1>
                  {/* <span>{this.state.date.toDateString()}</span> */}
                  <br /> <br />
                  <h2>Amount: {amount}</h2>
                  <br />
                  <div className="upload">
                    <div className="title">
                      <h3>Please upload your deposit slip</h3>
                      <InfoOutlined fontSize="small" />
                    </div>
                    <span>
                      Please make sure that the entered amount is the same in
                      the deposit slip
                    </span>
                    <div className="upload-box">
                      <input
                        required
                        accept="image/x-png,image/gif,image/jpeg"
                        type="file"
                        ref={(ref) => (this.fileInput = ref)}
                        className="upload-file"
                        name="picture"
                        onChange={(event) => this.handleChange(event)}
                      />
                      <span>Drag and Drop files here or choose file</span>
                    </div>
                    <Paper className="custom-list-upload" component="ul">
                      {picture !== null && (
                        <Chip
                          icon={<AttachFileOutlined />}
                          label={picture.name}
                          onDelete={this.deletePicture}
                          className="custom-chip-upload"
                        />
                      )}
                    </Paper>
                    <br />
                    {loading ? (
                      <LinearProgress color="primary" />
                    ) : (
                      <Button
                        disabled={loading}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="custom-btn"
                        size="large"
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="payment-bank">
                    <BankWidget />
                  </div>
                  <div className="quick-note">
                    <h2>Quick Note</h2>
                    <span>
                      Your personal data will be used to process your order,
                      support your experience throughout this website, and for
                      other purposes described in our <b>privacy policy.</b>
                    </span>
                  </div>
                </Grid>
              </Grid>
            )}
          </form>
        </div>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const UploadDepositSlip = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(UploadDepositSlipBase);

export default UploadDepositSlipPage;

export { UploadDepositSlip };
