import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';

class CopyUserId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  handleCopy = () => {
    console.log(this.props)
    copy(this.props.acctNum);
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 1500);
  };

  render() {
    const { copied } = this.state;

    return (
      <span>
        <Tooltip title={copied ? 'Copied!' : 'Copy to Clipboard'}>
          <IconButton color="secondary" onClick={this.handleCopy}>
            <FileCopyIcon small/> &nbsp;

          </IconButton>
        </Tooltip>
      </span>
    );
  }
}

export default CopyUserId;