import React, { Component } from "react";
import ConfirmNewPasswordContainer from "./ConfirmNewPasswordContainer";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import Navbar from "../Navbar";

const ConfirmNewPasswordPage = () => {
  return <ConfirmNewPasswordForm />;
};

class ConfirmNewPasswordBase extends Component {
  render() {
    return (
      <>
        <Navbar />
        <ConfirmNewPasswordContainer props={this.props} />
      </>
    );
  }
}

const ConfirmNewPasswordForm = compose(
  withRouter,
  withFirebase
)(ConfirmNewPasswordBase);

export default ConfirmNewPasswordPage;

export { ConfirmNewPasswordForm };
