import React, { Component } from "react";
import { Container } from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import { withFirebase } from "../Firebase";
import Logo from "../../contents/img/Rampver_Online_Logo-main.png";

class SignupCheckEmailVerificationPage extends Component {
  componentDidMount() {
    this.props.firebase.doSignOut();
    setTimeout(() => {
      this.props.history.push(ROUTES.LANDING);
    }, 10000);
  }

  render() {
    return (
      <Container>
        <div id="message" className="r-title-container text-center">
          <img
            src={Logo}
            alt="rampver-logo"
            style={{ height: "auto", width: "300px" }}
          />
          <br />
          <p className="r-title">
            Thank you for registering to Rampver Online!
          </p>
          <p className="r-subtitle">
            To start your financial journey, you need to verify your email.
            Please check your inbox and click the verification link so we can
            ensure this email belongs to you.
          </p>
          <br />
          <p className="r-subtitle">
            We will be sending you important notifications such as your
            investment details, updates to your account, and more!
          </p>
        </div>
      </Container>
    );
  }
}

export default withFirebase(SignupCheckEmailVerificationPage);
