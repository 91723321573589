import PropTypes from "prop-types";
import { getAPIKey } from "@/components/Firebase/firebase";

const YOUTUBE_PLAYLIST_ITEMS_API =
  "https://www.googleapis.com/youtube/v3/playlistItems";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      referrerPolicy="no-referrer origin-when-cross-origin same-origin"
      title="Embedded Youtube Video"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export async function getYoutubePlaylist(playlistId) {
  let response = { data: null, error: "" };
  try {
    const res = await fetch(
      `${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&maxResults=50&playlistId=${playlistId}&key=${getAPIKey()}`
    );
    const data = await res.json();
    response.data = data;
    return response;
  } catch (err) {
    response.error = err;
    return response;
  }
}

export default YoutubeEmbed;
