import { Button, Divider, TextField } from "@material-ui/core";
import React, {  useEffect, useState } from "react";
import { makeStyles, Grid, Fade } from "@material-ui/core";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import {  ValidatorForm } from "react-material-ui-form-validator";;

import CustomButton from "../../Button";
import { RadioForm} from "../shortKYC/fieldAndimage";
import DropDownField from "../../ValidatedFields/DropDownField";

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "10px",
  },
}));

const PEPDeclaration = (props) => {
    const { PEPData,initialLoad, editKYC, uploadImg } = props;
    const classes = useStyles();

    const {
      watch,
      control,
      handleSubmit,
      formState: { errors },
      trigger,
      setValue,
    } = useForm();


 useEffect(() => {
   const subscription = watch((value, { name }) => {
     if (name === "governmentWork" || name === "governmentRelative") {
       if (value.governmentWork === "0" && value.governmentRelative === "0") {
         setValue("banks", "");
         props.handleChangePEPDec(value);
       }
     }
     props.handleChangePEPDec(value);
   });

   return () => subscription.unsubscribe();
 }, [watch,setValue]);



    if (initialLoad) {
      return <div>Loading...</div>;
    }
    const onSubmit = async (data) => {
      props.nextStep();
      props.fixedStep(3);
      console.log(data);
    };
 
const listBank = (
  <Grid item xs={12} sm={12} className={classes.margin}>
    <div style={{ marginBottom: "20px" }}>
      <p style={{ fontWeight: "600", fontStyle: "italic" }}>
        Please enumerate the banks you have an account in:
      </p>
      <Controller
        name="banks"
        control={control}
        defaultValue={PEPData.banks}
        rules={{ required: "This field is required" }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            color="primary"
            fullWidth
            type="text"
            inputRef={ref}
            onChange={(e) => {
           
              field.onChange(e);
              trigger("banks");
            }}
            multiline
            rows={2}
            className={classes.margin}
            placeholder="List of banks"
            variant="outlined"
            error={!!errors.banks}
            helperText={errors.banks && errors.banks.message}
          />
        )}
      />
    </div>
  </Grid>
);

  return (
    <Fade in={true}>
      <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1>PEP (Politically Exposed Person) Declaration</h1>
          </Grid>
          <Divider />
          <Grid item xs={12} className={classes.margin}>
            <RadioForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              name="governmentWork"
              rules={{ required: "This field is required" }}
              className={clsx(classes.margin, "pull-left")}
              label="Are you working / have you worked in a government agency or institution?"
              value={PEPData.governmentWork}
              styles={true}
            />
          </Grid>
          <Grid item xs={12} className={classes.margin}>
            <RadioForm
              disabled={false}
              trigger={trigger}
              error={errors}
              control={control}
              name="governmentRelative"
              rules={{ required: "This field is required" }}
              className={clsx(classes.margin, "pull-left")}
              label="Do you have any relative who is / was an elected government
                official or who is / was an appointed government official?"
              value={PEPData.governmentRelative}
              styles={true}
            />
          </Grid>
          {(watch("governmentWork") === "1" ||
            watch("governmentRelative") === "1") && (
            <>
              <Grid item xs={12} sm={12} className={classes.margin}>
                <div style={{ marginBottom: "20px" }}>
                  <p style={{ fontWeight: "600", fontStyle: "italic" }}>
                    If you have checked one or both of these items, you will
                    need to upload necessary documents:
                  </p>
                  <ul style={{ fontWeight: "200", fontStyle: "italic" }}>
                    <li>
                      If your primary source of funds is your salary, kindly
                      provide your latest Income Tax Return (ITR) or latest
                      payslip.
                    </li>
                    <li>
                      If your primary source of funds is sale of asset, kindly
                      provide the Deed of Sale of the asset or property
                    </li>
                  </ul>
                </div>
                <DropDownField
                  onAdd={props.handleDropzone("idPEPDeclaration")}
                  onDelete={props.handleDropzoneDelete("idPEPDeclaration")}
                  filesLimit={1}
                  value={uploadImg.idPEPDeclarationLabel}
                  image={uploadImg.idPEPDeclarationPreview}
                  validators={["required"]}
                  errorMessages={["This field is required."]}
                />
                {editKYC && uploadImg.oldPEPDeclaration !== null && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ color: "white" }}
                    onClick={() =>
                      window.open(
                        uploadImg.oldPEPDeclaration,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                    fullWidth
                  >
                    View my current photo
                  </Button>
                )}
              </Grid>
              {listBank}
            </>
          )}
          <Grid item xs={12}>
            <div className="flex">
              <div className="btn-back" onClick={props.prevStep}>
                Previous Step
              </div>

              <CustomButton
                text="Continue"
                type="submit"
                disabled={false}
                buttonTheme="primary"
              />
            </div>
          </Grid>

          {/* 
            {data.PEP.governmentWork || data.PEP.governmentRelative
              ? this.editPEP(main.editKYC)
              : ""} */}
          <Divider />
        </Grid>
      </ValidatorForm>
    </Fade>
  );
};

export default PEPDeclaration;

