import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import * as ROUTES from "../../constants/route";
import { Container, LinearProgress } from "@material-ui/core";
import Logo from "../../contents/img/Rampver_Online_Logo-main.png";
import axios from "axios";

const EmailVerificationPage = (props) => {
  return <EmailVerificationHandler props={props} />;
};

class EmailVerificationHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "Verifying Email",
      subtitle: "Please wait a moment",
    };

    this.fb = props.props.firebase;
    this.location = props.props.location;
    this.history = props.props.history;

    this.actionCode = new URLSearchParams(this.location.search).get("oobCode");
    this.mode = new URLSearchParams(this.location.search).get("mode");
    this.email = new URLSearchParams(this.location.search).get("email");
    switch (this.mode) {
      case "verifyEmail":
        this.fb
          .handleVerifyEmail(this.actionCode)
          .then(() => {
            return axios.post(
              `${ROUTES.FUNCTION_BASE_URL}clients-client/sendWelcome`,
              JSON.stringify({ token: this.actionCode }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
          })
          .then((result) => {
            if (!result.data.ok) throw new Error(result.data.message);

            this.setState(
              {
                title:
                  "Congratulations! Your Rampver Online email has been verified.",
                subtitle:
                  "Redirecting you to the login page now so you can enter Rampver Online.",
              },
              () => {
                setTimeout(() => {
                  this.history.push(ROUTES.SIGN_IN);
                }, 8000);
              }
            );
          })
          .catch((error) => {
            alert(error);
            this.setState(
              {
                title: "Failed to verify email.",
                subtitle:
                  "You will be redirected to back to the login page, please wait a moment...",
              },
              () => {
                setTimeout(() => {
                  this.history.push(ROUTES.SIGN_IN);
                }, 8000);
              }
            );
          });
        break;

      case "resetPassword":
        this.history.push({
          pathname: `/confirm-password/${this.actionCode}`,
          state: { code: this.actionCode },
        });
        break;
      default:
        this.history.push(ROUTES.SIGN_IN);
        break;
    }
  }

  render() {
    const { title, subtitle } = this.state;
    return (
      <>
        <LinearProgress />
        <Container>
          <div id="message" className="r-title-container text-center">
            <img src={Logo} className="email-handler-img" alt="rampver-logo" />
            <br />
            <p className="r-title">{title}</p>
            <p className="r-subtitle">{subtitle}</p>
          </div>
        </Container>
      </>
    );
  }
}

export default compose(withFirebase)(EmailVerificationPage);

export { EmailVerificationHandler };
