import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/route";
import { getLatestApprovedTransaction } from "../MyInvestments/RecurringInvestment/recurringAmount";

const DialogsTitle = withStyles((theme) => ({
  root: {
    margin: "10px 0px 0px 10px",
  },
}))(DialogTitle);

const DialogsContent = withStyles((theme) => ({
  root: {
    margin: "-20px 20px 20px 20px",
    padding: "0px 10px 10px 10px",
    fontSize: "12px",
    fontWeight: "900",
    lineHeight: "9px",
    color: "black !important",
    maxWidth: "450px",
  },
}))(DialogContent);

// Define the base design
const baseStyle = {
  textTransform: "capitalize",
  fontFamily: "Poppins",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "18px",
  height: "40px",
};

export default function RecurringInvestmentDialog({
  userID,
  fundName,
  onClose,
  investedAmount,
  minInitialAmount,
  firebase,
}) {
  const [selectedValue, setSelectedValue] = useState("");
  const [lastAmountInvested, setLastAmountInvested] = useState(0);

  const [tier, setTier] = useState(null);

  const getLastAmountInvested = async () => {
    try {
      const latestTransaction = await getLatestApprovedTransaction(
        investedAmount,
        fundName,
        userID
      );
      if (latestTransaction) {
        setLastAmountInvested(latestTransaction.amount);
      }
      const { stats } = (
        await firebase.db.collection("users").doc(userID).get()
      ).data();
      setTier(stats.tier ?? 3);
    } catch (error) {
      console.error("Error fetching last amount invested:", error);
      throw error;
    }
  };
  useEffect(() => {
    getLastAmountInvested();
  }, []);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  //  const getTransactionsForFund = async (fundName) => {
  //    try {
  //     const transactions = await investedAmount
  //       .where("fundDetails.fundName", "==", fundName)
  //       .where("status", "==", "approved")
  //       .orderBy("datePlaced", "desc")
  //       .limit(1)
  //       .get();

  //     const userid = transactions.docs.filter(
  //       (doc) => doc.data().clientDetails.userUid === userID
  //     );

  //     if (userid.length > 0) {
  //       const amount = userid[0].data().amount;
  //       setLastAmountInvested(amount);
  //     } else {
  //       console.log("No transactions found for the fund");
  //     }
  //    } catch (error) {
  //      console.error("Error fetching transactions:", error);
  //      throw error;
  //    }
  //  };

  const isSelected = (value) => selectedValue === value;
  const design = selectedValue
    ? {
        ...baseStyle,
        background: "#c8932a",
        color: "white",
      }
    : {
        ...baseStyle,
        background: "default",
      };
  const stateObj = {
    state: {
      fundValue: fundName,
      investedValue: lastAmountInvested,
      minInitialValue: minInitialAmount,
    },
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogsTitle>
        <span className="text-xl lg:text-2xl font-poppins font-extrabold">
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{ float: "right", bottom: "20px", right: "-20px" }}
          >
            <CloseIcon />
          </IconButton>
          Additional Investment
        </span>
      </DialogsTitle>

      <DialogsContent dividers>
        <FormControl fullWidth size="small" margin="normal">
          <div className=" text-base font-poppins font-semibold">Fund Name</div>
          <TextField
            disabled
            defaultValue={fundName}
            variant="outlined"
            margin="dense"
            InputProps={{
              style: {
                color: "black",
                fontWeight: "600",
                background: "#E1E0DE",
              },
            }}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <div className=" text-base font-poppins font-semibold">
            Additional Investment Options:
          </div>

          <RadioGroup
            aria-label="investment-type"
            defaultValue="monthly"
            name="investment-type"
            value={selectedValue}
            onChange={handleChange}
            className="flex flex-col gap-4 pt-5"
          >
            {["monthly", "one-time"].map((type) => (
              <label
                key={type}
                className={`block lg:px-3 bg-white py-2 ${
                  isSelected(type)
                    ? "border-2 border-solid border-gray-400 bg-primary text-white"
                    : "border-2 border-solid border-gray-400"
                } ${
                  tier < 3 &&
                  type === "monthly" &&
                  "opacity-50 pointer-events-none"
                } shadow-sm rounded-sm`}
              >
                <Radio
                  checked={isSelected(type)}
                  value={type}
                  className=" top-5"
                />

                <span
                  className={`absolute py-1 lg:py-2  text-left font-poppins font-semibold "cursor-pointer text-base"
                    `}
                >
                  {type === "monthly"
                    ? `Monthly Recurring Investments (Beta)`
                    : "One-time Investment"}
                </span>
                <div
                  className={`flex flex-col ml-11 ${
                    type !== "monthly" ? "mt-0" : "mt-4"
                  } lg:mt-0`}
                >
                  <span
                    className={`text-xs font-poppins font-normal italic cursor-pointer`}
                  >
                    {type === "monthly"
                      ? tier < 3
                        ? "This feature is available to fully verified accounts. Get fully verified now!"
                        : "Automatically invest in your chosen fund every month. Powered by BPI Online Auto-debit."
                      : "Make an additional investment in your chosen fund. Pay through any e-wallet or bank."}
                  </span>
                </div>
              </label>
            ))}
          </RadioGroup>
        </FormControl>
        <div className="grid lg:justify-items-end ">
          <div className="mt-5 w-full lg:w-56  ">
            <Button
              variant="contained"
              fullWidth
              style={design}
              disabled={!selectedValue}
              component={Link}
              to={{
                pathname:
                  selectedValue === "monthly"
                    ? ROUTES.RECURRING_INVESTMENT_INFO
                    : ROUTES.FUNDS + "/" + fundName + "/payment",

                ...(selectedValue === "monthly" && stateObj),
              }}
            >
              Proceed
            </Button>
          </div>
        </div>
      </DialogsContent>
    </Dialog>
  );
}
