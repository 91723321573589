import React, { Component } from "react";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import NavigationMenus from "../NavigationMenus";
import {
  Dialog,
  Breadcrumbs,
  Typography,
  Link,
  Grid,
  Divider,
  Checkbox,
  Paper,
  Slider,
  Button,
  FormControlLabel,
  FormControl,
  TextField,
  FormGroup,
  FormHelperText,
  RadioGroup,
  Radio,
  useTheme,
  IconButton,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import * as ROUTES from "../../constants/route";
import DialogActions from "@material-ui/core/DialogActions";
import PerformanceGraph from "../MyInvestments/PerformanceGraph";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import LinearProgress from "@material-ui/core/LinearProgress";
import Functions from "../MyInvestments/Functions";
import { VerifiedUser } from "@material-ui/icons";
import { ModalOneButtonAgree } from "../MyInvestments/ModalAlert";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import Helpers from "../Helpers";

import NumberFormat from "react-number-format";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Logo from "../../images/Ramp_Logo.png";
import RadioField from "../ValidatedFields/RadioField";

const useStyles = makeStyles((theme) => ({
  success: {
    background: "#256141",
    color: "white",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
      background: "#3a9464",
    },
  },
  message: {
    fontWeight: "600",
  },
  warning: {
    background: "rgb(255,152,0)",
    color: "white",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
      background: "rgb(255,152,0)",
    },
  },
  agree: {
    background: "#256141",
    fontStyle: "Gill Sans, Poppins",
    color: "white",
    textTransform: "none",
    "&:disabled": {
      color: "white",
      background: "#757575",
    },
    "&:hover": {
      background: "#1c4f35",
    },
  },
  cancel: {
    background: "#C8932A",
    fontStyle: "Gill Sans, Poppins",
    color: "white",
    textTransform: "none",
    "&:hover": {
      background: "rgb(140, 102, 29)",
    },
  },
  link: {
    color: "rgb(26, 176, 113)",
    cursor: "pointer",
  },
  paperPadding: {
    padding: "30px",
  },
  display: {
    marginLeft: "10px",
  },
  surveyBody: {
    [theme.breakpoints.up("md")]: {
      padding: "5px 30px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    width: "100%",
  },
  surveyText: {
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  surveyLabel: {
    [theme.breakpoints.down("md")]: {
      margin: "0px",
    },
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CustomSlider = withStyles({
  root: {
    height: 2,
    padding: "25px 0",
  },
  valueLabel: {
    top: -22,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
  markLabel: {
    paddingTop: "10px",
    transform: "translateX(-100%)",
    '&[data-index="0"]': {
      transform: "none",
    },
  },
})(Slider);

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#C8932A",
      },
      "&:hover fieldset": {
        borderColor: "#ebab2d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b88c35",
      },
    },
  },
})(TextField);

const RedemptionPage = () => {
  const classes = useStyles();
  const mdUp = useMediaQuery(useTheme().breakpoints.up("md"));
  return (
    <>
      <NavigationMenus content={<Redemption mdUp={mdUp} classes={classes} />} />
    </>
  );
};

class RedemptionPageBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFund: this.props.history.location.state.currentFund,
      currentRate: this.props.history.location.state.currentRate,
      currentRatesDate: this.props.history.location.state.currentRatesDate,
      maxShares: this.props.history.location.state.maxShares,
      shares: 0,
      sharesFloor: 0,
      sharesCeiling: 0,
      postdata: this.props.history.location.state.postdata,
      fundId: this.props.history.location.state.fundId,
      performanceGraphData: [],
      modalAcceptOpen: false,
      modalErrorOpen: false,
      modalErrorMessage: "",
      isAgree: false,
      termsDialogOpen: false,
      termsText: "",
      termsErrorDisplay: false,
      submitButtonDisabled: true,
      redemptionLoading: false,
      serviceFee: 50,
      doneLoading: false,
      accountDetails: {},
      surveyOpen: false,
      surveyData: {
        question1: {
          answers: [],
          otherText: "",
        },
        question2: "",
      },

      // bank settlement
      accountName: "",
      bankName: "",
      bankNumber: "",
      savingsAccount: false,
    };
    this.functions = new Functions();
    this.helpers = new Helpers();
  }

  componentDidMount() {
    const redemptionTermsRef = this.props.firebase.db
      .collection("miscellaneous")
      .doc("redemptionTerms");
    this.props.firebase.db
      .collection("users")
      .doc(this.props.firebase.auth.currentUser.uid)
      .get()
      .then((result) => {
        if (result.data().hasCurrentWithdrawal) {
          this.setState({
            modalErrorOpen: true,
            modalErrorMessage: "You have an existing redemption request",
          });
          return "hasWithdrawal";
        } else
          return Promise.all([
            this.props.firebase.db
              .collection("users")
              .doc(this.props.firebase.auth.currentUser.uid)
              .collection("accountDetails")
              .doc("AD")
              .get(),
            redemptionTermsRef.get(),
          ]);
      })
      .then((result) => {
        if (result === "hasWithdrawal") return 0;

        this.getPerformanceGraph();

        let minShares = 1000 / parseFloat(this.state.currentRate);
        let maxShares = 500000 / parseFloat(this.state.currentRate);

        if (!Number.isInteger(minShares)) {
          minShares = Math.ceil(minShares);
        }

        if (this.state.maxShares < maxShares) maxShares = this.state.maxShares;

        if (this.state.maxShares < minShares) {
          minShares = this.state.maxShares;
        }

        if (minShares <= 0) {
          this.setState({
            modalErrorOpen: true,
            modalErrorMessage: "You cannot redeem 0 shares",
          });
          return 0;
        }

        return this.setState({
          accountDetails: result[0].data(),
          termsText: result[1].data().content,
          minShares: minShares,
          maxShares: maxShares,
          shares: minShares,
        });
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });
  }

  getPerformanceGraph = () => {
    const fundRatesRef = this.props.firebase.db
      .collection("funds")
      .doc(this.state.fundId)
      .collection("summary");
    let data = [];

    fundRatesRef
      .orderBy("dateCompiled", "asc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.data().months.forEach((item) => {
            if (item.week1.length > 0) {
              item.week1.forEach((item) => {
                data.push([item.date.seconds * 1000, item.rate]);
              });
            }

            if (item.week2.length > 0) {
              item.week2.forEach((item) => {
                data.push([item.date.seconds * 1000, item.rate]);
              });
            }

            if (item.week3.length > 0) {
              item.week3.forEach((item) => {
                data.push([item.date.seconds * 1000, item.rate]);
              });
            }

            if (item.week4.length > 0) {
              item.week4.forEach((item) => {
                data.push([item.date.seconds * 1000, item.rate]);
              });
            }
          });
        });
        this.setState({
          performanceGraphData: data,
          doneLoading: true,
        });
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });
  };

  handleSliderChange = (event, newValue) => {
    if (!isNaN(newValue) && newValue > 0) this.setState({ shares: newValue });
  };

  handleInputChange = (value) => {
    value = parseFloat(value);
    if (!(value === this.state.maxShares)) value = parseInt(value);

    if (
      !isNaN(value) &&
      value > this.state.minShares &&
      value < this.state.maxShares
    )
      this.setState({ shares: value });
  };

  handleCheckBox = (e) => {
    if (!this.state.isAgree) this.handleTermsDialog(e);

    this.setState({
      isAgree: e.target.checked,
      submitButtonDisabled: !e.target.checked,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.surveyOpen) {
      this.setState(
        {
          submitButtonDisabled: true,
          redemptionLoading: true,
          surveyOpen: false,
        },
        () => {
          let postData = {
            fundCode: this.state.postdata.fundCode,
            shares: this.state.shares,
            companyId: this.state.postdata.companyId,
            userUid: this.state.postdata.userUid,
            investmentUid: this.state.postdata.investmentUid,
            rateUid: this.state.postdata.rateUid,
            surveyData: this.state.surveyData,

            // bank settlement
            accountName: this.state.accountName,
            bankName: this.state.bankName,
            bankAccountNumber: this.state.bankNumber,
            savingAccount: this.state.savingsAccount ,

            //checking
            accountDetails: this.state.accountDetails.accountName === "",
          };
          this.functions
            .doRedeemInvestment(postData)
            .then((res) => {
              if (res.data.ok) {
                this.setState({ modalAcceptOpen: true });
              } else {
                this.setState({
                  modalErrorOpen: true,
                  modalErrorMessage: res.data.message,
                });
              }
            })
            .catch((err) => {
              this.setState({
                modalErrorOpen: true,
                modalErrorMessage: err.message,
              });
            });
        }
      );
    } else this.handleSurveyDialog();
  };

  handleModalAlertCLose = () => {
    this.props.history.push(ROUTES.INVESTMENT_HISTORY);
  };

  handleTermsDialog = () => {
    this.setState({ termsDialogOpen: !this.state.termsDialogOpen });
  };

  handleErrorClick = (e) => {
    if (!this.state.isAgree) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ termsErrorDisplay: true });
    }
  };

  handleAcceptDialog = () => {
    this.props.history.push(ROUTES.INVESTMENT_HISTORY);
  };

  handleFundInfoButton = (e) => {
    e.preventDefault();
    this.props.history.push(
      ROUTES.INVESTMENT_PAYMENT + `/${this.state.fundId}`
    );
  };

  handleSurveyDialog = () => {
    this.setState({ surveyOpen: !this.state.surveyOpen });
  };

  handleSurveyAnswer = (e, question) => {
    e.preventDefault();
    let newSurveyData = this.state.surveyData;

    switch (question) {
      case 1:
        if (e.target.name === "other") {
          newSurveyData.question1.otherText = e.target.value;
        } else {
          let checkboxNumber = parseInt(e.target.name);

          if (e.target.checked)
            newSurveyData.question1.answers.push(checkboxNumber);
          else {
            let index = newSurveyData.question1.answers.findIndex(
              (x) => x === checkboxNumber
            );
            newSurveyData.question1.answers.splice(index, 1);
          }
        }
        break;

      case 2:
        newSurveyData.question2 = e.target.value;
        break;

      default:
        break;
    }
    this.setState({ surveyData: newSurveyData });
  };

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }
  handleSavingAccount = (event) => {
    this.setState({ savingsAccount: event.target.value === "true" });
  };

  render() {
    const { classes } = this.props;
    const {
      shares,
      minShares,
      maxShares,
      currentRatesDate,
      currentRate,
      currentFund,
      performanceGraphData,
      isAgree,
      modalAcceptOpen,
      modalErrorOpen,
      modalErrorMessage,
      termsDialogOpen,
      termsText,
      termsErrorDisplay,
      redemptionLoading,
      submitButtonDisabled,
      serviceFee,
      doneLoading,
      accountDetails,
      surveyOpen,
      surveyData,
    } = this.state;

    const { mdUp } = this.props;

    let marketVal = parseFloat(currentRate) * parseFloat(shares);
    let marks = [
      {
        value: minShares,
        label: `${parseFloat(minShares).toLocaleString("en-US")}`,
      },
      {
        value: maxShares,
        label: `${parseFloat(maxShares).toLocaleString("en-US")}`,
      },
    ];
    const otherValidation = surveyData.question1.answers.find(
      (item) => item === 6
    );
    let navpsLabel = `NAVPS as of ${currentRatesDate}`;
    let surveyButtonDisabled =
      otherValidation === 6
        ? surveyData.question1.otherText === ""
        : surveyData.question1.answers.length < 1;

    const surveyDialog = (
      <div>
        <Dialog
          open={surveyOpen}
          fullWidth
          maxWidth={"md"}
          scroll={"paper"}
          aria-labelledby="form-dialog-title"
          onClose={() => this.handleSurveyDialog()}
        >
          <DialogTitle>
            <img
              className="w-1/3 px-0 pt-1 md:w-auto md:px-3 md:pt-3 lg:w-auto lg:px-6 lg:pt-6"
              src={Logo}
              alt="rampver-logo"
            />
            <div className="px-0 text-xl font-extrabold md:text-2xl md:px-3 lg:text-4xl lg:px-6">
              Rampver Financials Redemption Survey
            </div>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => this.handleSurveyDialog()}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className={classes.surveyBody}>
              <span>
                <b>
                  1. Why are you redeeming your mutual fund investment? (Please
                  check all boxes that apply)
                </b>
              </span>
              <FormControl
                required
                error={surveyData.question1.answers.length < 1}
                component="fieldset"
                className={classes.surveyBody}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyData.question1.answers.includes(1)}
                        onChange={(e) => this.handleSurveyAnswer(e, 1)}
                        name={1}
                      />
                    }
                    label={
                      <span className={classes.surveyText}>
                        Realized Gains (I have already achieved my financial
                        goal for this investment)
                      </span>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyData.question1.answers.includes(2)}
                        onChange={(e) => this.handleSurveyAnswer(e, 1)}
                        name={2}
                      />
                    }
                    label={
                      <span className={classes.surveyText}>
                        Fund Switching (I want to invest in a different mutual
                        fund within Rampver&apos;s products)
                      </span>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyData.question1.answers.includes(3)}
                        onChange={(e) => this.handleSurveyAnswer(e, 1)}
                        name={3}
                      />
                    }
                    label={
                      <span className={classes.surveyText}>
                        Use for another financial product (I will use the
                        proceeds form my mutual fund investment to fund another
                        financial product)
                      </span>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyData.question1.answers.includes(4)}
                        onChange={(e) => this.handleSurveyAnswer(e, 1)}
                        name={4}
                      />
                    }
                    label={
                      <span className={classes.surveyText}>
                        Emergency (I will use the proceeds from my mutual fund
                        investment to pay for an emergency expense)
                      </span>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyData.question1.answers.includes(5)}
                        onChange={(e) => this.handleSurveyAnswer(e, 1)}
                        name={5}
                      />
                    }
                    label={
                      <span className={classes.surveyText}>
                        Not satisfied with the returns (I am not satisfied with
                        the performance of my mutual fund investment)
                      </span>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={surveyData.question1.answers.includes(6)}
                        onChange={(e) => this.handleSurveyAnswer(e, 1)}
                        name={6}
                      />
                    }
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span className={classes.surveyText}>
                          Others (Kindly specify the reason) &nbsp;
                        </span>
                        <span>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              id="other-textfield"
                              disabled={
                                !surveyData.question1.answers.includes(6)
                              }
                              placeholder="others"
                              variant="outlined"
                              value={surveyData.question1.otherText}
                              onChange={(e) => this.handleSurveyAnswer(e, 1)}
                              name={"other"}
                              className=" w-40 sm:w-52 md:w-72 lg:w-96"
                            />
                          </FormControl>
                        </span>
                      </div>
                    }
                    labelPlacement="end"
                  />
                </FormGroup>
                {surveyData.question1.answers.length < 1 ? (
                  <FormHelperText>Please pick at least one</FormHelperText>
                ) : (
                  <br />
                )}
              </FormControl>

              <span>
                <b>
                  2. Do you have any feedback or suggestion for Rampver Online?
                </b>
              </span>
              <FormControl component="fieldset" className={classes.surveyBody}>
                <TextField
                  id="question-2-text"
                  multiline
                  rows={2}
                  placeholder="Comments and suggestions"
                  variant="outlined"
                  value={surveyData.question2}
                  onChange={(e) => this.handleSurveyAnswer(e, 2)}
                />
              </FormControl>
              <div className="text-center ">
                Thank you for answering this quick survey. If you have any other
                concerns, kindly email us at{" "}
                <a href="mailto:online@rampver.com">online@rampver.com</a>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ margin: "10px 40px" }}>
            <Button
              style={{
                background: "white",
                padding: "9px 55px",
                color: "#C8932A",
                border: "2px solid",
                textTransform: "none",
                fontWeight: "700",
              }}
              size="large"
              variant="contained"
              onClick={() => this.handleSurveyDialog()}
            >
              Cancel
            </Button>
            <Button
              style={{ color: "white", padding: "10px 45px" }}
              size="large"
              variant="contained"
              color="primary"
              disabled={surveyButtonDisabled}
              onClick={(e) => this.handleSubmit(e)}
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textSecondary" href={ROUTES.INVESTMENT_HISTORY}>
            Investment History
          </Link>
          <Typography color="textPrimary" aria-current="page">
            Redemption
          </Typography>
        </Breadcrumbs>

        {modalAcceptOpen ? (
          <div className="overlay-alert">
            <div className="alert-box">
              <div className="icon-bgRampver">
                <VerifiedUser fontSize="large" />
              </div>
              <h2>Redemption Request successfully submitted</h2>

              <span style={{ fontStyle: "italic" }}>
                Your redemption will be processed in 5-7 banking days.
              </span>
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                className="custom-btn"
                size="large"
                onClick={() => this.handleAcceptDialog()}
                fullWidth
              >
                Okay
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}

        <ModalOneButtonAgree
          function={this.handleModalAlertCLose}
          open={modalErrorOpen}
          message={modalErrorMessage}
          executeFuncOnAgree={this.handleModalAlertCLose}
        />

        {surveyDialog}

        <Dialog
          open={termsDialogOpen}
          onClose={() => {
            this.handleTermsDialog();
          }}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <h3>Redemption Terms</h3>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => this.handleTermsDialog()}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <div style={{ paddingBottom: 30 }}>
                <div dangerouslySetInnerHTML={{ __html: termsText }}></div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {!redemptionLoading ? "" : <LinearProgress />}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="redemption-modal">
              <ValidatorForm onSubmit={(e) => this.handleSubmit(e)}>
                <h2>Redemption Request</h2>
                <br />
                <div className="slider">
                  <Paper elevation={4} className={classes.paperPadding}>
                    <h3>
                      Number of Shares to be redeemed:{" "}
                      {parseFloat(shares.toFixed(2)).toLocaleString()} Shares
                    </h3>
                    <br />
                    <Grid container spacing={4} alignItems="center">
                      {minShares === maxShares ? (
                        <Grid
                          item
                          xs
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <ErrorIcon
                            style={{ color: "#FFCC00", marginRight: "5px" }}
                          />
                          <span>Only a full redemption is available.</span>
                          <br />
                          <br />
                        </Grid>
                      ) : (
                        <Grid item xs>
                          <Typography id="input-slider" gutterBottom>
                            Number of Shares
                          </Typography>
                          <CustomSlider
                            value={parseFloat(shares).toFixed(2)}
                            onChange={(e, val) =>
                              this.handleSliderChange(e, val)
                            }
                            aria-labelledby="input-slider"
                            valueLabelDisplay="auto"
                            marks={marks}
                            min={parseFloat(minShares)}
                            max={parseFloat(maxShares)}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {minShares === maxShares ? (
                      ""
                    ) : (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                          <span
                            style={{ fontStyle: "italic", fontWeight: "bold" }}
                          >
                            Select number of shares through the slider or by
                            indicating exact number in the text box.
                          </span>
                        </Grid>
                        <Grid item xs={3}>
                          <CustomTextField
                            id="outlined-number"
                            variant="outlined"
                            label="Shares"
                            value={
                              minShares === shares
                                ? parseFloat(parseFloat(shares).toFixed(2))
                                : maxShares === shares
                                ? parseFloat(parseFloat(shares).toFixed(2))
                                : parseFloat(shares)
                            }
                            margin="dense"
                            onChange={(e) =>
                              this.handleInputChange(e.target.value)
                            }
                            InputProps={{
                              min: minShares,
                              max: maxShares,
                              step: "any",
                              inputComponent: this.NumberFormatCustom,
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                  <br />
                  <Paper elevation={4} className={classes.paperPadding}>
                    <div className="redemption-second">
                      <table>
                        <tbody>
                          <tr>
                            <th>Number of shares to be redeemed:</th>
                            <td>
                              {parseFloat(shares.toFixed(2)).toLocaleString()}
                            </td>
                          </tr>
                          <tr>
                            <th>{`NAVPS as of ${currentRatesDate}`}:</th>
                            <td>{this.helpers.formatToPHP(currentRate, 4)}</td>
                          </tr>
                          <tr>
                            <th>Approximate Market Value:</th>
                            <td>{this.helpers.formatToPHP(marketVal, 2)}</td>
                          </tr>
                          <tr>
                            <th>Service Fee:</th>
                            <td>{`₱${serviceFee.toFixed(2)}`}</td>
                          </tr>
                          <tr>
                            <th>Approximate Amount to be Collected:</th>
                            <td>
                              {this.helpers.formatToPHP(
                                parseFloat(marketVal - serviceFee, 2)
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {accountDetails.accountName !== "" && (
                      <div className="redemption-second">
                        <h4>
                          Your redemption proceeds will be sent to your
                          registered settlement account below:
                        </h4>
                        <table>
                          <tbody>
                            <tr>
                              <th>Account Name:</th>
                              <td>{accountDetails.accountName}</td>
                            </tr>
                            <tr>
                              <th>Name of Bank:</th>
                              <td>{accountDetails.bankName}</td>
                            </tr>
                            <tr>
                              <th>Bank Account Number:</th>
                              <td>{accountDetails.bankAccountNumber}</td>
                            </tr>
                            <tr>
                              <th>Type of Account:</th>
                              <td>
                                {accountDetails.savingsAccount
                                  ? "Saving"
                                  : "Current"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}

                    <span>
                      Note: If you need to update your registered settlement
                      account, kindly email us at online@rampver.com.
                    </span>
                    <Grid container spacing={4} alignItems="center">
                      <Grid item xs={1}>
                        <Checkbox
                          disabled={redemptionLoading}
                          checked={isAgree}
                          onChange={(e) => this.handleCheckBox(e)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <p>
                          I agree to the{" "}
                          <span
                            className={classes.link}
                            onClick={(e) => this.handleTermsDialog(e)}
                          >
                            <b>Redemption Terms</b>
                          </span>{" "}
                          as provided by Rampver Financials
                        </p>
                      </Grid>
                    </Grid>
                    {termsErrorDisplay ? (
                      <Grid container spacing={4} alignItems="center">
                        <Grid item xs>
                          <span style={{ color: "red" }}>
                            You are required to agree to Redemption Terms
                          </span>
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Paper>
                </div>

                <br />
                {this.state.isAgree && accountDetails.accountName === "" && (
                  <Paper elevation={4} className={classes.paperPadding}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <p style={{ fontWeight: "600", fontStyle: "bold" }}>
                          Reminder: Bank Account Name should be the same with
                          the investor’s account name. Third-party bank accounts
                          are not accepted.
                        </p>
                      </Grid>
                      <Grid item xs={12} className={classes.margin}>
                        <label className="dark-label">Account Name:</label>
                        <br />
                        <TextValidator
                          value={this.state.accountName}
                          className={classes.margin}
                          inputProps={{ style: { padding: "12px" } }}
                          variant="outlined"
                          fullWidth
                          placeholder="Account Name"
                          autoComplete="off"
                          onChange={(e) =>
                            this.setState({ accountName: e.target.value })
                          }
                          validators={["required"]}
                          errorMessages={["This field is required."]}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.margin}>
                        <label className="dark-label">Name of Bank: </label>
                        <TextValidator
                          value={this.state.bankName}
                          className={classes.margin}
                          inputProps={{ style: { padding: "12px" } }}
                          variant="outlined"
                          fullWidth
                          placeholder="Bank Name"
                          autoComplete="off"
                          onChange={(e) =>
                            this.setState({ bankName: e.target.value })
                          }
                          validators={["required"]}
                          errorMessages={["This field is required."]}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.margin}>
                        <label className="dark-label">
                          Bank Account Number:
                        </label>
                        <TextValidator
                          // type="number"
                          value={this.state.bankNumber}
                          className={classes.margin}
                          inputProps={{ style: { padding: "12px" } }}
                          variant="outlined"
                          fullWidth
                          placeholder="Bank Account Number"
                          autoComplete="off"
                          onChange={(e) =>
                            this.setState({ bankNumber: e.target.value })
                          }
                          validators={["required"]}
                          errorMessages={["This field is required."]}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.margin}>
                        <RadioField
                          value={this.state.savingsAccount}
                          label="Current/Savings Account"
                          onChange={this.handleSavingAccount}
                          className="pull-left"
                          validators={["required"]}
                          errorMessages={["This field is required."]}
                        >
                          <Radio
                            name="savingsAccount"
                            value="false"
                            checked={this.state.savingsAccount === false}
                          />
                          <label>Current</label>
                          <Radio
                            className={classes.ml10px}
                            name="savingsAccount"
                            value="true"
                            checked={this.state.savingsAccount === true}
                          />
                          <label>Savings</label>
                        </RadioField>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
                <DialogActions>
                  <div onClick={(e) => this.handleErrorClick(e)}>
                    <br />
                    <Button
                      disabled={submitButtonDisabled}
                      className={classes.agree}
                      type="submit"
                    >
                      Submit Redemption Request
                    </Button>
                  </div>
                </DialogActions>
              </ValidatorForm>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="fund-upper">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="fund-title">
                    <h2>{currentFund}</h2>
                    <Button onClick={(e) => this.handleFundInfoButton(e)}>
                      Fund Information
                    </Button>
                    <br />
                    <br />
                    <div className="amount2">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <h2>{this.helpers.formatToPHP(currentRate, 4)}</h2>
                          }
                          label={navpsLabel}
                          labelPlacement="top"
                        />
                      </FormControl>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <h2>
                              {parseFloat(
                                maxShares.toFixed(2)
                              ).toLocaleString()}
                            </h2>
                          }
                          label="Total Shares"
                          labelPlacement="top"
                        />
                      </FormControl>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <h2>
                              {this.helpers.formatToPHP(
                                parseFloat(currentRate) * parseFloat(maxShares),
                                2
                              )}
                            </h2>
                          }
                          label="Current Market Value"
                          labelPlacement="top"
                        />
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <Divider />
            <div>
              <h2>Performance Graph</h2>
              <PerformanceGraph
                title="Fund Graph"
                firebase={this.props.firebase}
                data={performanceGraphData}
                type="stockChart"
                doneLoading={doneLoading}
              />
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const Redemption = compose(
  withAuthorization(condition),
  withFirebase
)(RedemptionPageBase);

export default RedemptionPage;

export { Redemption };
