import React from "react";
import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";

import { withFirebase } from "../../Firebase";
import NavigationMenus from "../../NavigationMenus";
import { RecurringPaymentInfo } from "./RecurringPaymentInfo";


const RecurringPaymentBase = (props) => {
    return (
        <>
            <NavigationMenus
                disableFab={true}
                content={<RecurringPaymentInfo  firebase={props.firebase} token={props.allCookies.token} history={props.history} />}
            />
        </>
    );
};

const condition = (authUser) => !!authUser;

const RecurringPaymentPage = compose(
    withAuthorization(condition),
    withRouter,
    withFirebase,
    withCookies
)(RecurringPaymentBase);

export default RecurringPaymentPage;
