import React, { useEffect, useState } from "react";
import "../../App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import * as ROUTES from "../../constants/route";
import SignUpCheckEmailVerificationPage from "../Signup/signupCheckEmailVerification";
import SignInPage from "../Signin";
import ForgotPasswordPage from "../ForgotPassword";
import ForgotPasswordVerification from "../ForgotPassword/ForgotPasswordVerification";
import ConfirmNewPasswordPage from "../ConfirmNewPassword/";
import ConfirmNewPasswordVerication from "../ConfirmNewPassword/ConfirmNewPasswordVerification";
import PrivacyPolicyPage from "../PrivacyPolicy";
import EmailVerifyFailedPage from "../GenericPages/emailVerificationFailed";
import EmailVerifySuccessPage from "../GenericPages/emailVerificationSuccess";
import EmailVerifyHandler from "../GenericPages/emailHandler";
import Dashboard from "../Dashboard";
import LongKYCPage from "../kyc/shortKYC";
import LongKYCLastPage from "../kyc/LongKYC"
import { Fade } from "@material-ui/core";
import { withAuthentication } from "../Session";
import MyInvestments from "../MyInvestments";
import MyInvestmentsByCategory from "../MyInvestments/MyInvestmentsByCategory";
import Funds from "../MyInvestments/Funds";
import InvestmentHistory from "../MyInvestments/InvestmentHistory";
import InvestmentHistoryByTransactionId from "../MyInvestments/InvestmentHistoryByTransactionId";
import PaymentPage from "../MyInvestments/Payment/Payment";
import UploadDepositSlipPage from "../MyInvestments/UploadDepositSlip";
import InvestmentByCompany from "../MyInvestments/InvestmentByCompany";
import AccountProfile from "../AccountProfile";
import Declined from "../kyc/shortKYC/Declined";
import DeclinedUpgrade from "../kyc/LongKYC/Declined";
import InvalidToken from "../GenericPages/InvalidToken";
import SessionEndedPage from "../GenericPages/sessionEnded";
import NotFoundPage from "../GenericPages/NotFoundPage";
import TermsAndConditionsPage from "../TermsAndConditions";
import RedemptionPage from "../Redemption";
import Navps from "../NAVPS";
import LandingPage from "../Landing/LandingPage";
import { FAQsPage, FrequentlyAskedQuestionsDashboard } from "../FAQs";
import RecurringPaymentPage from "../MyInvestments/RecurringInvestment/index.js";
import RecurringHistoryPage from "../MyInvestments/RecurringInvestment/recurringHistory.js";
import { compose } from "recompose";
import { withFirebase } from "../Firebase/context.js";
import MaintenancePage from "../Maintenance/index.js";
import { auth } from "../Firebase/firebase.js";
import UpComing from "../Maintenance/UpComing.js";

const App = (props) => {
  const [isMaintenance, setMaintenance] = useState(null);
  const [upComing, setUpComing] = useState(null);
  const [isDev, setDev] = useState(null);

  useEffect(() => {
    const maintenanceListener = props.firebase.db
      .collection("miscellaneous")
      .doc("maintenance")
      .onSnapshot((doc) => {
        const maintenanceStatus = doc.data().enabled;
        const upComingStatus = doc.data();
        setMaintenance(maintenanceStatus);
        setUpComing(upComingStatus);
      });

    const authClaims = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const tokenResult = await user.getIdTokenResult();
        setDev(tokenResult.claims?.dev);
      } else {
        setDev(null);
      }
    });

    return () => {
      maintenanceListener();
      authClaims();
    };
  }, []);

  function ReturnPermission(component) {
    if (isMaintenance && isDev) {
      return component;
    } else if (isMaintenance && !isDev) return <Redirect to={ROUTES.LANDING} />;
    else {
      return component;
    }
  }


  return (
    <Fade in={isMaintenance !== null}>
      <div>
        <Router>
          <Switch>
            <Route exact path={ROUTES.LANDING}>
              {isMaintenance ? (
                MaintenancePage
              ) : upComing ? (
                <>
                  <UpComing
                    upComing={upComing}
                    onChange={() =>
                      setUpComing({ ...upComing, hasUpcoming: false })
                    }
                  />
                  <LandingPage />
                </>
              ) : (
                <LandingPage />
              )}
            </Route>
            {/* todo: not sure why there's another route to landing page  */}
            <Route
              exact
              path={ROUTES.SIGN_UP}
              component={isMaintenance ? MaintenancePage : LandingPage}
            />
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.FORGOT_PASSWORD}
                component={ForgotPasswordPage}
              />
            )}
            {ReturnPermission(
              <Route
                path={ROUTES.FORGOT_PASSWORD_VERIFICATION}
                component={ForgotPasswordVerification}
              />
            )}
            {ReturnPermission(
              <Route
                path={`${ROUTES.CONFIRM_NEW_PASSWORD}/:code`}
                component={ConfirmNewPasswordPage}
              />
            )}
            {ReturnPermission(
              <Route
                path={ROUTES.CONFIRM_NEW_PASSWORD_VERIFICATION}
                component={ConfirmNewPasswordVerication}
              />
            )}
            {ReturnPermission(
              <Route
                path={ROUTES.SIGN_UP_LANDING}
                component={SignUpCheckEmailVerificationPage}
              />
            )}
            {ReturnPermission(
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            )}
            {ReturnPermission(
              <Route
                path={ROUTES.PRIVACY_POLICY}
                component={PrivacyPolicyPage}
              />
            )}
            {ReturnPermission(
              <Route
                path={ROUTES.EMAIL_VERIFY_FAILED}
                component={EmailVerifyFailedPage}
              />
            )}
            {ReturnPermission(
              <Route
                path={ROUTES.EMAIL_VERIFY_SUCCESS}
                component={EmailVerifySuccessPage}
              />
            )}
            {ReturnPermission(
              <Route
                path={ROUTES.EMAIL_VERIFY_HANDLER}
                component={EmailVerifyHandler}
              />
            )}
            {ReturnPermission(
              <Route
                exact
                path={`${ROUTES.INVESTMENT_HISTORY}/:id`}
                component={InvestmentHistoryByTransactionId}
              />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.INVESTMENT_PAYMENT + "/:fundid/payment"}
                component={PaymentPage}
              />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.INVESTMENT_HISTORY}
                component={InvestmentHistory}
              />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.RECURRING_INVESTMENT_HISTORY}
                component={RecurringHistoryPage}
              />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.MY_INVESTMENTS}
                component={MyInvestments}
              />
            )}
            {ReturnPermission(
              <Route
                exact
                path={`${ROUTES.INVESTMENT_UPLOAD_DEPOSIT_SLIP}/:id`}
                component={UploadDepositSlipPage}
              />
            )}
            {ReturnPermission(
              <Route exact path={ROUTES.FUNDS + "/:id"} component={Funds} />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.MY_INVESTMENTS + "/:code"}
                component={MyInvestmentsByCategory}
              />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.INVESTMENT_BY_COMPANY + "/:id"}
                component={InvestmentByCompany}
              />
            )}
            {ReturnPermission(
              <Route path={ROUTES.LONG_KYC} component={LongKYCPage} />
            )}
            {ReturnPermission(
              <Route path={ROUTES.LONG_KYC_LAST} component={LongKYCLastPage} />
            )}
           
            {ReturnPermission(
              <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.ACCOUNT_PROFILE}
                component={AccountProfile}
              />
            )}
            {ReturnPermission(
              <Route exact path={ROUTES.DECLINED_PAGE} component={Declined} />
            )}
            {ReturnPermission(
              <Route exact path={ROUTES.DECLINEDUPGRADE_PAGE} component={DeclinedUpgrade} />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.REDEMPTION_PAGE}
                component={RedemptionPage}
              />
            )}
            {ReturnPermission(
              <Route exact path={ROUTES.INVALID} component={InvalidToken} />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.SESSION_ENDED}
                component={SessionEndedPage}
              />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.TERMS_AND_CONDITIONS}
                component={TermsAndConditionsPage}
              />
            )}
            {ReturnPermission(
              <Route exact path={ROUTES.FAQS} component={FAQsPage} />
            )}
            {ReturnPermission(
              <Route exact path={ROUTES.NAVPS} component={Navps} />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.LANDING_WITH_REFERRAL_CODE}
                // component={LandingPage}
              >
                {upComing ? (
                  <>
                    <UpComing
                      upComing={upComing}
                      onChange={() =>
                        setUpComing({ ...upComing, hasUpcoming: false })
                      }
                    />
                    <LandingPage />
                  </>
                ) : (
                  <LandingPage />
                )}
              </Route>
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.FAQS_NOAUTH}
                component={FrequentlyAskedQuestionsDashboard}
              />
            )}
            {ReturnPermission(
              <Route
                exact
                path={ROUTES.RECURRING_INVESTMENT_INFO}
                component={RecurringPaymentPage}
              />
            )}
            <Route component={NotFoundPage} />
            <Route>
              <Redirect to={ROUTES.LANDING} />
            </Route>
          </Switch>
        </Router>
      </div>
    </Fade>
  );
};

export default compose(withAuthentication, withFirebase)(App);
