import React, { Component } from "react";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import NavigationMenus from "../NavigationMenus";
import { AuthUserContext } from "../Session";
import VerticalTabs from "./VerticalTabs";
import AccountOverview from "./AccountOverview";
import EditAccount from "./EditAccount";
import ChangePassword from "./ChangePassword";
import MyApplication from "./MyApplication";
import VerifiedLabel from "./VerifiedLabel";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  Typography,
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Button,
  Fade,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountProfileFunction from "./AccountProfileFunction";
import ReusableModal from "./ReusableModal";
import Helpers from "../Helpers";

import {
  AccountCircleOutlined,
  VpnKeyOutlined,
  LockOutlined,
  ListAlt,
} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  mobileTableTitle: {
    fontWeight: "600",
    fontSize: "13px",
    minWidth: "50%",
  },
}));

const CustomAccordion = withStyles({
  root: {
    backgroundColor: "#256141",
    color: "white",
  },
  expanded: {},
})(MuiAccordion);

const CustomAccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    minHeight: 56,
  },
  expanded: {},
})(MuiAccordionSummary);

const CustomAccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    color: "black",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const AccountProfilePage = () => {
  const classes = useStyles();
  const mdUp = useMediaQuery(useTheme().breakpoints.up("md"));
  return (
    <>
      <NavigationMenus
        content={<AccountProfile mdUp={mdUp} classes={classes} />}
      />
    </>
  );
};

class AccountProfileBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {
          title: "Account Overview",
          icon: <AccountCircleOutlined />,
          content: <AccountOverview />,
        },
        // {
        //   title: "Edit Account",
        //   icon: <VpnKeyOutlined />,
        //   content: <EditAccount props={props} />,
        // },
        {
          title: "Change Password",
          icon: <LockOutlined />,
          content: <ChangePassword props={props} />,
        },
        // {
        //     title: "My Financial Advisor",
        //     icon: <RecordVoiceOver />,
        //     content: <MyCoach props={props} />,
        // },
        {
          title: "My Application",
          icon: <ListAlt />,
          content: <MyApplication props={props} />,
        },
      ],
      account_overview: null,
      edit_account: null,
      coach_profile: null,
      my_application: null,
      account_details: null,
      isLoadingEditAcct: false,
      isLoadingChangePW: false,
      currentPassword: "",
      currentPasswordError: "",
      password: "",
      passwordError: "",
      confirmPassword: "",
      confirmPasswordError: "",
      showModal: false,
      modalButton: "",
      modalTitle: "",
      modalContent: "",
    };
    this.helpers = new Helpers();
    this.accountProfileFunction = new AccountProfileFunction();
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    this.getAccountOverView();
    //this.checkIfGoogleAccount();
  }

  checkIfGoogleAccount = () => {
    var user = this.props.firebase.auth.currentUser;
    if (user.providerData[0].providerId !== "password") {
      this.passPropsTabsPanel(-1, "Change Password");
    }
  };

  getAccountOverView = async () => {
    const uid = this.context.uid;
    const userRef = await this.props.firebase.db
      .collection("users")
      .doc(uid)
      .get();
    try {
      const data = userRef.data();
      this.setState({
        account_overview: [
          {
            title: "Name",
            value: data.firstName + " " + data.lastName,
          },
          {
            title: "Email",
            value: data.email,
          },
          {
            title: "Mobile Number",
            value: data.mobileNumber,
          },
        ],
        edit_account: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mobileNumber: data.mobileNumber,
          riskProfile:
            data.riskProfile !== undefined ? data.riskProfile : "N/A",
        },
      });
      this.getKYC();
      this.passPropsTabsPanel(1, "Account Overview");
      this.passPropsTabsPanel(1, "Edit Account");
      this.getCoachProfile(data.investmentManager.investmentManagerId);
    } catch (error) {
      console.log(error);
    }
  };

  getKYC = async () => {
    const uid = this.context.uid;
    const userRef = await this.props.firebase.db
      .collection("users")
      .doc(uid)
      .collection("KYC")
      .doc("KYC")
      .get();
    try {
      const data = userRef.data();
      if (data !== undefined) {
        this.setState({
          my_application: data,
        });
        this.getAccountDetails(uid);
      } else {
        this.passPropsTabsPanel(-1, "My Application");
      }
    } catch (error) {
      console.log(error);
    }
  };

  getCoachProfile = async (uid) => {
    if (uid !== "") {
      const userRef = await this.props.firebase.db
        .collection("users")
        .doc(uid)
        .get();
      try {
        const data = userRef.data();
        this.setState({
          coach_profile: [
            {
              title: "Name",
              value: data.firstName + " " + data.lastName,
            },
            {
              title: "Email",
              value: data.email,
            },
            {
              title: "Mobile Number",
              value: data.mobileNumber,
            },
          ],
        });
        this.passPropsTabsPanel(1, "My Financial Advisor");
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState({ coach_profile: "N/A" });
    }
  };

  getAccountDetails = async (uid) => {
    if (uid !== "") {
      const userRef = await this.props.firebase.db
        .collection("users")
        .doc(uid)
        .collection("accountDetails")
        .doc("AD")
        .get();
      try {
        const data = userRef.data();
        if (data !== undefined) {
          this.setState({ account_details: data });
        } else {
          this.setState({
            account_details: {
              accountName: "",
              bankAccountNumber: "",
              bankName: "",
              savingsAccount: "N/A",
            },
          });
        }
        this.passPropsTabsPanel(1, "My Application");
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState({ account_details: "N/A" });
    }
  };

  passPropsTabsPanel = (index, num = null) => {
    var tabs = [...this.state.tabs];
    const { account_overview, edit_account, my_application, account_details } =
      this.state;
    var indexOfTab;
    tabs.forEach(function (value, key) {
      if (value.title === num) {
        indexOfTab = key;
      }
    });
    if (index === 1) {
      if (num === "Account Overview") {
        tabs[indexOfTab].content = (
          <AccountOverview account_overview={account_overview} />
        );
      } else if (num === "Edit Account") {
        tabs[indexOfTab].content = (
          <EditAccount props={this.props} edit_account={edit_account} />
        );
      }
      // else if (num === "My Financial Advisor") {
      //     tabs[indexOfTab].content = (
      //         <MyCoach props={this.props} coach_profile={coach_profile} />
      //     );
      // }
      else if (num === "My Application") {
        tabs[indexOfTab].content = (
          <MyApplication
            props={this.props}
            my_application={my_application}
            risk_profile={edit_account.riskProfile}
            account_details={account_details}
            mobile_number={edit_account.mobileNumber}
          />
        );
      }
    } else {
      tabs.splice(indexOfTab, 1);
    }
    this.setState({ tabs: tabs });
  };

  update = (type) => {
    if (type === "success") {
      window.location.reload();
    }
    this.setState({ showModal: false, isLoading: false });
  };

  onChange = (event) => {
    const me = this;
    if (event.target.name === "mobileNumber") {
      let copiedStateEditAcc = this.state.edit_account;
      const newVal = event.target.value.replace(/[^\d]/, "");
      copiedStateEditAcc.mobileNumber = newVal;
      this.setState({ edit_account: copiedStateEditAcc });
    } else {
      this.setState({ [event.target.name]: event.target.value }, () => {
        var result = this.helpers.validator(me.state, event.target.name);
        me.setState({ [result.name]: result.error });
      });
    }
  };

  editAccountSave = (e) => {
    e.preventDefault();
    const uid = this.context.uid;
    console.log(uid);
    this.setState({ isLoadingEditAcct: true });
    this.accountProfileFunction
      .doUpdateAccount(
        uid,
        this.state.edit_account.email,
        this.state.edit_account.mobileNumber
      )
      .then((response) => {
        var modalButton = (
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={() => this.update("success")}
            fullWidth
          >
            OK
          </Button>
        );
        if (response.data.ok) {
          this.setState({
            modalTitle: "Success",
            modalContent: "Thank you for updating your mobile number!",
            modalButton: modalButton,
            showModal: true,
          });
        } else {
          this.setState({
            modalButton: modalButton,
            modalTitle: "Warning!",
            modalContent: response.data.message,
            showModal: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        var modalButton = (
          <Button
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            onClick={() => this.update("error")}
            fullWidth
          >
            Error
          </Button>
        );
        this.setState({
          modalButton: modalButton,
          modalTitle: "Warning!",
          modalContent: error.message,
          showModal: true,
        });
      });
  };

  changePW = (e) => {
    e.preventDefault();
    const {
      currentPassword,
      password,
      currentPasswordError,
      passwordError,
      confirmPasswordError,
    } = this.state;
    const isValid =
      currentPasswordError === "" &&
      passwordError === "" &&
      confirmPasswordError === "" &&
      currentPassword !== "" &&
      password !== "";

    if (isValid) {
      var user = this.props.firebase.auth.currentUser;
      this.setState({ isLoading: true });

      user
        .reauthenticateWithCredential(
          this.props.firebase.emailAuthProvider.credential(
            user.email,
            currentPassword
          )
        )
        .then(() => {
          return user.updatePassword(password);
        })
        .then(() => {
          var modalButton = (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={() => this.update("success")}
              fullWidth
            >
              OK
            </Button>
          );
          this.setState({
            modalButton: modalButton,
            modalTitle: "Success!",
            modalContent:
              "Looks good! You have successfully changed your password.",
            showModal: true,
            currentPassword: "",
            password: "",
            confirmPassword: "",
          });
        })
        .catch((error) => {
          let errorMessage = error.message;
          if (error.code === "auth/wrong-password")
            errorMessage = "You have entered an invalid password.";

          var modalButton = (
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={() => this.update("error")}
              fullWidth
            >
              Error
            </Button>
          );
          this.setState({
            modalButton: modalButton,
            modalTitle: "Oops! Please try again.",
            modalContent: errorMessage,
            showModal: true,
            currentPassword: "",
            password: "",
            confirmPassword: "",
          });
        });
    }
  };

  render() {
    document.body.style.backgroundColor = "white";
    const {
      tabs,
      my_application,
      account_overview,
      edit_account,
      account_details,
      isLoadingEditAcct,
      isLoadingChangePW,
      showModal,
      modalTitle,
      modalContent,
      modalButton,
      currentPassword,
      currentPasswordError,
      password,
      passwordError,
      confirmPasswordError,
      confirmPassword,
    } = this.state;
    const { mdUp } = this.props;

    let renderAccountOverview = (
      <tr>
        <td colSpan="2">No Data Available</td>
      </tr>
    );
    if (account_overview !== null) {
      renderAccountOverview = account_overview.map((acc, index) => (
        <tr key={index}>
          <td>{acc.title}</td>
          <td>{acc.value}</td>
        </tr>
      ));
    }

    return (
      <>
        <VerifiedLabel kyc={my_application} />
        {mdUp ? (
          <VerticalTabs tabs={tabs} />
        ) : (
          <div>
            <ReusableModal
              show={showModal}
              title={modalTitle}
              content={modalContent}
              button={modalButton}
            />
            <CustomAccordion>
              <CustomAccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <Typography>Account Overview</Typography>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                <div>
                  <table className="tbl-border-bottom">
                    <tbody>{renderAccountOverview}</tbody>
                  </table>
                </div>
              </CustomAccordionDetails>
            </CustomAccordion>
            <br />
            <CustomAccordion>
              <CustomAccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <Typography>Edit Account</Typography>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                <form onSubmit={this.submit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <label className="dark-label bold">First Name</label>
                      <TextField
                        name="firstName"
                        value={
                          edit_account !== null ? edit_account.firstName : "n/a"
                        }
                        inputProps={{ style: { padding: "12px" } }}
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        required
                        placeholder="Your first name..."
                        autoComplete="off"
                        onChange={this.onChange}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <label className="dark-label bold">Last Name</label>
                      <TextField
                        name="lastName"
                        value={
                          edit_account !== null ? edit_account.lastName : "n/a"
                        }
                        inputProps={{ style: { padding: "12px" } }}
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        required
                        placeholder="Your last name..."
                        autoComplete="off"
                        onChange={this.onChange}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <label className="dark-label bold">Email Address</label>
                      <TextField
                        type="email"
                        name="email"
                        value={
                          edit_account !== null ? edit_account.email : "n/a"
                        }
                        inputProps={{ style: { padding: "12px" } }}
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        required
                        placeholder="Your email address..."
                        autoComplete="off"
                        onChange={this.onChange}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <label className="dark-label bold">Mobile Number</label>
                      <FormControl fullWidth>
                        <OutlinedInput
                        disabled
                          name="mobileNumber"
                          value={
                            edit_account !== null
                              ? edit_account.mobileNumber.split("+63")[1]
                              : "n/a"
                          }
                          // className={classes.margin}
                          inputProps={{
                            style: {
                              padding: "12px 0px 12px 12px",
                            },
                            maxLength: 10,
                          }}
                          required
                          autoComplete="off"
                          startAdornment={
                            <InputAdornment position="start">
                              +63
                            </InputAdornment>
                          }
                          onChange={(e) => this.onChange(e)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br /> <br />
                  {!isLoadingEditAcct ? (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ color: "white" }}
                      onClick={(e) => this.editAccountSave(e)}
                      fullWidth
                    >
                      Save Changes
                    </Button>
                  ) : (
                    <CircularProgress color="primary" />
                  )}
                </form>
              </CustomAccordionDetails>
            </CustomAccordion>
            <br />
            <CustomAccordion>
              <CustomAccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <Typography>Change Password</Typography>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                <form onSubmit={this.submit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="dark-label bold">
                        Current Password
                      </label>
                      <TextField
                        type="password"
                        name="currentPassword"
                        value={currentPassword}
                        inputProps={{ style: { padding: "12px" } }}
                        variant="outlined"
                        fullWidth
                        required
                        placeholder="Your current password..."
                        autoComplete="off"
                        onChange={this.onChange}
                      />
                      <Fade in={true}>
                        <FormHelperText style={{ color: "red" }}>
                          {currentPasswordError}
                        </FormHelperText>
                      </Fade>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="dark-label bold">New Password</label>
                      <TextField
                        type="password"
                        name="password"
                        value={password}
                        inputProps={{ style: { padding: "12px" } }}
                        variant="outlined"
                        fullWidth
                        required
                        placeholder="Your current password..."
                        autoComplete="off"
                        onChange={this.onChange}
                      />
                      <Fade in={true}>
                        <FormHelperText style={{ color: "red" }}>
                          {passwordError}
                        </FormHelperText>
                      </Fade>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label className="dark-label bold">
                        Confirm Password
                      </label>
                      <TextField
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        inputProps={{ style: { padding: "12px" } }}
                        variant="outlined"
                        fullWidth
                        required
                        placeholder="Confirm your password..."
                        autoComplete="off"
                        onChange={this.onChange}
                      />
                      <Fade in={true}>
                        <FormHelperText style={{ color: "red" }}>
                          {confirmPasswordError}
                        </FormHelperText>
                      </Fade>
                    </Grid>
                  </Grid>
                  <br /> <br />
                  {!isLoadingChangePW ? (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ color: "white" }}
                      onClick={(e) => this.changePW(e)}
                      fullWidth
                    >
                      Change Password
                    </Button>
                  ) : (
                    <CircularProgress color="primary" />
                  )}
                </form>
              </CustomAccordionDetails>
            </CustomAccordion>
            <br />
            {/* <CustomAccordion>
                            <CustomAccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                            >
                                <Typography>My Financial Advisor</Typography>
                            </CustomAccordionSummary>
                            <CustomAccordionDetails>
                                <div>
                                    <table className='tbl-border-bottom'>
                                        <tbody>
                                            {renderCoachProfile}
                                        </tbody>
                                    </table>
                                </div>
                            </CustomAccordionDetails>
                        </CustomAccordion>
                        <br /> */}
            <CustomAccordion>
              <CustomAccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <Typography>My Application</Typography>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                {my_application !== null &&
                edit_account !== null &&
                account_details !== null ? (
                  <MyApplication
                    props={this.props}
                    my_application={my_application}
                    risk_profile={edit_account.riskProfile}
                    account_details={account_details}
                    mobile_number={edit_account.mobileNumber}
                  />
                ) : (
                  ""
                )}
              </CustomAccordionDetails>
            </CustomAccordion>
          </div>
        )}
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const AccountProfile = compose(
  withAuthorization(condition),
  withFirebase
)(AccountProfileBase);

export default AccountProfilePage;

export { AccountProfile };
