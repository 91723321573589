import Firebase from "../Firebase/firebase";
import moment from "moment";

export default class Helpers {
  constructor() {
    this.firebase = new Firebase();
  }

  timeStampFormatter = (timeStamp) => {
    var d = new Date(timeStamp.toDate());
    var dateString = moment.unix(timeStamp.seconds).format("MM/DD/YYYY");
    var hours = d.getHours();
    var minutes = d.getMinutes();
    var time = this.tConvert(hours, minutes);

    return `${dateString} ${time}`;
  };

  tConvert = (hours, minutes) => {
    var AmOrPm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var finalTime =
      hours + ":" + (minutes > 9 ? minutes : `${0}${minutes}`) + " " + AmOrPm;
    return finalTime;
  };

  formatToPHP = (value, decimalPlaces) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
      minimumFractionDigits: decimalPlaces,
    });

    return formatter.format(value);
  };

  numberCommaSeparated = (value) => {
    const formatter = new Intl.NumberFormat("en-US");

    return formatter.format(value);
  };

  dateFormat = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };

  phoneNumIsFromPH = (phoneNumber) => /^63/.test(phoneNumber);

  getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  setCookie = (cName, cValue) => {
    document.cookie = cName + "=" + cValue + "; path=/";
  };

  handleError = (error) => {
    if (error.response !== undefined) {
      const status = error.response.status;
      if (status === 401 || status === 403) {
        if (status === 401) window.location.href = "/invalid";
        else alert(error.response.data.message);
      }
    }
  };

  validator = (state, name) => {
    const {
      firstName,
      lastName,
      email,
      mobileNumber,
      password,
      confirmPassword,
      currentPassword,
    } = state;
    switch (name) {
      case "firstName":
        if (firstName === "") {
          return {
            name: "firstNameError",
            error: "Please input first name.",
          };
        } else {
          return { name: "firstNameError", error: "" };
        }
      case "lastName":
        if (lastName === "") {
          return {
            name: "lastNameError",
            error: "Please input last name.",
          };
        } else {
          return {
            name: "lastNameError",
            error: "",
          };
        }
      case "email":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          return {
            name: "emailError",
            error: "Please input a valid email address.",
          };
        } else {
          return {
            name: "emailError",
            error: "",
          };
        }
      case "password":
        var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,64}$)"
        );
        if (!strongRegex.test(password)) {
          return {
            name: "passwordError",
            error:
              "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
          };
        } else {
          return {
            name: "passwordError",
            error: "",
          };
        }
      case "confirmPassword":
        if (confirmPassword !== password) {
          return {
            name: "confirmPasswordError",
            error: "Password doesn't match.",
          };
        } else {
          return {
            name: "confirmPasswordError",
            error: "",
          };
        }
      case "mobileNumber":
        if (mobileNumber.length < 10) {
          return {
            name: "mobileNumberError",
            error: "Please input correct mobile number",
          };
        } else {
          return {
            name: "mobileNumberError",
            error: "",
          };
        }
      case "currentPassword":
        if (currentPassword === "") {
          return {
            name: "currentPasswordError",
            error: "Please input your current password.",
          };
        } else {
          return {
            name: "currentPasswordError",
            error: "",
          };
        }
      default:
        break;
    }
  };
}
