import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import * as ROUTES from "../../constants/route";

class EmailVerificationSuccess extends Component {
  constructor(props) {
    super(props);
    setTimeout(() => {
      this.props.history.push(ROUTES.SIGN_IN);
    }, 10000);
  }

  render() {
    return (
      <Container>
        <div id="message" className="r-title-container text-center">
          <p className="r-title">Email verification was successful.</p>
          <p className="r-subtitle">
            You will be redirected to back to the login page, please wait a
            moment...
          </p>
        </div>
      </Container>
    );
  }
}

export default EmailVerificationSuccess;
