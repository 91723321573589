import React, { Component } from "react";
import ForgotPasswordContainer from "./ForgotPasswordContainer";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import Navbar from "../Navbar";

const ForgotPasswordPage = () => {
  return <ForgotPasswordForm />;
};

class ForgotPasswordBase extends Component {
  render() {
    return (
      <>
        <Navbar />
        <ForgotPasswordContainer props={this.props} />
      </>
    );
  }
}

const ForgotPasswordForm = compose(
  withRouter,
  withFirebase
)(ForgotPasswordBase);

export default ForgotPasswordPage;

export { ForgotPasswordForm };
