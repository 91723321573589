import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { withFirebase } from "../Firebase";
import withoutAuthorization from "../Session/withoutAuthorization";
import { compose } from "recompose";
import { Button, Grid, Paper } from "@material-ui/core";

class InvalidToken extends Component {
  constructor(props) {
    super(props);
    this.props.firebase.doSignOut();
  }
  render() {
    return (
      <Container>
        <div
          className="inverted-bg"
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
          }}
        >
          <Container maxWidth="sm" style={{ marginTop: "270px" }}>
            <Paper style={{ padding: "30px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                  <span style={{ fontWeight: "600" }}>
                    Uh oh, looks like your session has expired.
                  </span>
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                  <span style={{ fontWeight: "600" }}>
                    Don&apos;t worry! If you were investing or redeeming, rest
                    assured that you may sign-in and try again!
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                    variant="contained"
                    color="secondary"
                    style={{ color: "white", padding: "10px 40px" }}
                  >
                    Return to Log In
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </div>
      </Container>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(
  withoutAuthorization(condition),
  withFirebase
)(InvalidToken);
