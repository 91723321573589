import { Divider } from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { makeStyles, Grid, Fade } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import clsx from "clsx";
import { useForm } from "react-hook-form";

import CustomButton from "../../Button";
import { RadioForm } from "../shortKYC/fieldAndimage";

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: "10px",
  },

}));

const FATCA = (props) => {
  const classes = useStyles();
  const { FATCAData,initialLoad, editKYC } = props;


  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    getValues,
  } = useForm();


  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "usPerson") {
        if (value.usPerson === "0") {
          const currentValues = getValues();
          Object.keys(currentValues).forEach((field) => {
            if (field !== "usPerson") {
              setValue(field, "");
              
            }
          });
        }
      }
      props.handleChangeFATCA(value);
    });

    return () => subscription.unsubscribe();
  }, [watch]);


  if (initialLoad) {
    return <div>Loading...</div>;
  }
  const onSubmit = async (data) => {
    props.nextStep();
    props.fixedStep(4);
    console.log(data);
  };

    let questions = [
      {
        text: "Are you a US Person?",
        data: FATCAData.usPerson,
        name: "usPerson",
      },
    ];

      if (watch("usPerson") === "1") {
        questions = [
          {
            text: "Are you a US Person?",

            data: FATCAData.usPerson,
            name: "usPerson",
          },
          {
            text: "Are you a US citizen?",
            data: FATCAData.usCitizen,
            name: "usCitizen",
          },
          {
            text: "US resident?",
            data: FATCAData.usResident,
            name: "usResident",
          },
          {
            text: "US resident alien?",
            data: FATCAData.usAlien,
            name: "usAlien",
          },
          {
            text: "Do you have a current US telephone number?",
            data: FATCAData.usPhone,
            name: "usPhone",
          },
          {
            text: "Where you born in the US?",
            data: FATCAData.usBorn,
            name: "usBorn",
          },
          {
            text: "Do you have a current US residence address?",
            data: FATCAData.usAddressResidence,
            name: "usAddressResidence",
          },
          {
            text: "Do you have a current US mailing address? (Including US P.O.)",
            data: FATCAData.usAddressMailing,
            name: "usAddressMailing",
          },
          {
            text: "Have you issued a standing instruction to transfer funds to an account?",
            data: FATCAData.usAccount,
            name: "usAccount",
          },
          {
            text: "Have you issued a power of Attorney or granted signatory authority to a person with US address?",
            data: FATCAData.usPOA,
            name: "usPOA",
          },
          {
            text: "Do you have “in care of” address or “hold mail” address that is the sole address for an account (whether such address in the US or outside the US)?",
            data: FATCAData.usCareOfAddress,
            name: "usCareOfAddress",
          },
        ];
      }

      const questionList = questions.map( (item, index) =>{
        return (
          <Grid
            key={index}
            item
            container
            xs={12}
            spacing={3}
            className={classes.margin}
          >
            <Grid item xs={12} sm={8}>
              <label style={{ fontWeight: "600" }} className="dark-label">
                {item.text}
              </label>
            </Grid>
            <Grid item xs={12} sm={4}>
              <RadioForm
                disabled={false}
                trigger={trigger}
                error={errors}
                control={control}
                name={item.name}
                rules={{ required: "This field is required" }}
                className={clsx(classes.margin, "pull-left")}
                label={item.text}
                value={item.data}
              />
            </Grid>
          </Grid>
        );
      });
  return (
    <Fade in={true}>
      <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1>FATCA (Foreign Account Tax Compliance Act)</h1>
          </Grid>
          <Divider />
          {questionList}

          <Grid item xs={12}>
            <div className="flex">
              <div className="btn-back" onClick={props.prevStep}>
                Previous Step
              </div>

              <CustomButton
                text="Continue"
                type="submit"
                disabled={false}
                buttonTheme="primary"
              />
            </div>
          </Grid>

          <Divider />
        </Grid>
      </ValidatorForm>
    </Fade>
  );
};


export default FATCA;

