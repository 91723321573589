import { Button, Card, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import CancelIcon from "@material-ui/icons/Cancel";

import DropDownField from "../../ValidatedFields/DropDownField";
import selfieHoldingId from "../../../images/Rampver_SelfieHoldingId_Man_WomanGIF_1.gif";

export const TextForm = ({
  error,
  name,
  control,
  rules,
  placeholder,
  type,
  inputProps,
  trigger,
  className,
  disabled,
  value,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      render={({ field: { ref, ...field } }) => (
        <TextField
          {...field}
          color="primary"
          fullWidth
          type={type}
          inputRef={ref}
          onChange={(e) => {
            field.onChange(e);
            trigger(name);
          }}
          // onBlur={() => trigger(name)}
          className={className}
          disabled={disabled}
          placeholder={placeholder}
          variant="outlined"
          error={!!error[name]}
          helperText={error[name] && error[name].message}
          inputProps={inputProps}
        />
      )}
    />
  );
};

export const RadioForm = ({
  error,
  name,
  control,
  rules,
  trigger,
  className,
  disabled,
  value,
  label,
  styles
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      {styles && <label>{label}</label>}

      <div>
        <Controller
          name={name}
          control={control}
          defaultValue={value}
          rules={rules}
          render={({ field: { ref, ...field } }) => (
            <RadioGroup
              {...field}
              disabled={disabled}
              row
              className={className}
              onChange={(event) => {
                field.onChange(event);
              }}
              inputRef={ref}
              error={!!error[name]}
            >
              <FormControlLabel
                value="1"
                style={{ marginLeft: "5px" }}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                style={{ marginLeft: "20px" }}
                value="0"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          )}
        />
        {error[name] && (
          <FormHelperText style={{ color: "red" }}>
            {error[name].message}
          </FormHelperText>
        )}
      </div>
    </FormControl>
  );
};
export const SelectForm = ({
  error,
  name,
  control,
  rules,
  placeholder,
  inputProps,
  trigger,
  className,
  disabled,
  value,
  options,
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        rules={rules}
        render={({ field: { ref, ...field } }) => (
          <Select
            {...field}
            className={className}
            disabled={disabled}
            onChange={(event) => {
              field.onChange(event);
             
            }}
            // placeholder={"placeholder"}
            inputRef={ref}
            inputProps={inputProps}
            error={!!error[name]}
            displayEmpty
          >
            <MenuItem value="" disabled>
              <span
                style={{
                  color: "#a3a3a3",
                  fontFamily: "Gill Sans, Poppins",
                  fontWeight: 200,
                }}
              >
                {placeholder}
              </span>
            </MenuItem>

            {options.value
              ? options.name.map((name, index) => (
                  <MenuItem key={index} value={options.value[index]}>
                    {name}
                  </MenuItem>
                ))
              : options.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
          </Select>
        )}
      />
      {error[name] && (
        <FormHelperText style={{ color: "red" }}>
          {error[name].message}
        </FormHelperText>
      )}
    </FormControl>
  );
};
export const SelectFormAddress = ({
  error,
  name,
  control,
  rules,
  placeholder,
  inputProps,
  trigger,
  className,
  disabled,
  value,
  options,
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <Controller
        name={name}
        control={control}
        defaultValue={value?.name }
        rules={rules}
        render={({ field: { ref, ...field } }) => (
          <Select
            {...field}
            className={className}
            disabled={disabled}
            onChange={(event) => {
              field.onChange(event);
            }}
            // placeholder={"placeholder"}
            inputRef={ref}
            inputProps={inputProps}
            error={!!error[name]}
            displayEmpty
          >
            <MenuItem value="" disabled>
              <span
                style={{
                  color: "#a3a3a3",
                  fontFamily: "Gill Sans, Poppins",
                  fontWeight: 200,
                }}
              >
                {placeholder}
              </span>
            </MenuItem>
            {options.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />

      {error[name] && (
        <FormHelperText style={{ color: "red" }}>
          {error[name].message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const handlePreviewImage = (
  fileType,
  imageData,
  imageValue,
  design,
  removeText, 
  props
) => {
  const style = design
    ? "w-2/3 max-h-32 lg:w-2/5 m-5 lg:max-h-36"
    : "w-2/3 max-h-32  m-5 sm:max-h-44 md:max-h-60 lg:max-h-80";

  const imageLabel = (
    <div className="flex justify-center ml-0 mb-2 items-center h-8 w-auto sm:ml-3 sm:w-11/12 md:w-9/12 lg:w-7/12 lg:ml-3 md:ml-10 text-black border-2 border-solid border-gray-200 rounded-3xl">
      <span className="ml-5 sm:ml-3">{imageValue}</span>
      <IconButton onClick={props.handleDropzoneDelete(removeText)}>
        <CancelIcon fontSize="small" />
      </IconButton>
    </div>
  );

  return (
    <div className="border-2 border-solid border-gray-200 text-center ">
      {fileType.startsWith("image/") ? (
        <>
          <img src={imageData} alt="" className={style} />

          {imageLabel}
        </>
      ) : (
        fileType.startsWith("application/") && (
          <>
            <embed src={imageData} type="application/pdf" className={style} />
            {imageLabel}
          </>
        )
      )}
    </div>
  );
};


export const editIdPhotoFrontSwitch = (isEdit, main, classes, props) => {
  let design = true;
  let removeText = "idPhotoFront";

  switch (isEdit) {
    case true:
      return (
        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.margin}>
          <label>
            Current Front Side ID
          </label>
          <Card className={classes.root}>
            <DropDownField
              onAdd={props.handleDropzone("idPhotoFront")}
              onDelete={props.handleDropzoneDelete("idPhotoFront")}
              filesLimit={1}
              value={main.idPhotoFrontLabel}
              image={main.idPhotoFrontPreview}
              validators={["required"]}
              errorMessages={["This field is required."]}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={() =>
                window.open(
                  main.oldIdPhotoFront,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              fullWidth
            >
              View my current photo
            </Button>
          </Card>
        </Grid>
      );

    case false:
      return (
        <>
          <Grid
            item
            xs={12}
            sm={6}
            lg={6}
            className={classes.margin}
            justify="center"
            alignItems="center"
          >
            <p style={{ fontWeight: "600", fontStyle: "italic" }}>
              {main.noValidID
                ? "Valid Secondary ID #1"
                : "Front side of the ID"}
            </p>
            {main.idPhotoFrontPreview !== null ? (
              <div className="border-2 border-solid border-gray-200 text-center ">
                {handlePreviewImage(
                  main.idPhotoFrontPreview.file.type,
                  main.idPhotoFrontPreview.data,
                  main.idPhotoFrontLabel,
                  design,
                  removeText,
                  props
                )}
              </div>
            ) : (
              <DropDownField
                onAdd={props.handleDropzone("idPhotoFront")}
                onDelete={props.handleDropzoneDelete("idPhotoFront")}
                filesLimit={1}
                value={main.idPhotoFrontLabel}
                image={main.idPhotoFrontPreview}
                validators={["required"]}
                errorMessages={["This field is required."]}
              />
            )}
          </Grid>
        </>
      );
    default:
      break;
  }
};

 export const editIdPhotoBackSwitch = (isEdit, main, classes, props) => {
   let design = true;
   let removeText = "idPhotoBack";
   switch (isEdit) {
     case true:
       return (
         <Grid item xs={12} sm={6} md={6} lg={6} className={classes.margin}>
           <label>
            Current Back ID
           </label>
           <Card className={classes.root}>
             <DropDownField
               onAdd={props.handleDropzone("idPhotoBack")}
               onDelete={props.handleDropzoneDelete("idPhotoBack")}
               filesLimit={1}
               value={main.idPhotoBackLabel}
               image={main.idPhotoBackPreview}
               validators={["required"]}
               errorMessages={["This field is required."]}
             />
             <Button
               variant="contained"
               color="primary"
               style={{ color: "white" }}
               onClick={() =>
                 window.open(
                   main.oldIdPhotoBack,
                   "_blank",
                   "noopener,noreferrer"
                 )
               }
               fullWidth
             >
               View my current photo
             </Button>
           </Card>
         </Grid>
       );

     case false:
       return (
         <Grid
           item
           xs={12}
           sm={6}
           className={classes.margin}
           justify="center"
           alignItems="center"
         >
           <p style={{ fontWeight: "600", fontStyle: "italic" }}>
             <label>
               {main.noValidID
                 ? "Valid Secondary ID #2"
                 : "Back side of the ID"}
             </label>
           </p>
           {main.idPhotoBackPreview !== null ? (
             <div className="border-2 border-solid border-gray-200 text-center ">
               {handlePreviewImage(
                 main.idPhotoBackPreview.file.type,
                 main.idPhotoBackPreview.data,
                 main.idPhotoBackLabel,
                 design,
                 removeText,
                 props
               )}
             </div>
           ) : (
             <DropDownField
               onAdd={props.handleDropzone("idPhotoBack")}
               onDelete={props.handleDropzoneDelete("idPhotoBack")}
               filesLimit={1}
               value={main.idPhotoBackLabel}
               image={main.idPhotoBackPreview}
               validators={["required"]}
               errorMessages={["This field is required."]}
             />
           )}
           <div />
         </Grid>
       );

     default:
       break;
   }
 };

 export const editSelfieSwitch = (isEdit, main, classes, props) => {
   let design = false;
   let removeText = "selfie";
   switch (isEdit) {
     case true:
       return (
         <Grid item xs={12} sm={12} className={classes.margin}>
           <label>Current Selfie</label>
           <Card className={classes.root}>
             <DropDownField
               onAdd={props.handleDropzone("selfie")}
               onDelete={props.handleDropzoneDelete("selfie")}
               filesLimit={1}
               value={main.selfieLabel}
               image={main.selfiePreview}
               validators={["required"]}
               errorMessages={["This field is required."]}
             />
             <Button
               variant="contained"
               color="primary"
               style={{ color: "white" }}
               onClick={() =>
                 window.open(main.oldSelfie, "_blank", "noopener,noreferrer")
               }
               fullWidth
             >
               View my current photo
             </Button>
           </Card>
         </Grid>
       );
     case false:
       return (
         <>
           <Grid container spacing={1} columns={16}>
             <Grid item xs={12}>
               <p style={{ fontWeight: "600", fontStyle: "italic" }}>
                 Please upload a clear client selfie holding your presented
                 valid ID.
               </p>
             </Grid>

             <Grid
               item
               xs={12}
               sm={6}
               justify="center"
               alignItems="center"
               className="relative "
             >
               {main.selfiePreview !== null ? (
                 handlePreviewImage(
                   main.selfiePreview.file.type,
                   main.selfiePreview.data,
                   main.selfieLabel,
                   design,
                   removeText,
                   props
                 )
               ) : (
                 <FormControl fullWidth>
                   <DropDownField
                     className={true}
                     onAdd={props.handleDropzone("selfie")}
                     onDelete={props.handleDropzoneDelete("selfie")}
                     filesLimit={1}
                     value={main.selfieLabel}
                     image={main.selfiePreview}
                     validators={["required"]}
                     errorMessages={["This field is required."]}
                   />
                 </FormControl>
               )}
             </Grid>
             <Grid
               item
               xs={12}
               sm={6}
               justify="center"
               alignItems="center"
               className="relative"
             >
               <div className="border-2 border-solid border-gray-200 text-center ">
                 <img
                   src={selfieHoldingId}
                   alt=""
                   className={"h-auto m-5  w-2/3 sm:h-44 md:h-60 lg:h-80"}
                 />
               </div>

               <div className="h-12 w-full sm:w-11/12 md:w-full lg:w-full text-white bg-green-900 text-base font-bold rounded-md text-center py-3">
                 Preview
               </div>
             </Grid>
           </Grid>
         </>
       );

     default:
       break;
   }
 };
