import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import * as ROUTES from "../../constants/route";

class EmailVerificationFailed extends Component {
  constructor(props) {
    super(props);

    this.props.firebase.doSignOut();
    setTimeout(() => {
      this.props.history.push(ROUTES.SIGN_IN);
    }, 10000);
  }
  render() {
    return (
      <Container>
        <div id="message" className="r-title-container text-center">
          <p className="r-title">Email Verification Failed</p>
          <p className="r-subtitle">
            Either your email was verified or verification code was already been
            used or expired.
          </p>
        </div>
      </Container>
    );
  }
}

export default EmailVerificationFailed;
