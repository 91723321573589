import { Button, Checkbox, Divider, FormHelperText, Paper } from "@material-ui/core";
import React, { Component } from "react";
import { Grid, Fade, FormControlLabel } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";

import CustomButton from "../../Button";
import Accordion from "../../AccountProfile/Accordion";
import SimpleModal from "../../Modal";

const Summary = (props) => {
    const {
      handleSubmit,
      control,
      formState: { errors },
    } = useForm();

  const { modalAlert, riskType, loading, clientInfo } = props;
 const birthDate = moment(clientInfo.dateOfBirth).format("LL");

  let firstName = clientInfo.name.firstName;
  let middleName = clientInfo.name.middleName;
  let lastName = clientInfo.name.lastName;
  let suffix = clientInfo.name.suffix;
  let email = clientInfo.email;
  let civil = clientInfo.civil.civilStatus;

  let gender = clientInfo.gender;
  let mobileNumber = `+${clientInfo.mobileNumber}`;
  let dateOfBirth = birthDate;
  let nationality = clientInfo.nationality;
  let placeOfBirth = clientInfo.placeOfBirth;
  let cityOfBirth = clientInfo.cityOfBirth;
  let selectedID = clientInfo.ids.idType;
  let curCountry = clientInfo.currentAddress.country;
  let curProv = clientInfo.currentAddress.province;
  let curCity = clientInfo.currentAddress.city;
  let curStreet = clientInfo.currentAddress.street;
  let curZip = clientInfo.currentAddress.zip !== "" ? clientInfo.currentAddress.zip : "N/A";

  let sourceFunds = clientInfo.work.sourceOfFunds;
  let primaryOccupation = clientInfo.work.primaryOccupation;


  let riskProfile = riskType.riskProfileType;

 

  var dataApplication = [
    {
      title: "Personal Information",
      table: [
        {
          title: "First Name",
          value: firstName,
        },
        {
          title: "Middle Name",
          value: middleName,
        },
        {
          title: "Last Name",
          value: lastName,
        },
        {
          title: "Suffix",
          value: suffix,
        },
        {
          title: "Email Address",
          value: email,
        },
        {
          title: "Mobile Number",
          value: mobileNumber,
        },
        {
          title: "Gender",
          value: <span className=" capitalize">{gender}</span>,
        },
        {
          title: "Civil Status",
          value: <span className=" capitalize">{civil}</span>,
        },
        {
          title: "Date of Birth",
          value: dateOfBirth,
        },
        {
          title: "Place of Birth",
          value: placeOfBirth,
        },
        {
          title: "City of Birth",
          value: cityOfBirth,
        },
        {
          title: "Nationality",
          value: nationality,
        },
        ...(selectedID !== "None"
          ? [
              {
                title: selectedID,
                value: clientInfo.ids[selectedID] ?? "None",
              },
            ]
          : []),
      ],
    },
    {
      title: "Current Address",
      table: [
        {
          title: "Country",
          value: curCountry,
        },
        {
          title: "Province",
          value: curProv,
        },
        {
          title: "City",
          value: curCity,
        },
        {
          title: "Street",
          value: curStreet,
        },
        {
          title: "Zip Code",
          value: curZip,
        },
      ],
    },
    {
      title: "Source of Income",
      table: [
        {
          title: "Source of Income",
          value: <span className="capitalize">{sourceFunds}</span>,
        },
        {
          title: "Primary Occupation",
          value: <span className="capitalize">{primaryOccupation}</span>,
        },
      ],
    },
    {
      title: "Risk Profiler",
      table: [
        {
          title: "Type of Investor",
          value: <span className=" capitalize">{riskProfile}</span>,
        },
      ],
    },
  ];
  
    const onSubmit = (data) => {
      console.log(data);
      props.saveAndContinue();
    };
  return (
    <>
      <Fade in={true}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleModal
            open={modalAlert.modalOpen}
            success={modalAlert.modalSuccess}
            title={modalAlert.modalTitle}
            content={modalAlert.modalContent}
            subText={modalAlert.modalSubText}
            onClose={props.handleModalClose}
          />
          <Grid item xs={12}>
            <h1>My Application Details</h1>
            <p style={{ fontStyle: "italic" }}>
              Please review your application before submission.
            </p>
            <Divider />
            {dataApplication.map((data, index) => (
              <Accordion
                title={data.title}
                table={data.table}
                index={index}
                key={index}
                defaultExpanded={true}
              />
            ))}
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  name="agreeKYC"
                  control={control}
                  rules={{
                    required: `Agree to the policy of ${process.env.REACT_APP_PROJECT_NAME}.`,
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <FormControlLabel
                      inputRef={ref}
                      name="agreeKYC"
                      checked={field.value}
                      control={
                        <Checkbox
                          checkboxName="agreeKYCBox"
                          color="primary"
                          value={field.value}
                        />
                      }
                      label={
                        <p style={{ fontWeight: "400", fontStyle: "italic" }}>
                          I hereby certify that the details furnished above are
                          true and correct to the best of my knowledge and
                          belief and I undertake to inform you of any changes
                          therein, immediately. In case any of the above
                          information is found to be false or untrue or
                          misleading or misrepresenting, I am aware that I may
                          be held liable for it.
                        </p>
                      }
                      onClick={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              }
            />
            {errors.agreeKYC && (
              <FormHelperText style={{ color: "red" }}>
                {errors.agreeKYC.message}
              </FormHelperText>
            )}
          </Grid>
          <br /> <br />
          <Grid item xs={12}>
            <div className="flex">
              <div className="btn-back" onClick={props.prevStep}>
                Previous Step
              </div>
              {loading.loading ? (
                <CircularProgress />
              ) : (
                <CustomButton
                  text="Continue"
                  type="submit"
                  disabled={false}
                  buttonTheme="primary"
                />
              )}
            </div>
          </Grid>
        </form>
      </Fade>
    </>
  );
};


export default Summary;

