import React, { Component } from "react";
import { Container } from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import { withFirebase } from "../Firebase";

import Logo from "../../contents/img/Rampver_Online_Logo-main.png";

class ForgotPasswordVerificationPage extends Component {
  componentDidMount() {
    this.props.firebase.doSignOut();
    setTimeout(() => {
      this.props.history.push(ROUTES.SIGN_IN);
    }, 10000);
  }

  render() {
    
    return (
      <Container>
        <div id="message" className="r-title-container text-center">
          <img src={Logo} className="email-handler-img" alt="rampver-logo" />
          <br />
          <p className="r-title">
            Please check your email inbox or spam folder!
          </p>
          <p className="r-subtitle">
            Wouldn&apos;t want anyone else to see it!
          </p>
        </div>
      </Container>
    );
  }
}

export default withFirebase(ForgotPasswordVerificationPage);
