import React from "react";
import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import ExpiryPopup from "./popup";
import moment from "moment";
import * as ROUTES from "../../constants/route";
import Helpers from "../Helpers";

const initialInactiveTime = 900;

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
        timeLeft: 1000000,
        expirationDate: -1,
        popupOpen: false,
        refreshTimer: 0,
        isTokenRetrieving: false,
        inactiveTime: 1000000,
      };
      this.helpers = new Helpers();
      this.updateInactivity = this.updateInactivity.bind(this);
    }

    updateInactivity() {
      if (!this.state.popupOpen) {
        let currentTime = moment().add(initialInactiveTime, "s").unix();
        localStorage.setItem("_sessionTime", currentTime);

        let inactiveTime = currentTime - moment().unix();
        if (this.state.inactiveTime < inactiveTime) {
          this.setState({ inactiveTime });
        }
      }
    }

    initTrackers() {
      window.addEventListener("mousemove", this.updateInactivity);
      window.addEventListener("scroll", this.updateInactivity);
      window.addEventListener("keydown", this.updateInactivity);
    }

    cleanUpTrackers() {
      localStorage.removeItem("_sessionTime");
      window.removeEventListener("mousemove", this.updateInactivity);
      window.removeEventListener("scroll", this.updateInactivity);
      window.removeEventListener("keydown", this.updateInactivity);
    }

    resetState() {
      this.setState({
        timeLeft: 1000000,
        expirationDate: -1,
        popupOpen: false,
        refreshTimer: 0,
        isTokenRetrieving: false,
        inactiveTime: 1000000,
      });
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (
            performance.getEntriesByType("navigation")[0].type ===
            "back_forward"
          ) {
            window.location.reload();
            this.clickedSignOut();
          }

          if (authUser) {
            this.setState({ authUser });
            let currentTime = moment().add(initialInactiveTime, "s").unix();

            this.initTrackers();

            localStorage.setItem("_sessionTime", currentTime);
            let dateSignIn = moment(authUser.metadata.lastSignInTime);

            if (this.state.expirationDate === -1) {
              this.setState({ expirationDate: dateSignIn.add(1, "h") });
              console.log("set date");
            }
            this.setState({ inactiveTime: currentTime });

            if (this.state.timeLeft >= 1)
              this.interval = setInterval(this.tick, 1000);
          } else {
            this.setState({ authUser: null });
            this.resetState();
            this.cleanUpTrackers();
            clearInterval(this.interval);
            console.log("out");
          }
        }
      );
    }

    componentDidUpdate() {
      if (this.state.inactiveTime <= 0) {
        console.log("inactivity done");
        clearInterval(this.interval);
        this.resetState();
        window.location.assign(ROUTES.SESSION_ENDED);
      }

      if (this.state.timeLeft <= 1) {
        clearInterval(this.interval);
        console.log("done");

        if (this.state.isTokenRetrieving === false) {
          console.log("retrieving token");
          this.setState({ isTokenRetrieving: true }, () => {
            this.updateToken();
          });
        }
      }
    }

    componentWillUnmount() {
      this.listener();
      this.cleanUpTrackers();
    }

    clickedSignOut() {
      clearInterval(this.interval);
      this.resetState();
      this.props.firebase.doSignOut();
    }

    clickedContinue() {
      let currentTime = moment().add(initialInactiveTime, "s").unix();
      localStorage.setItem("_sessionTime", currentTime);
      this.setState({
        inactiveTime: currentTime - moment().unix(),
        popupOpen: false,
      });
    }

    tick = () => {
      let dateToUse;
      let currentSession = 0;

      if (this.state.timeLeft > 1) {
        dateToUse = 600000;
        let expDate = this.state.expirationDate;
        let timeLeft;

        do {
          currentSession = expDate.valueOf() - dateToUse;
          timeLeft = (currentSession - Date.parse(new Date())) / 1000;
          if (timeLeft < 0) expDate.add(1, "h");
        } while (timeLeft < 0);

        let inactiveTime = parseInt(localStorage.getItem("_sessionTime"));
        inactiveTime = inactiveTime - moment().unix();

        let popupOpen;
        if (inactiveTime < 60) {
          popupOpen = inactiveTime < 60 ? true : false;
        }

        this.setState({ timeLeft, inactiveTime, popupOpen });
      }
    };

    updateToken() {
      this.props.firebase.auth.currentUser
        .getIdToken()
        .then((token) => {
          this.helpers.setCookie("token", token);
          console.log("token refreshed");
          let newExpiration = this.state.expirationDate.add(1, "h");
          this.setState(
            {
              isTokenRetrieving: false,
              expirationDate: newExpiration,
              timeLeft: 1000000,
            },
            () => {
              this.interval = setInterval(this.tick, 1000);
            }
          );
        })
        .catch((err) => {
          alert(err);
        });
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          {this.state.authUser ? (
            <ExpiryPopup
              time={this.state.inactiveTime}
              signOut={() => this.clickedSignOut.bind(this)}
              continue={() => this.clickedContinue.bind(this)}
              popupOpen={this.state.popupOpen}
            />
          ) : (
            ""
          )}
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
