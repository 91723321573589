import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import YoutubeEmbed from "../../components/YoutubeEmbed";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ShowChartIcon from "@material-ui/icons/ShowChart";

import profile from "../../assets/learning_center/profiler.png";
import project from "../../assets/learning_center/projector.png";

import { COLOR_PRIMARY, COLOR_SUCCESS } from "../../constants/colors";

const DashboardLearningCenter = () => {
  const responsive = {
    0: { items: 1 },
    768: { items: 3 },
  };

  return (
    <AliceCarousel
      autoHeight
      responsive={responsive}
      autoPlay
      autoPlayInterval={5000}
      prevButtonClassName
    >
      <Card
        style={{
          height: "410px",
          margin: "0px 5px 25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Aligns items to the end of the container
          }}
        >
          <YoutubeEmbed embedId={"JL27zluj_qQ"} />
          <Typography
            gutterBottom
            variant="subtitle2"
            style={{ paddingTop: "20px" }}
          >
            Starting your investment journey has never been easier! With Rampver
            Online, you can invest in professionally managed funds for as low as
            P1,000 only.
          </Typography>

          <Button
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                "https://www.youtube.com/watch?v=JL27zluj_qQ",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            variant="contained"
            color="secondary"
            fullWidth
            style={{ alignSelf: "flex-end" }}
          >
            Watch this video!
          </Button>
        </CardContent>
      </Card>
      <Card
        style={{
          height: "410px",
          margin: "0px 5px 25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Aligns items to the end of the container
          }}
        >
          <div style={{ textAlign: "center", marginTop: "10px", flexGrow: 1 }}>
            <img src={profile} className="w-40 lg:w-60" />
          </div>

          <Typography gutterBottom variant="subtitle2">
            It is essential that you align your investments to your risk
            profile. Take the Risk Assessment quiz to know if you are a
            conservative, moderate, or aggressive investor.
          </Typography>

          <Button
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                "https://rampver.com/risk-profile",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            variant="contained"
            color="secondary"
            fullWidth
            style={{ alignSelf: "flex-end" }}
          >
            Take the Risk Assessment Quiz
          </Button>
        </CardContent>
      </Card>
      <Card
        style={{
          height: "410px",
          margin: "0px 5px 25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Aligns items to the end of the container
          }}
        >
          <div style={{ textAlign: "center", marginTop: "10px", flexGrow: 1 }}>
            <img src={project} className="w-40 lg:w-60" />
          </div>
          <Typography gutterBottom variant="subtitle2">
            {`People don't realize the great power of compound interest! That's
              why you need to start investing as early as possible. Your P1,000
              can be P1,000,000 with the right behavior and habits!`}
          </Typography>

          <Button
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                "https://rampver.com/projection",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            variant="contained"
            color="secondary"
            fullWidth
            style={{ alignSelf: "flex-end" }}
          >
            Calculate My Future Investment
          </Button>
        </CardContent>
      </Card>
    </AliceCarousel>
  );
};

export default DashboardLearningCenter;
