import {
  TextField,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Divider,
  Switch,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";


function SearchFilter({
  valueSearch,
  valueFilter,
  valueSorting,
  onChange,
  companies,
  riskTypeValidation,
  changeCategory,
  valueCategory
})
 {
  const isMobile = window.innerWidth <= 599;
const renderArrow = () => {
  const switchComponent = (
    <Switch
      checked={valueCategory}
      onChange={changeCategory}
      style={{ color: "#256141" }}
      name="categorySwitch"
      inputProps={{ "aria-label": "primary checkbox" }}
    />
  );

  return isMobile && (valueCategory ? switchComponent : switchComponent);
};

  const content = (
    <>
      <Grid item lg={2} md={6} sm={6} xs={6} >
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel htmlFor="sortOptions">Sort by</InputLabel>
          <Select
            native
            name="sortingBy"
            label="Sort Highest"
            value={valueSorting}
            onChange={onChange}
            inputProps={{
              id: "sortOptions",
            }}
          >
            <option disabled aria-label="None" value="" />
            <option value="">All </option>

            <option value="YTDhighest">YTD (Highest)</option>

            <option value="1yrperformance">1-Year Return (Highest)</option>
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={2} md={6} sm={6} xs={6}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel htmlFor="filterbyOptions">Filter By</InputLabel>
          <Select
            native
            name="filterBy"
            label="Filter By"
            value={valueFilter}
            onChange={onChange}
            inputProps={{
              id: "filterbyOptions",
            }}
          >
            <option disabled aria-label="None" value="" />
            <option value="">All</option>
            <optgroup label="Fund Company">
              {companies.map((company, index) => (
                <option key={index} value={company.value}>
                  {company.label}
                </option>
              ))}
            </optgroup>
            {!riskTypeValidation && (
              <optgroup label="Risk Profile">
                <option value="aggressive">Aggressive</option>

                <option value="moderate">Moderate</option>
                <option value="conservative">Conservative</option>
              </optgroup>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={3} md={12} sm={12} xs={12}>
        <>
          <TextField
            size="small"
            fullWidth
            name="searchQuery"
            variant="outlined"
            label="Search by any parameters"
            value={valueSearch}
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

        </>
      </Grid>
    </>
  );
  return (
    <>
      <Grid container spacing={1} style={{ margin: "10px 0px 15px 0px" }}>
        <Grid
          item
          lg={2}
          md={12}
          sm={12}
          xs={12}
          style={{ marginLeft: "32px" }}
        ></Grid>
        <Grid item lg={2} md={12} sm={12} xs={12}>
          <div
            className=" flex text-center font-sans mt-0 mb-0 lg:mt-2 lg:mb-5 text-base font-bold "  
          >
            <span className="ml-0 lg:ml-0 xl:ml-10 2xl:ml-16 md:ml-0 sm:ml-0">Sort and Filter:</span>
            <span>{renderArrow()}</span>
          </div>
        </Grid>

        {isMobile ? valueCategory && content : content}
      </Grid>

      <Divider />
    </>
  );
}

export default SearchFilter;

