import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

import "./css/PerformanceGraph.css";

function PerformanceGraph(props) {
  let options = {
    title: {
      text: props.title,
      align: "left",
    },
    chart: {
      panning: {
        enabled: false,
      },
    },
    navigator: {
      adaptToUpdatedData: false,
      enabled: false,
    },
    scrollbar: {
      liveRedraw: false,
      enabled: false,
    },
    series: [
      {
        name: "NAVP",
        data: props.data,
        color: "#256141",
      },
    ],
    rangeSelector: {
      buttons: [
        {
          type: "month",
          count: 1,
          text: "1m",
          title: "View 1 month",
          key: 0,
        },
        {
          type: "month",
          count: 3,
          text: "3m",
          title: "View 3 months",
          key: 1,
        },
        {
          type: "month",
          count: 6,
          text: "6m",
          title: "View 6 months",
          key: 2,
        },
        {
          type: "ytd",
          text: "YTD",
          title: "View year to date",
          key: 3,
        },
        {
          type: "year",
          count: 1,
          text: "1y",
          title: "View 1 year",
          key: 4,
        },
        {
          type: "year",
          count: 5,
          text: "5y",
          title: "View 5 year",
          key: 5,
          dataGrouping: {
            forced: false,
            units: [["week", [1]]],
            anchor: "end",
            approximation: function (groupData) {
              return groupData[groupData.length - 1];
            },
            dateTimeLabelFormats: {
              week: ["%b %e, %Y"],
            },
          },
        },
      ],
      inputEnabled: false,
      selected: 4,
    },
    yAxis: {
      floor: 0,
      offset: 40,
      tickPosition: "outside",
    },
    tooltip: {
      dateTimeLabelFormats: {
        day: ["%b %e, %Y"],
      },
    },
  };
  return (
    <Fade in={true}>
      <div style={{ height: "100%", width: "100%" }}>
        {!props.doneLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px",
              flexDirection: "column",
            }}
          >
            <span>Loading Perfomance Graph</span>
            <br />
            <CircularProgress />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            allowChartUpdate={false}
            constructorType={props.type}
            containerProps={{ className: "perf-chart" }}
          />
        )}
      </div>
    </Fade>
  );
}

function PorfolioGraph(props) {
  let options = {
    title: {
      text: props.title,
      align: "left",
    },
    time: {
      timezoneOffset: -8 * 60,
    },
    chart: {
      panning: {
        enabled: false,
      },
    },
    navigator: {
      adaptToUpdatedData: false,
      enabled: false,
    },
    scrollbar: {
      liveRedraw: false,
      enabled: false,
    },
    series: [
      {
        name: "Total Value",
        data: props.data,
        color: "#256141",
      },
    ],
    tooltip: {
      pointFormat: "Total Value: PHP {point.y:,.2f}",
    },
    yAxis: {
      floor: 0,
      offset: 40,
      tickPosition: "outside",
    },
  };
  return (
    <Fade in={true}>
      <div style={{ height: "100%", width: "100%" }}>
        {!props.doneLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px",
              flexDirection: "column",
            }}
          >
            <span>Loading Perfomance Graph</span>
            <br />
            <CircularProgress />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            allowChartUpdate={false}
            constructorType={props.type}
            containerProps={{ className: "perf-chart" }}
          />
        )}
      </div>
    </Fade>
  );
}

export default PerformanceGraph;

export { PorfolioGraph };
