import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Helpers from "../Helpers";
import { Paper, Grid, Button } from "@material-ui/core";
import design from "./css/Banner.module.css"

class FundCard extends Component {
  render() {
    const { funds, classes, smBreakpoint, oneYrProgress, investDisabled } = this.props;
    this.helpers = new Helpers();
    let fundCards = null;

    if (funds.length === 0) {
      fundCards = (
        <div className="dark-label text-center">
          <label>No recommended funds based on your risk profile.</label>
        </div>
      );
      return fundCards;
    } else {
      fundCards = funds.map((fund, index) => {
        const fundBannerText =
          fund.banner !== undefined && fund.banner.text.length;
        const fundBannerStatus =
          fund.banner !== undefined && fund.banner.enabled;
        let spacingCard = "20px";
        if (
          (fund.name.length <= 31 && fundBannerText === 9) ||
          (!fundBannerStatus && fund.name.length <= 45)
        ) {
          spacingCard = "30px";
        }

        return (
          <Paper elevation={4} className="suggested-box" key={index}>
            <div className="relative">
              <Grid
                container
                className={classes.recommendedInvestCardTitle}
                style={{
                  fontFamily: "sans-serif",
                  textTransform: "uppercase",
                }}
              >
                <Grid item xs>
                  <div>{fund.name}</div>
                </Grid>
                {fund.banner === undefined
                  ? ""
                  : fund.banner.enabled && (
                      <Grid
                        item
                        style={
                          fund.name.length <= 33
                            ? { marginLeft: "100px" }
                            : { marginLeft: "90px" }
                        }
                      >
                        <span
                          className={design.ribbon}
                          style={{
                            background: fund.banner.color,
                            color: fund.banner.textColor,
                            fontWeight: "900",
                            top: top,
                          }}
                        >
                          {fund.banner.text}
                        </span>
                      </Grid>
                    )}
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: spacingCard,
                }}
              >
                <div className={`indicator ${fund.riskType}`}>
                  {`For ${fund.riskType}-type of investor`}
                </div>
              </div>
              {smBreakpoint ? (
                <div>
                  <div className="d-flex">
                    <span>{`NAVPS as of ${fund.date}`}</span>
                    <span className={`text-gold`}>
                      {this.helpers.formatToPHP(fund.pricePerShare, 4)}
                    </span>
                  </div>
                  <div className="d-flex">
                    <span>Year-to-Date Return</span>
                    <span
                      className={`text-${
                        fund.ytd * 100 > 0
                          ? "ytd-above"
                          : fund.ytd * 100 < 0
                          ? "ytd-below"
                          : "gold"
                      }`}
                    >
                      {parseFloat(fund.ytd * 100).toFixed(2)}%
                    </span>
                  </div>
                  {oneYrProgress && (
                    <div className="d-flex">
                      <span>1-Year Return</span>
                      <span className={`text-gold`}>
                        {fund.oneyrprogress === "n/a" ||
                        Math.abs(fund.oneyrprogress) < 1e-10 ||
                        fund.oneyrprogress === ""
                          ? "N/A"
                          : `${parseFloat(fund.oneyrprogress * 100).toFixed(
                              2
                            )}%`}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <td>{`NAVPS as of ${this.helpers.dateFormat(
                        fund.date
                      )}`}</td>
                      <td className={`text-gold`}>
                        {this.helpers.formatToPHP(fund.pricePerShare, 4)}
                      </td>
                    </tr>
                    <tr>
                      <td>Year-to-Date Return</td>
                      <td
                        className={`text-${
                          fund.ytd * 100 > 0
                            ? "ytd-above"
                            : fund.ytd * 100 < 0
                            ? "ytd-below"
                            : "gold"
                        }`}
                      >
                        {parseFloat(fund.ytd * 100).toFixed(2)}%
                      </td>
                    </tr>
                    {oneYrProgress && (
                      <tr>
                        <td>1st Yr. Return</td>
                        <td className={`text-gold`}>
                          {fund.oneyrprogress === "n/a" ||
                          Math.abs(fund.oneyrprogress) < 1e-10 ||
                          fund.oneyrprogress === ""
                            ? "N/A"
                            : parseFloat(fund.oneyrprogress * 100).toFixed(2)}
                          %
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}

              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className={classes.recommendedInvestCardButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.props.redirectFund(fund.id, fund.riskType, false)
                      }
                      fullWidth
                    >
                      <span className={classes.recommendedInvestCardText}>
                        Learn More
                      </span>
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className={classes.recommendedInvestCardButton}>
                    <Button
                      disabled={investDisabled}
                      variant="contained"
                      color="primary"
                      style={{ color: "white" }}
                      onClick={() =>
                        this.props.redirectFund(fund.name, fund.riskType, true)
                      }
                      fullWidth
                    >
                      <span className={classes.recommendedInvestCardText}>
                        Invest Now
                      </span>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>
        );
      });
    }

    return <div className="flexbox">{fundCards}</div>;
  }
}

export default withRouter(FundCard);
