import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Firebase, { FirebaseContext } from "./components/Firebase";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { NotificationProvider } from "@/context/NotificationContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#C8932A",
    },
    secondary: {
      main: "#256141",
    },
    tertiary: {
      main: "#9E9E9E"
    }
  },
  overrides: {
    MuiStepIcon: {
      text: {
        fill: "#FFFFFF",
      },
    },
  },
  typography: {
    fontFamily: "Gill Sans, Poppins, sans-serif",
  },
});

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </ThemeProvider>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
