import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionContent from "./AccordionContent";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  heading: {
    fontSize: "16px !important",
    fontWeight: "bold",
  },
}));

export default function SimpleAccordion(props) {
  const { table, index, title, defaultExpanded } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}a-content`}
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionContent table={table} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
