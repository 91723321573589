import React, { useState, Component } from "react";
import ReusableModal from "../AccountProfile/ReusableModal";
import CustomButton from "../Button";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import Navbar from "../Navbar";
import { Container } from "@material-ui/core";

const PrivacyPolicyPage = () => {
  return <PrivacyPolicy />;
};

class PrivacyPolicyBase extends Component {
  constructor(props) {
    super(props);
    this.state = { text: "" };
  }
  componentDidMount() { 
    const terms = this.props.firebase.db
      .collection("miscellaneous")
      .doc("privacyPolicy")
      .get();
    terms
      .then((result) => {
        var text = result
          .data()
          .content.replaceAll("Open Sans", "Gill Sans, Poppins")
          .replaceAll("DauphinPlain", "Gill Sans, Poppins");
        this.setState({ text: text });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <>
        <Navbar />
        <Container container maxWidth="md">
          <h1>Privacy Policy</h1>
          <div style={{ paddingBottom: 30 }}>
            <div dangerouslySetInnerHTML={{ __html: this.state.text }}></div>
          </div>
        </Container>
      </>
    );
  }
}

function PrivacyPolicyLink(props) {
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("");
  const close = () => {
    setModal(false);
  };
  const [button] = useState(
    <CustomButton
      type="button"
      text="Close"
      buttonTheme="primary"
      onClick={close}
    />
  );
  const [body, setBody] = useState("");
  const TermsAndCondition = () => {
    const terms = props.firebase.db
      .collection("miscellaneous")
      .doc("termsAndConditions")
      .get();
    terms
      .then((result) => {
        setModal(true);
        setBody(result.data().content);
        setTitle(result.data().title);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const PrivacyPolicy = () => {
    const privacy = props.firebase.db
      .collection("miscellaneous")
      .doc("privacyPolicy")
      .get();
    privacy
      .then((result) => {
        setModal(true);
        setBody(result.data().content);
        setTitle(result.data().title);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <ReusableModal
        show={modal}
        title={title}
        content={body}
        button={button}
      />
      <p
        style={{
          fontSize: "16px",
          color: "white",
          lineHeight: "30px",
          fontFamily: "Gill Sans"
        }}
      >
        I agree on the{" "}
        <span className="data-privacy-link" onClick={TermsAndCondition}>
          Terms and Conditions
        </span>{" "}
        and{" "}
        <span className="data-privacy-link" onClick={PrivacyPolicy}>
          Privacy Policy
        </span>{" "}
        of {process.env.REACT_APP_PROJECT_NAME}.
      </p>
    </div>
  );
}

const PrivacyPolicy = compose(withRouter, withFirebase)(PrivacyPolicyBase);

export default PrivacyPolicyPage;

export { PrivacyPolicyLink };
