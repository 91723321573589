import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import * as ROUTES from "../../constants/route";

class BasicKYCHandler extends Component {
  constructor(props) {
    super(props);
    setTimeout(() => {
      this.props.history.push(ROUTES.DASHBOARD);
    }, 5000);
  }

  render() {
    return (
      <Container>
        <div id="message" className="r-title-container text-center">
          <p className="r-title">
            Sorry you already filled up your Client Application
          </p>
          <p className="r-subtitle">
            Please wait for the admin to verify your information.
          </p>
        </div>
      </Container>
    );
  }
}

export default BasicKYCHandler;
