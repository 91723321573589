import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Select,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Grid,
  IconButton,
  Tooltip,
  LinearProgress,
  Snackbar,
  FormHelperText,
  RadioGroup,
  Radio,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import * as ROUTES from "../../../constants/route";
import { SubmitModalDialogs, formatNumber, header } from "./RecurringHelper";
import Button from "../../ui/Button";
import lastAmount from "../../../assets/recurringPage/last_investment_icon.png";
import Instruction from "./Instruction";
import TermsCondition from "./TermsCondition";
import instructionGif from "../../../assets/recurringPage/eye_icon.png";
import {
  getDeclinedRecurring,
  getLatestApprovedTransaction,
} from "./recurringAmount";
import "./regexNumber.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    position: "relative",
    width: "10px",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },

    [theme.breakpoints.up("md")]: {
      width: "140px",
    },

    [theme.breakpoints.up("lg")]: {
      width: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "250px",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#D32F2F",
    color: "white",
    fontSize: theme.typography.pxToRem(14),
    border: "0px solid #dadde9",
    fontFamily: "Poppins",
    padding: "10px",
  },
}))(Tooltip);

function getSteps() {
  return [
    "Enter investment Preferences",
    "Register BPI Account to Auto-debit",
    "Review and Agree to T&Cs",
  ];
}

const InvestmentForm = ({
  fromPayment,
  firebase,
  initialFund,
  amount,
  minInvested,
  token,
  backDropDown,
}) => {
  const design = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  // validation, etc
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalErr, setModalErr] = useState("");

  // step 2 copy the account number
  const [acctNum, setAcctNum] = useState("");

  // this is the check box button in step 2 and step 3
  const [bpiRegistered, setBpiRegistered] = useState(false);
  const [monthlyAgree, setMonthlyAgree] = useState(false);
  const [monthlyAgreePopUp, setMonthlyAgreePopUp] = useState(false);

  // this is for step 1 input field
  const [investmentAmount, setInvestmentAmount] = useState(amount);
  const [investmentFundAmount, setInvestmentFundAmount] = useState(0);
  const [investmentFund, setInvestmentFund] = useState(initialFund);
  const [investmentDate, setInvestmentDate] = useState(0);

  // for select option funds in step 1
  const [fundList, setFundList] = useState([]);

  const investedAmount = firebase.db.collection("transactions");
  const userID = firebase.db._delegate._authCredentials.currentUser.uid;

  const getLastAmountInvested = async () => {
    try {
      const latestTransaction = await getLatestApprovedTransaction(
        investedAmount,
        investmentFund,
        userID
      );
      if (latestTransaction) {
        const amount = latestTransaction.amount;
        setInvestmentAmount(amount);
        setInvestmentFundAmount(amount);
      } else {
        console.log("No transactions found for the fund");
      }
    } catch (error) {
      console.error("Error fetching transaction data:", error);
    }
  };

  async function fetchData() {
    try {
      const user = await firebase.db
        .collection("users")
        .doc(firebase.auth.currentUser.uid)
        .get();
      setAcctNum(user.data().accountNumber.slice(2));
      const { docs } = await firebase.db
        .collection("investments")
        .where("useruid", "==", user.data().accountNumber)
        .get();
      let fundArr = [];
      docs.forEach((fund) => {
        fundArr.push({
          id: fund.data().fundId,
          fundName: fund.data().fundName,
        });
      });

      setFundList(fundArr);
    } catch (err) {
      console.log(err);
    }
  }
  const amountValue = fromPayment === true ? "" : investmentFund;
  useEffect(() => {
    const fetchDataAndInvestedAmount = async () => {
      if (fromPayment === undefined) {
        await getLastAmountInvested();
      }

      await fetchData();
    };

    fetchDataAndInvestedAmount();
  }, [amountValue]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { id } = fundList.find((item) => item.fundName === investmentFund);
      await axios.post(
        ROUTES.FUNCTION_BASE_URL +
          "investments-recurring-recurringInvestments/request-recurring",
        {
          userUid: firebase.auth.currentUser.uid,
          amount:
            typeof investmentAmount === "number"
              ? investmentAmount
              : parseFloat(investmentAmount.replace(/,/g, "")),
          fundId: id,
          fundName: investmentFund,
          day: investmentDate === 0 ? 1 : 16,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setSuccess(true);
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.status === 400) {
        setModalErr(err.response.data.message);
        setOpenModal(true);
      }
    }
  };
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleLastAmount = (e) => {
    e.preventDefault();
    if (fromPayment === undefined) {
      setInvestmentAmount(investmentFundAmount);
    } else {
      setInvestmentAmount(amount);
    }
  };
  const validateOne =
    investmentAmount < parseInt(minInvested) ||
    investmentAmount === "" ||
    isNaN(parseFloat(investmentAmount));

  const validateTwo = !bpiRegistered;

  const validateThree = !monthlyAgree;
  const handleSubmitTerm = () => {
    setMonthlyAgreePopUp(false);
    setMonthlyAgree(true);
  };

  const isError = investmentAmount < parseFloat(minInvested);
  const amountEnable =
    fromPayment === undefined
      ? parseInt(investmentFundAmount)
      : parseInt(amount);

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    setInvestmentAmount(formattedValue);
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <p className=" relative font-semibold text-base bottom-5 lg:bottom-7">
              STEP 1: Enter your monthly recurring investment preferences
            </p>
            <p />

            <Grid container justify="flex-start" spacing={4}>
              <Grid item lg={3} md={4} sm={6} xs={4}>
                <span className=" relative font-normal font-poppins text-base py-0 top-0 pl-0 lg:py-5 lg:top-2 lg:pl-10">
                  Investment Amount:
                </span>
              </Grid>
              <Grid item lg={9} md={8} sm={6} xs={8}>
                <FormControl size="small" variant="outlined" error={isError}>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Amount
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    value={investmentAmount.toLocaleString()}
                    className="text-white truncate w-44 lg:w-full xl:w-full 2xl:w-full"
                    onChange={handleInputChange}
                    startAdornment={
                      <InputAdornment position="start">₱</InputAdornment>
                    }
                    labelWidth={60}
                    type="text"
                    inputProps={{
                      min: minInvested,
                    }}
                  />
                  <FormHelperText error={isError}>
                    {isError &&
                      "Amount must be greater than or equal to ₱" +
                        formatNumber(minInvested)}
                  </FormHelperText>
                </FormControl>

                {parseInt(investmentAmount) !== amountEnable ? (
                  <Tooltip
                    title={
                      <div className=" relative font-normal font-poppins text-base">
                        Based on your previous investment
                      </div>
                    }
                    placement="top"
                    arrow
                  >
                    <img
                      src={lastAmount}
                      alt="Last Investment Icon"
                      className={`relative lg:ml-3 
                       bg-primary cursor-pointer
                          
                     w-10 h-10 rounded`}
                      onClick={handleLastAmount}
                    />
                  </Tooltip>
                ) : (
                  <img
                    src={lastAmount}
                    alt="Last Investment Icon"
                    className={`relative lg:ml-3 bg-gray-400
                     w-10 h-10 rounded`}
                  />
                )}
              </Grid>

              <Grid item lg={3} md={4} sm={6} xs={4}>
                <span className=" relative font-normal font-poppins text-base lg:top-2 lg:py-5 pl-0 lg:pl-10 ">
                  Fund Name:
                </span>
              </Grid>
              <Grid item lg={9} md={8} sm={6} xs={8}>
                <FormControl size="small" variant="outlined">
                  <InputLabel htmlFor="investment-fund-select">Fund</InputLabel>
                  <Select
                    disabled
                    id="investment-fund-select"
                    style={{
                      backgroundColor: "#F0F0F0",
                      color: "black",
                    }}
                    className={`${investmentFund === "" && "w-44 lg:w-52"}`}
                    value={investmentFund}
                    onChange={(e) => setInvestmentFund(e.target.value)}
                    label="Fund"
                    IconComponent={() => null}
                  >
                    {fundList.map((item) => (
                      <MenuItem key={item.id} value={item.fundName}>
                        <div className="truncate w-44 lg:w-full xl:w-full 2xl:w-full">
                          {item.fundName}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={3} md={4} sm={6} xs={4}>
                <span className=" relative font-normal font-poppins text-base lg:top-2 lg:py-5 pl-0 lg:pl-10 ">
                  Schedule:
                </span>
              </Grid>

              <Grid item lg={9} md={8} sm={6} xs={8}>
                <FormControl size="small" component="fieldset">
                  <RadioGroup
                    id="investment-day-radio"
                    value={investmentDate.toString()}
                    onChange={(e) => setInvestmentDate(Number(e.target.value))}
                    style={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Every 1st"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Every 16th"
                    />
                  </RadioGroup>
                </FormControl>

                <span className=" relative font-normal font-poppins text-base top-2 py-5 px-5">
                  of the month.
                </span>
              </Grid>
            </Grid>

            <h4 className=" relative top-8">
              Note: If the date falls on a weekend or holiday, we will book your
              investment on the next business day.
            </h4>
          </>
        );
      case 1:
        return (
          <div className="overflow-y-auto pt-9 max-h-screen lg:overflow-y-hidden lg:max-h-full">
            <p className="relative font-semibold text-base bottom-5">
              STEP 2: Register your BPI Online Account with Rampver’s Auto-debit
              Arrangement
            </p>
            <p className="relative mx-0 lg:mx-16 italic text-sm font-medium bottom-5">
              Note: “Click the
              <img
                src={instructionGif}
                alt="Instruction Icon"
                className="relative mx-2 top-2 bg-primary w-7 h-7 rounded-full"
              />
              to view a visual guide of the BPI eADA instructions.”
            </p>
            <Instruction acctNum={acctNum} />

            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  name="BPI"
                  checked={bpiRegistered}
                  onClick={(e) => setBpiRegistered(e.target.checked)}
                />
              }
              label={
                <span className="font-poppins font-bold text-base">
                  I have successfully registered my BPI Account with Rampver’s
                  Auto-debit Arrangement
                </span>
              }
              labelPlacement="end"
            />
          </div>
        );
      case 2:
        return (
          <>
            <p className=" relative font-semibold text-base bottom-7">
              STEP 3: Review Recurring Investment Agree to the Monthly Recurring
              Investment Terms
            </p>
            <p />

            <Grid container justify="flex-start" spacing={4}>
              <Grid item lg={3} md={4} sm={6} xs={4}>
                <span className=" relative font-normal font-poppins text-base py-0 top-0 pl-0 lg:py-5 lg:top-2 lg:pl-10">
                  Investment Amount:
                </span>
              </Grid>
              <Grid item lg={9} md={8} sm={6} xs={8}>
                <FormControl size="small" variant="outlined">
                  <OutlinedInput
                    disabled
                    value={investmentAmount.toLocaleString()}
                    className="truncate w-48 lg:w-full xl:w-full 2xl:w-full"
                    style={{
                      backgroundColor: "#F0F0F0",
                      color: "black",
                      fontWeight: "600",
                    }}
                    onChange={(e) =>
                      setInvestmentAmount(e.target.value.replace(/,/g, ""))
                    }
                    startAdornment={
                      <InputAdornment position="start">Php</InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item lg={3} md={4} sm={6} xs={4}>
                <span className=" relative font-normal font-poppins text-base lg:top-2 lg:py-5 pl-0 lg:pl-10 ">
                  Fund Name:
                </span>
              </Grid>
              <Grid item lg={9} md={8} sm={6} xs={8}>
                <FormControl size="small" variant="outlined">
                  <Select
                    className="truncate w-48 lg:w-full xl:w-full 2xl:w-full"
                    style={{
                      backgroundColor: "#F0F0F0",
                      color: "black",
                      fontWeight: "600",
                    }}
                    disabled
                    value={investmentFund}
                    onChange={(e) => setInvestmentFund(e.target.value)}
                    IconComponent={() => null}
                  >
                    {fundList.map((item) => (
                      <MenuItem key={item.id} value={item.fundName}>
                        {item.fundName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={3} md={4} sm={6} xs={4}>
                <span className=" relative font-normal font-poppins text-base lg:top-2 lg:py-5 pl-0 lg:pl-10 ">
                  Schedule:
                </span>
              </Grid>
              <Grid item lg={9} md={8} sm={6} xs={8}>
                <FormControl size="small" variant="outlined">
                  <Select
                    disabled
                    style={{
                      minWidth: "90px",
                      backgroundColor: "#F0F0F0",
                      color: "black",
                      fontWeight: "600",
                    }}
                    value={investmentDate}
                    onChange={(e) => setInvestmentDate(e.target.value)}
                    IconComponent={() => null}
                  >
                    <MenuItem value={0}>Every 1st of the month</MenuItem>
                    <MenuItem value={1}>Every 16th of the month</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <br />
            <p className=" relative font-semibold text-base top-2">
              Agree to Terms & Conditions:
            </p>
            <div className=" relative left-7">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    color="primary"
                    name="monthlyAgree"
                    checked={monthlyAgree}
                    onClick={(e) => setMonthlyAgreePopUp(true)}
                  />
                }
                label={
                  <span className=" font-poppins font-normal text-base">
                    I agree to the Monthly Recurring Investment Terms as
                    provided by Rampver Financials
                  </span>
                }
                labelPlacement="end"
              />
            </div>
            <p
              style={{ color: "#c8932a" }}
              className="relative font-poppins font-semibold text-base top-5 ml-4 italic"
            >
              Please make sure your Monthly Recurring Investment details are
              correct before clicking Submit
            </p>
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <>
      {success ? (
        <SubmitModalDialogs />
      ) : (
        <>
          {header}
          <div
            style={{
              margin: "20px -45px -45px -45px",
              paddingBottom: "20px",
            }}
          >
            <Snackbar
              open={openModal}
              onClose={() => setOpenModal(false)}
              autoHideDuration={4000}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Alert severity="error">{modalErr}</Alert>
            </Snackbar>
            {monthlyAgreePopUp && (
              <TermsCondition closeTerm={handleSubmitTerm} />
            )}

            <Box style={{ padding: "30px 45px" }}>
              <span className=" font-poppins font-semibold text-xl ml-0 lg:ml-10 ">
                Let’s get started! Follow the instructions below to register:
              </span>

              <form onSubmit={handleSubmit}>
                <div className={design.root}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    <>
                      <Typography className={design.instructions}>
                        <div className=" relative font-poppins mt-1 lg:mt-12">
                          {getStepContent(activeStep)}
                        </div>
                      </Typography>
                      <Grid
                        container
                        justify="flex-end"
                        spacing={2}
                        style={{ marginTop: "40px" }}
                      >
                        <Grid
                          item
                          lg={9}
                          md={3}
                          sm={6}
                          xs={6}
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            style={{ fontSize: ".90rem", fontWeight: "800" }}
                            variant="outlined"
                            onClick={
                              activeStep === 0 ? backDropDown : handleBack
                            }
                            className={design.backButton}
                          >
                            Go Back
                          </Button>
                        </Grid>

                        <Grid item lg={2} md={2} sm={6} xs={6}>
                          {activeStep === 1 ? (
                            <CustomTooltip
                              disableFocusListener
                              title={`Please confirm if you have completed the steps above.`}
                              placement="top"
                              arrow
                            >
                              <div>
                                <Button
                                  type={
                                    activeStep === steps.length - 1
                                      ? "submit"
                                      : "button"
                                  }
                                  disabled={
                                    activeStep === 0
                                      ? validateOne
                                      : activeStep === 1
                                      ? validateTwo
                                      : activeStep === 2 && validateThree
                                  }
                                  className={design.backButton}
                                  onClick={
                                    activeStep !== steps.length - 1 &&
                                    handleNext
                                  }
                                >
                                  {activeStep === steps.length - 1
                                    ? "Submit"
                                    : "Next"}
                                </Button>
                              </div>
                            </CustomTooltip>
                          ) : (
                            <Button
                              type={
                                activeStep === steps.length - 1
                                  ? "submit"
                                  : "button"
                              }
                              disabled={
                                activeStep === 0
                                  ? validateOne
                                  : activeStep === 1
                                  ? validateTwo
                                  : activeStep === 2 && validateThree
                              }
                              className={design.backButton}
                              onClick={
                                activeStep !== steps.length - 1 && handleNext
                              }
                            >
                              {activeStep === steps.length - 1
                                ? "Submit"
                                : "Next"}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  </div>
                </div>
              </form>
            </Box>
            {isLoading && <LinearProgress />}
          </div>
        </>
      )}
    </>
  );
};

export default InvestmentForm;
