import React, { Component } from "react";
import { Container } from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import { withFirebase } from "../Firebase";

class ConfirmNewPasswordVerificationPage extends Component {
  componentDidMount() {
        this.props.firebase.doSignOut();
      const emailQuery = this.props.location.state?.email ?? false;
    setTimeout(() => {

      this.props.history.push({
        pathname: ROUTES.SIGN_IN,
        state: { email: emailQuery },
      });
    }, 5000);
  }

  render() {
    return (
      <Container>
        <div id="message" className="r-title-container text-center">
          <p className="r-title">Redirecting you back to the login page</p>
        </div>
      </Container>
    );
  }
}

export default withFirebase(ConfirmNewPasswordVerificationPage);
