import React from "react";

const BankWidget = () => {
  return (
    <div className="bank-account-container">
      <h2>Bank Details</h2>
      <table className="tbl-border-bottom">
        <tbody>
          <tr>
            <td>Bank Name:</td>
            <td>Metrobank</td>
          </tr>
          <tr>
            <td>Account Name:</td>
            <td>{process.env.REACT_APP_PROJECT_NAME} Financials</td>
          </tr>
          <tr>
            <td>Account Number:</td>
            <td>3782 8224 6310 005</td>
          </tr>
          <tr>
            <td>Type of Account:</td>
            <td>Current</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BankWidget;
