import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import styles from "./Button.module.css";
import { ReactNode } from "react";
import { COLOR_PRIMARY } from "@/constants/colors";
import CircularProgress from "@material-ui/core/CircularProgress";

type Props = {
  children: ReactNode;
  loading?: boolean;
  // to and component are props that are used by react-router-dom, there might be better ways to do this but this works for now
  to?: string;
  target?: string;
  component?: ReactNode;
} & ButtonProps;

export default function Button({ loading, children, ...props }: Props) {
  return (
    <MuiButton
      variant="contained"
      className={`${styles.button} ${props.className}`}
      color="primary"
      {...props}
      style={{
        color: props.variant === "outlined" ? COLOR_PRIMARY : "#fff",
        textTransform: "none",
        ...props.style,
      }}
    >
      {loading && (
        <CircularProgress
          size={20}
          color="inherit"
          style={{ marginRight: "1em" }}
        />
      )}
      {children}
    </MuiButton>
  );
}
