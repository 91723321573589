import React from "react";
import * as ROUTES from "../../constants/route";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";

function VerifiedLabel(props) {
  const { kyc } = props;
  var validated;
  var verified;
  var link;
  const renderLink = (link) => {
    return (
      <div className="not-verified rampver-bgColor">
        <InfoOutlinedIcon />
        <span>
          Your account isn&apos;t complete yet.
          <b>{link}</b>
        </span>
      </div>
    );
  };

  const declinedLink = (link) => {
    return (
      <div className="not-verified rampver-errColor">
        <ErrorOutlinedIcon />
        <span>
          We have unfortunately have declined your application. .<b>{link}</b>
        </span>
      </div>
    );
  };

  if (kyc !== null) {
    if ("validated" in kyc) validated = kyc.validated;
  } else {
    validated = "notValidated";
  }
  if (validated === "verified") {
    verified = "";
  } else if (validated === "pending") {
    link = " We are currently processing your application.";
    verified = renderLink(link);
  } else if (validated === "declined") {
    link = (
      <Link className="verify" to={ROUTES.DECLINED_PAGE}>
        {" "}
        Please review our comments here.
      </Link>
    );
    verified = declinedLink(link);
  } else {
    link = (
      <Link className="verify" to={ROUTES.LONG_KYC}>
        {" "}
        Complete my application.
      </Link>
    );
    verified = renderLink(link);
  }
  return (
    <>
      {verified} <br />
    </>
  );
}

export default VerifiedLabel;
