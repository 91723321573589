import React, { Component } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Helpers from "../Helpers";
import { SearchOutlined } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: props.state.start_date,
      end_date: props.state.end_date,
      fund: props.state.fund,
      loading: props.state.loading,
      searchByFundName: props.state.searchByFundName,
    };
    this.helpers = new Helpers();
  }

  handleChange = (value, name) => {
    this.props.handleChange(value, name);
    this.setState({ [name]: value });
  };

  render() {
    document.body.style.backgroundColor = "white";
    const renderListStatus = this.props.funds.map((fund) => (
      <MenuItem key={fund.value} value={fund.value}>
        {fund.name}
      </MenuItem>
    ));

    return (
      <>
        <form onSubmit={this.props.submitSearchFilter}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <FormControl
                style={{ minWidth: "100%", maxWidth: "100%" }}
                variant="outlined"
              >
                <InputLabel>Fund</InputLabel>
                <Select
                  value={this.props.fund}
                  onChange={(e) => this.handleChange(e.target.value, "fund")}
                  label="Fund"
                  fullWidth
                  style={{ height: "50px" }}
                >
                  {renderListStatus}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  style={{ width: "100%" }}
                  autoOk
                  label="Start Date"
                  value={this.props.state.start_date}
                  format="MM/dd/yyyy"
                  onChange={(value) => this.handleChange(value, "start_date")}
                  className="custom-date-range"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  style={{ width: "100%" }}
                  autoOk
                  label="End Date"
                  clearable
                  minDate={this.props.state.start_date}
                  value={this.props.state.end_date}
                  format="MM/dd/yyyy"
                  onChange={(value) => this.handleChange(value, "end_date")}
                  className="custom-date-range"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <FormControl
                variant="outlined"
                style={{ height: "50px", minWidth: "100%", maxWidth: "100%" }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  onChange={(e) =>
                    this.handleChange(e.target.value, "searchByFundName")
                  }
                  style={{ height: "50px" }}
                  onKeyDown={this.props.handleKeyDown}
                  endAdornment={
                    this.props.state.loading_search ? (
                      <CircularProgress color="primary" />
                    ) : (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.props.search}
                          edge="end"
                        >
                          <SearchOutlined />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                  labelWidth={50}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

export default Filters;
