import axios from "axios";
import * as ROUTES from "../../constants/route";

export default class SignUpFunction {
  doAddUserData = (user) =>
    axios.post(
      `${ROUTES.FUNCTION_BASE_URL}clients-client/createNewUser`,
      JSON.stringify(user),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

  doCheckUser = (email) =>
    axios.post(
      `${ROUTES.FUNCTION_BASE_URL}clients-client/checkNewUser`,
      email,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
}
