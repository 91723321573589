import React from "react";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { Paper } from "@material-ui/core";

function Documents(props) {
  const { docs } = props;
  var renderDocs = "";
  if (docs !== null) {
    renderDocs = docs.map((item, index) => (
      <Paper
        elevation={2}
        style={{ padding: "5px", width: "100%" }}
        onClick={() => props.downloadFile(item)}
        key={index}
      >
        <tr>
          <td>
            <DescriptionOutlinedIcon />
          </td>
          <td>{item.split("/")[2]}</td>
        </tr>
      </Paper>
    ));
  }
  return (
    <>
      <table className="documents">
        <tbody>{renderDocs}</tbody>
      </table>
    </>
  );
}

export default Documents;
