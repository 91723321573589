import axios from "axios";
import * as ROUTES from "../../../constants/route";
import Helpers from "../../Helpers";
import { assignStatus } from "../../../services/metrics";


export default class Functions {
  constructor() {
    this.helpers = new Helpers();
  }

  createFormDataPromise = (name, data, token, uid) => {
    const formData = new FormData();
    formData.append(name, data);
    return axios.post(
      `${ROUTES.FUNCTION_BASE_URL}clients-kyc-kyc/AddKYCImage/${uid}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  doAddLongKYC = (
    uid,
    uploadImg,
    additionalInfo,
    pepDeclaration,
    signature,
    fatca
  ) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      let sigSplit = signature.signatureUrl.split(",");
        const toBoolean = (item) => (item === "1" );
      const filteredData = additionalInfo.existingID
        ? {
            work: {
              annualIncomeAmount: additionalInfo.work.annualIncomeAmount,
              businessNature: additionalInfo.work.businessNature,
              nameOfEmployer: additionalInfo.work.nameOfEmployer,
              primaryOccupation: additionalInfo.work.primaryOccupation,
            },
            businessAddress: {
              barangay: "",
              street: additionalInfo.businessAddress.street,
              city: additionalInfo.businessAddress.city,
              province: additionalInfo.businessAddress.province,
              zip: additionalInfo.businessAddress.zip,
              country: additionalInfo.businessAddress.country,
            },

            ids: {
              idNumber: "",
              idType: additionalInfo.ids.idType,
              SSS:
                additionalInfo.ids.idType === "SSS" &&
                additionalInfo.ids.SSS !== undefined
                  ? additionalInfo.ids.SSS
                  : "",
              TIN:
                additionalInfo.ids.idType === "TIN" &&
                additionalInfo.ids.TIN !== undefined
                  ? additionalInfo.ids.TIN
                  : "",
            },

            PEP: {
              banks: pepDeclaration.banks,
              governmentRelative: toBoolean(pepDeclaration.governmentRelative),
              governmentWork: toBoolean(pepDeclaration.governmentWork),
            },
            FATCA: {
              usPerson: toBoolean(fatca.usPerson),
              usCitizen: toBoolean(fatca.usCitizen),
              usResident: toBoolean(fatca.usResident),
              usAlien: toBoolean(fatca.usAlien),
              usPhone: toBoolean(fatca.usPhone),
              usBorn: toBoolean(fatca.usBorn),
              usAddressResidence: toBoolean(fatca.usAddressResidence),
              usAddressMailing: toBoolean(fatca.usAddressMailing),
              usAccount: toBoolean(fatca.usAccount),
              usPOA: toBoolean(fatca.usPOA),
              usCareOfAddress: toBoolean(fatca.usCareOfAddress),
            },
          }
        : {
            work: {
              annualIncomeAmount: additionalInfo.work.annualIncomeAmount,
              businessNature: additionalInfo.work.businessNature,
              nameOfEmployer: additionalInfo.work.nameOfEmployer,
              primaryOccupation: additionalInfo.work.primaryOccupation,
            },
            businessAddress: {
              barangay: "",
              street: additionalInfo.businessAddress.street,
              city: additionalInfo.businessAddress.city,
              province: additionalInfo.businessAddress.province,
              zip: additionalInfo.businessAddress.zip,
              country: additionalInfo.businessAddress.country,
            },

            PEP: {
              banks: pepDeclaration.banks,
              governmentRelative: toBoolean(pepDeclaration.governmentRelative),
              governmentWork: toBoolean(pepDeclaration.governmentWork),
            },
            FATCA: {
              usPerson: toBoolean(fatca.usPerson),
              usCitizen: toBoolean(fatca.usCitizen),
              usResident: toBoolean(fatca.usResident),
              usAlien: toBoolean(fatca.usAlien),
              usPhone: toBoolean(fatca.usPhone),
              usBorn: toBoolean(fatca.usBorn),
              usAddressResidence: toBoolean(fatca.usAddressResidence),
              usAddressMailing: toBoolean(fatca.usAddressMailing),
              usAccount: toBoolean(fatca.usAccount),
              usPOA: toBoolean(fatca.usPOA),
              usCareOfAddress: toBoolean(fatca.usCareOfAddress),
            },
          };
  const formData = new FormData();
  formData.append("req", JSON.stringify(filteredData));
  formData.append("signature", sigSplit[1]);
  let arrPromise = [];

  if (uploadImg.idPEPDeclaration !== null)
    arrPromise.push(
      this.createFormDataPromise(
        "PEPDocument",
        uploadImg.idPEPDeclaration,
        token,
        uid
      )
    );
  if (uploadImg.idPhotoFront !== null)
    arrPromise.push(
      this.createFormDataPromise(
        "secondary1",
        uploadImg.idPhotoFront,
        token,
        uid
      )
    );

  if (uploadImg.idPhotoBack !== null)
    arrPromise.push(
      this.createFormDataPromise(
        "secondary2",
        uploadImg.idPhotoBack,
        token,
        uid
      )
    );

  Promise.all(arrPromise)
    .then((res) => {
      let ok = true,
        msg = "";
      res.forEach((item) => {
        if (!item.data.ok) {
          ok = false;
          msg = item.data.message;
        }
      });

      if (!ok) throw new Error(msg);

      return axios.post(
        `${ROUTES.FUNCTION_BASE_URL}clients-kyc-kyc/tier3-kyc/${uid}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    })
 
  .then((res) => {
    resolve(res);
  })
  .catch((err) => {
    this.helpers.handleError(err);
    reject(err);
  });
    });
  };
  doUpdateLongKYC = (
    uid,
    uploadImg,
    additionalInfo,
    pepDeclaration,
    signature,
    fatca
  ) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      let sigSplit = signature.signatureUrl.split(",");
        const toBoolean = (item) => (item === "1" );
      const filteredData = additionalInfo.existingID
        ? {
            work: {
              annualIncomeAmount: additionalInfo.work.annualIncomeAmount,
              businessNature: additionalInfo.work.businessNature,
              nameOfEmployer: additionalInfo.work.nameOfEmployer,
              primaryOccupation: additionalInfo.work.primaryOccupation,
            },
            businessAddress: {
              barangay: "",
              street: additionalInfo.businessAddress.street,
              city: additionalInfo.businessAddress.city,
              province: additionalInfo.businessAddress.province,
              zip: additionalInfo.businessAddress.zip,
              country: additionalInfo.businessAddress.country,
            },

            ids: {
              idNumber: "",
              idType: additionalInfo.ids.idType,
              SSS:
                additionalInfo.ids.idType === "SSS" &&
                additionalInfo.ids.SSS !== undefined
                  ? additionalInfo.ids.SSS
                  : "",
              TIN:
                additionalInfo.ids.idType === "TIN" &&
                additionalInfo.ids.TIN !== undefined
                  ? additionalInfo.ids.TIN
                  : "",
            },

            PEP: {
              banks: pepDeclaration.banks,
              governmentRelative: toBoolean(pepDeclaration.governmentRelative),
              governmentWork: toBoolean(pepDeclaration.governmentWork),
            },
            FATCA: {
              usPerson: toBoolean(fatca.usPerson),
              usCitizen: toBoolean(fatca.usCitizen),
              usResident: toBoolean(fatca.usResident),
              usAlien: toBoolean(fatca.usAlien),
              usPhone: toBoolean(fatca.usPhone),
              usBorn: toBoolean(fatca.usBorn),
              usAddressResidence: toBoolean(fatca.usAddressResidence),
              usAddressMailing: toBoolean(fatca.usAddressMailing),
              usAccount: toBoolean(fatca.usAccount),
              usPOA: toBoolean(fatca.usPOA),
              usCareOfAddress: toBoolean(fatca.usCareOfAddress),
            },
          }
        : {
            work: {
              annualIncomeAmount: additionalInfo.work.annualIncomeAmount,
              businessNature: additionalInfo.work.businessNature,
              nameOfEmployer: additionalInfo.work.nameOfEmployer,
              primaryOccupation: additionalInfo.work.primaryOccupation,
            },
            businessAddress: {
              barangay: "",
              street: additionalInfo.businessAddress.street,
              city: additionalInfo.businessAddress.city,
              province: additionalInfo.businessAddress.province,
              zip: additionalInfo.businessAddress.zip,
              country: additionalInfo.businessAddress.country,
            },

            PEP: {
              banks: pepDeclaration.banks,
              governmentRelative: toBoolean(pepDeclaration.governmentRelative),
              governmentWork: toBoolean(pepDeclaration.governmentWork),
            },
            FATCA: {
              usPerson: toBoolean(fatca.usPerson),
              usCitizen: toBoolean(fatca.usCitizen),
              usResident: toBoolean(fatca.usResident),
              usAlien: toBoolean(fatca.usAlien),
              usPhone: toBoolean(fatca.usPhone),
              usBorn: toBoolean(fatca.usBorn),
              usAddressResidence: toBoolean(fatca.usAddressResidence),
              usAddressMailing: toBoolean(fatca.usAddressMailing),
              usAccount: toBoolean(fatca.usAccount),
              usPOA: toBoolean(fatca.usPOA),
              usCareOfAddress: toBoolean(fatca.usCareOfAddress),
            },
          };

  const formData = new FormData();
  formData.append("req", JSON.stringify(filteredData));
  formData.append("signature", sigSplit[1]);
  let arrPromise=[];

  if (uploadImg.idPEPDeclaration !== null)
    arrPromise.push(
      this.createFormDataPromise(
        "PEPDocument",
        uploadImg.idPEPDeclaration,
        token,
        uid
      )
    );
  if (uploadImg.idPhotoFront !== null)
    arrPromise.push(
      this.createFormDataPromise(
        "secondary1",
        uploadImg.idPhotoFront,
        token,
        uid
      )
    );

  if (uploadImg.idPhotoBack !== null)
    arrPromise.push(
      this.createFormDataPromise(
        "secondary2",
        uploadImg.idPhotoBack,
        token,
        uid
      )
    );

  Promise.all(arrPromise)
    .then((res) => {
      let ok = true,
        msg = "";
      res.forEach((item) => {
        if (!item.data.ok) {
          ok = false;
          msg = item.data.message;
        }
      });

      if (!ok) throw new Error(msg);

      return axios.post(
        `${ROUTES.FUNCTION_BASE_URL}clients-kyc-kyc/tier3-kyc/${uid}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    })

    .then((res) => {
      resolve(res);
    })
    .then((res) => {
      return assignStatus({ uid, status: "LONG_KYC-3" });
    })
    .catch((err) => {
      this.helpers.handleError(err);
      reject(err);
    });
    });
  };

}
