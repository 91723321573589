import { Button, Checkbox, Divider, FormHelperText, Paper } from "@material-ui/core";
import React, { Component, useRef, useState } from "react";
import { Grid, Fade, FormControlLabel } from "@material-ui/core";
import SignaturePad from "react-signature-canvas";
import { Controller, useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";

import CustomButton from "../../Button";
import Accordion from "../../AccountProfile/Accordion";
import SimpleModal from "../../Modal";

const Summary = (props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { modalAlert, additionalInfo, loading, PEPData, FATCAData, signature } =
    props;
    const [sigError, setSigError] =useState(false)

    const radioButton = (item) =>  item === "1" ?  "Yes" : "No";

  let SSS = additionalInfo.ids.SSS;
  let TIN = additionalInfo.ids.TIN;

  let busCountry =
    additionalInfo.businessAddress.country !== ""
      ? additionalInfo.businessAddress.country
      : "N/A";
  let busProv =
    additionalInfo.businessAddress.province !== null
      ? additionalInfo.businessAddress.province
      : "N/A";
  let busCity =
    additionalInfo.businessAddress.city !== null
      ? additionalInfo.businessAddress.city
      : "N/A";
  let busZip =
    additionalInfo.businessAddress.zip !== ""
      ? additionalInfo.businessAddress.zip
      : "N/A";
  let busStreet =
    additionalInfo.businessAddress.street !== ""
      ? additionalInfo.businessAddress.street
      : "N/A";
  let primaryOcc = additionalInfo.work.primaryOccupation;
  let nameOfEmployer = additionalInfo.work.nameOfEmployer;
  let natureOfBusiness = additionalInfo.work.businessNature;

  let governmentWork = radioButton(PEPData.governmentWork);
  let governmentRelative =
    radioButton(PEPData.governmentRelative);
  let banks = PEPData.banks;
  let usResident = radioButton(FATCAData.usResident);
  let usPhone = radioButton(FATCAData.usPhone);
  let usPOA = radioButton(FATCAData.usPOA);
  let usPerson = radioButton(FATCAData.usPerson) ;
  let usCitizen = radioButton(FATCAData.usCitizen);
  let usCareOfAddress =
    radioButton(FATCAData.usCareOfAddress) ;
  let usBorn = radioButton(FATCAData.usBorn) ;
  let usAlien = radioButton(FATCAData.usAlien) ;
  let usAddressResidence =
    radioButton(FATCAData.usAddressResidence);
  let usAddressMailing =
    radioButton(FATCAData.usAddressMailing);
  let usAccount = radioButton(FATCAData.usAccount);

  let annualIncome;
  switch (additionalInfo.work.annualIncomeAmount) {
    case 1:
      annualIncome = "PHP 50,000 or less";
      break;
    case 2:
      annualIncome = "PHP 50,001 to PHP 100,000";
      break;
    case 3:
      annualIncome = "PHP 100,001 to PHP 500,000";
      break;
    case 4:
      annualIncome = "PHP 500,001 to PHP 1,000,000";
      break;
    case 5:
      annualIncome = "PHP 1,000,001 - PHP 5,000,000";
      break;
    case 6:
      annualIncome = "5,000,001 - PHP 10,000,000";
      break;
    case 7:
      annualIncome = "PHP 10,000,001 or more";
      break;
    default:
      annualIncome = "n/a";
      break;
  }

  let PEPTable = [
    {
      title: "Have You Work In A Government Agency Or Insitution?",
      value: governmentWork,
    },
    {
      title:
        "Do You Have Any Relative Who Is / Was An Elected Governemnt Offical Or Who Is/Was An Appointed Government Offical?",
      value: governmentRelative,
    },
  ];

  if (PEPData.governmentWork || PEPData.governmentRelative)
    PEPTable.push({
      title: "Enumerate the banks you have an account in:",
      value: banks,
    });

  var dataApplication = [
    {
      title: "Additional Information",
      table: [
        {
          title: "Primary Occupation",
          value: primaryOcc,
        },
        {
          title: "Name of Employer",
          value: nameOfEmployer,
        },
        {
          title: "Nature of Business",
          value: natureOfBusiness,
        },
        ...(SSS
          ? [
              {
                title: "SSS/GSIS",
                value: SSS,
              },
            ]
          : []),
        ...(TIN
          ? [
              {
                title: "TIN",
                value: TIN,
              },
            ]
          : []),
        {
          title: "Annual Income",
          value: annualIncome,
        },
      ],
    },
    {
      title: "Business Address",
      table: [
        {
          title: "Country",
          value: busCountry,
        },
        {
          title: "Province",
          value: busProv,
        },
        {
          title: "City",
          value: busCity,
        },
        {
          title: "Street",
          value: busStreet,
        },
        {
          title: "Zip Code",
          value: busZip,
        },
      ],
    },

    {
      title: "PEP Declaration",
      table: PEPTable,
    },
    {
      title: "FATCA",
      table: [
        {
          title: "Are you a US Person?",
          value: usPerson,
        },
        {
          title: "Are You A US Citizen?",
          value: usCitizen,
        },

        {
          title: "US Resident?",
          value: usResident,
        },
        {
          title: "US Resident Alien?",
          value: usAlien,
        },
        {
          title: "Do You Have A Current US Telephone Number?",
          value: usPhone,
        },
        {
          title: "Where You Born In US?",
          value: usBorn,
        },
        {
          title: "Do You Have A Current US Residence Address?",
          value: usAddressResidence,
        },
        {
          title: "Do You Have A Current US Mailing Address? (Including US P.O)",
          value: usAddressMailing,
        },
        {
          title:
            "Have You Issued A Standing Instruction To Transferfunds To An Account",
          value: usAccount,
        },
        {
          title:
            "Have You Issued A Power Of Attorney Or Granted Signatory Authority To A Person With US Address?",
          value: usPOA,
        },
        {
          title:
            "Do You Have “In Care Of” Address Or “Hold Mail” Address That Is The Sole Address For An Account (Whether Such Address In The US Or Outside The US) ?",
          value: usCareOfAddress,
        },
      ],
    },
  ];
  const signatureCanvasRef = useRef(null);

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
  };

  const saveSignature = () => {
    if (signatureCanvasRef.current?.isEmpty()) {
    setSigError(true);
    }else{
    const signatureImage = signatureCanvasRef.current.toDataURL();
    props.handleSignature(signatureImage);
    }
  };

  const onSubmit = (data) => {
    if (signature.signatureUrl === null) {
      setSigError(true);
    }else{
      console.log(data);
    props.saveAndContinue();
    }
  };
  

  return (
    <Fade in={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleModal
          open={modalAlert.modalOpen}
          success={modalAlert.modalSuccess}
          title={modalAlert.modalTitle}
          content={modalAlert.modalContent}
          subText={modalAlert.modalSubText}
          onClose={props.handleModalClose}
        />
        <Grid item xs={12}>
          <h1>My Application Details</h1>
          <p style={{ fontStyle: "italic" }}>
            Please review your application before submission.
          </p>
          <Divider />
          {dataApplication.map((data, index) => (
            <Accordion
              title={data.title}
              table={data.table}
              index={index}
              key={index}
              defaultExpanded={true}
            />
          ))}
        </Grid>
        <Divider />
        <br />
        <Grid item xs={12}>
          <h3>Signature</h3>
          <p style={{ fontWeight: "400", fontStyle: "italic" }}>
            Please provide your signature in the space below and click ‘Save’.
          </p>
          <Paper elevation={5} style={{ minHeight: "150px" }}>
            <SignaturePad
              canvasProps={{ className: "sigPad" }}
              ref={signatureCanvasRef}
              clearOnResize={false}
            />
          </Paper>
          <br />
          <div className="flex">
            <div className="btn-back" onClick={clearSignature}>
              Erase
            </div>
            <CustomButton
              text="Save Signature"
              buttonTheme="secondary"
              onClick={saveSignature}
            />
          </div>
          <br />
          {sigError && (
            <FormHelperText style={{ color: "red" }}>
              Signature is required
            </FormHelperText>
          )}
          <br />
        </Grid>
        {signature.signatureUrl === null ? (
          ""
        ) : (
          <>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Button variant="outlined" onClick={props.handleEraseCurrentSig}>
                Clear Saved Signature
              </Button>
            </Grid>
            <br />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{ height: "100%", width: "100%" }}
            >
              <Paper elevation={3} style={{ height: "100%", width: "100%" }}>
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={signature.signatureUrl}
                  alt="Your current saved signature"
                ></img>
              </Paper>
            </Grid>
            <br />
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <p style={{ fontWeight: "400" }}>Your current saved signature</p>
            </Grid>
          </>
        )}
        <br />
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Controller
                name="agreeKYC"
                control={control}
                rules={{
                  required: `Agree to the policy of ${process.env.REACT_APP_PROJECT_NAME}.`,
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControlLabel
                    inputRef={ref}
                    name="agreeKYC"
                    checked={field.value}
                    control={
                      <Checkbox
                        checkboxName="agreeKYCBox"
                        color="primary"
                        value={field.value}
                      />
                    }
                    label={
                      <p style={{ fontWeight: "400", fontStyle: "italic" }}>
                        I hereby certify that the details furnished above are
                        true and correct to the best of my knowledge and belief
                        and I undertake to inform you of any changes therein,
                        immediately. In case any of the above information is
                        found to be false or untrue or misleading or
                        misrepresenting, I am aware that I may be held liable
                        for it.
                      </p>
                    }
                    onClick={(e) => {
                      field.onChange(e.target.checked);
                    }}
                  />
                )}
              />
            }
          />
          {errors.agreeKYC && (
            <FormHelperText style={{ color: "red" }}>
              {errors.agreeKYC.message}
            </FormHelperText>
          )}
        </Grid>
        <br /> <br />
        <Grid item xs={12}>
          <div className="flex">
            <div className="btn-back" onClick={props.prevStep}>
              Previous Step
            </div>
            {loading.loading ? (
              <CircularProgress />
            ) : (
              <CustomButton
                text="Continue"
                type="submit"
                disabled={false}
                buttonTheme="primary"
              />
            )}
          </div>
        </Grid>
      </form>
    </Fade>
  );
};



export default Summary;
