import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import { Link, withRouter } from "react-router-dom";
import * as ROUTES from "../../../constants/route";

import { withFirebase } from "../../Firebase";
import { withCookies } from "react-cookie";
import NavigationMenus from "../../NavigationMenus";
import {
  Breadcrumbs,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CustomDataTable from "../../ConfirmNewPassword/CustomDataTable";
import moment from "moment";
import { formatNumber } from "./RecurringHelper";
import comingSoon from "../../../assets/recurringPage/ComingSoon.png";

const RecurringHistoryBase = (props) => {
  return (
    <>
      <NavigationMenus
        disableFab={true}
        content={
          <RecurringHistory
            firebase={props.firebase}
            token={props.allCookies.token}
            history={props.history}
          />
        }
      />
    </>
  );
};

const RecurringHistory = (props) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fundOptions, setFundOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFund, setSelectedFund] = useState("");

  const columns = [
    {
      name: "dateCreated",
      label: "Date Created",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === "-") return value;

          return moment.unix(value.seconds).format("MM/DD/YYYY HH:MM");
        },
      },
    },

    {
      name: "fundName",
      label: "Fund Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount Invested",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => `₱ ${formatNumber(value)}`,
      },
    },
    {
      name: "day",
      label: "Investment Schedule",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) =>
          `${value === 1 ? "1st" : "16th"} of the month`,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <span className="capitalize">{value}</span>
        ),
      },
    },
  ];

  const options = {
    search: true,
    filter: false,
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
    selectableRows: "none",
    sortOrder: {
      name: "dateCreated",
      direction: "desc",
    },
    download: false,
  };

  const filteredData = tableData.filter((item) => {
    const matchSearchTerm = item.fundName
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchSelectedOption = selectedFund
      ? item.fundName === selectedFund
      : true;
    return matchSearchTerm && matchSelectedOption;
  });

  async function getInvestments() {
    try {
      setLoading(true);
      const investments = await props.firebase.db
        .collection("investmentsRecurring")
        .where("userUid", "==", props.firebase.auth.currentUser.uid)
        .where("status", "!=", "declined")
        .get();
      let invArr = [];
      investments.forEach((doc) => invArr.push(doc.data()));

      const uniqueFundNames = [...new Set(invArr.map((item) => item.fundName))];
      setFundOptions(
        uniqueFundNames.map((fundName) => ({
          label: fundName,
          value: fundName,
        }))
      );

      setTableData(invArr);
    } catch (err) {
      alert(err);
    }
  }

  useEffect(() => {
    getInvestments();
  }, []);

  return (
    <>
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textSecondary" href={ROUTES.DASHBOARD}>
            Dashboard
          </Link>
          <Typography color="textPrimary" aria-current="page">
            Monthly Investments
          </Typography>
        </Breadcrumbs>

        <h1>Monthly Recurring Investments</h1>

        <Grid container spacing={2} style={{ marginBottom: "10px" }}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <h3>All Funds</h3>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
            <FormControl
              style={{ minWidth: "100%", maxWidth: "100%" }}
              variant="outlined"
            >
              <InputLabel>Fund</InputLabel>
              <Select
                value={selectedFund}
                onChange={(e) => setSelectedFund(e.target.value)}
                label="Option"
                fullWidth
                placeholder="Select a fund"
              >
                <MenuItem key={"None"} value={""}>
                  None
                </MenuItem>
                {fundOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
            <TextField
              label="Search"
              variant="outlined"
              color="primary"
              fullWidth
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <CustomDataTable
          title={"Monthly Recurring Investments"}
          data={filteredData}
          columns={columns}
          options={options}
        />
        <div className="relative mt-4 text-black text-base font-semibold text-center">
          For any modification requests, please email{" "}
          <a
            href="mailto:online@rampver.com"
            className=" text-green-800 underline"
          >
            online@rampver.com
          </a>
        </div>
      </>
    </>
  );
};

const condition = (authUser) => !!authUser;

const RecurringHistoryPage = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase,
  withCookies
)(RecurringHistoryBase);

export default RecurringHistoryPage;
