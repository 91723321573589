import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { orange } from "@material-ui/core/colors";
import googleIcon from "../../contents/img/google.png";

const ButtonPrimary = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[900]),
    backgroundColor: "#C8932A",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    padding: "10px 45px",
    margin: "0px",
    "&:hover": {
      backgroundColor: "#b8892e",
    },
  },
}))(Button);

const ButtonSecondary = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[900]),
    backgroundColor: "#256141",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    padding: "10px 45px",
    margin: "0px",
    "&:hover": {
      backgroundColor: "#23593c",
    },
  },
}))(Button);

const ButtonGoogle = withStyles(() => ({
  root: {
    backgroundColor: "#fff",
    color: "#000",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    padding: "10px 45px",
    margin: "0px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
}))(Button);

const ButtonSuccess = withStyles(() => ({
  root: {
    backgroundColor: "rgb(76,175,80)",
    color: "white",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    margin: "0px",
    "&:hover": {
      backgroundColor: "rgb(76,175,80)",
    },
  },
}))(Button);

const ButtonDanger = withStyles(() => ({
  root: {
    backgroundColor: "rgb(244,67,54)",
    color: "white",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    margin: "0px",
    "&:hover": {
      backgroundColor: "rgb(244,67,54)",
    },
  },
}))(Button);

const ButtonWarning = withStyles(() => ({
  root: {
    backgroundColor: "rgb(255,152,0)",
    color: "white",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    margin: "0px",
    "&:hover": {
      backgroundColor: "rgb(255,152,0)",
    },
  },
}))(Button);

const ButtonCustom = withStyles(() => ({
  root: {
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    padding: "10px 45px",
    margin: "0px",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
}));
export default function CustomButton(props) {
  const classes = useStyles();
  const {
    text,
    disabled,
    type,
    buttonTheme,
    onClick,
    variant,
    color,
    fullWidth,
  } = props;
  switch (buttonTheme) {
    case "primary":
      return (
        <ButtonPrimary
          type={type}
          variant="contained"
          color="primary"
          className={classes.margin}
          disabled={disabled}
          onClick={onClick}
          fullWidth={fullWidth === undefined ? false : fullWidth}
        >
          {text}
        </ButtonPrimary>
      );
    case "secondary":
      return (
        <ButtonSecondary
          type={type}
          variant="contained"
          color="primary"
          className={classes.margin}
          disabled={disabled}
          onClick={onClick}
        >
          {text}
        </ButtonSecondary>
      );
    case "google":
      return (
        <ButtonGoogle
          type={type}
          variant="contained"
          color="primary"
          className={classes.margin}
          disabled={disabled}
          startIcon={<img src={googleIcon} alt="google-icon.png" />}
          onClick={onClick}
        >
          {text}
        </ButtonGoogle>
      );
    case "success":
      return (
        <ButtonSuccess
          type={type}
          variant="contained"
          color="primary"
          className={classes.margin}
          disabled={disabled}
          onClick={onClick}
        >
          {text}
        </ButtonSuccess>
      );
    case "danger":
      return (
        <ButtonDanger
          type={type}
          variant="contained"
          color="primary"
          className={classes.margin}
          disabled={disabled}
          onClick={onClick}
        >
          {text}
        </ButtonDanger>
      );
    case "warning":
      return (
        <ButtonWarning
          type={type}
          variant="contained"
          color="primary"
          className={classes.margin}
          disabled={disabled}
          onClick={onClick}
        >
          {text}
        </ButtonWarning>
      );

    case "custom":
      return (
        <ButtonCustom
          type={type}
          variant="outlined"
          color={color}
          className={classes.margin}
          disabled={disabled}
          onClick={onClick}
          fullWidth={fullWidth === undefined ? false : fullWidth}
        >
          {text}
        </ButtonCustom>
      );
    default:
      return (
        <ButtonPrimary
          type={type}
          variant={variant}
          color={color}
          className={classes.margin}
          disabled={disabled}
        >
          {text}
        </ButtonPrimary>
      );
  }
}
