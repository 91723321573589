import React, { Component } from "react";
import * as ROUTES from "../../constants/route";
import { Container } from "@material-ui/core";

const NotFoundPage = (props) => {
  return <NotFound props={props} />;
};

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.history = props.props.history;

    setTimeout(() => {
      this.history.push(ROUTES.LANDING);
    }, 5000);
  }

  render() {
    return (
      <Container>
        <div className="r-title-container text-center">
          <p className="r-title">404 Page Not Found</p>
          <p className="r-subtitle">You&apos;ll be redirected back shortly</p>
        </div>
      </Container>
    );
  }
}

export default NotFoundPage;
