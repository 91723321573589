import React, { useState } from "react";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import copy from "copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import instructionGif from "../../../assets/recurringPage/eye_icon.png";
import {
  Gifone,
  Giftwo,
  Gifthree,
  Giffour,
  Giffive,
  Gifsix,
  Gifseven,
  Gifeight,
} from "../../../assets/recurringPage/BPI eADA_Registration_Preview";
import GifModal from "./GifModal";
import {copyUserId} from "./RecurringHelper"

 const Instruction = ({ acctNum }) => {
   const [modalOpen, setModalOpen] = useState(false);
     const [instructionText, setInstructionText] = useState("");
     const [instructionGifLink, setInstructionGifLink] = useState("");

  const instructions = [
    {
      id: 1,
      text: (
        <span>
          Go to{" "}
          <a
            style={{ color: "blue", textDecoration: "underline" }}
            href="https://bpi-ada.com"
            target="_blank"
            rel="noreferrer"
          >
            BPI Auto-Debit Arrangement
          </a>
        </span>
      ),

      gifFile: Gifone,
    },
    {
      id: 2,
      text: "Search for 'Rampver Online' from the list of billers",
      gifFile: Giftwo,
    },
    {
      id: 3,
      text: "Select 'Rampver Online' and agree to the Terms & Conditions",
      gifFile: Gifthree,
    },
    {
      id: 4,
      text: (
        <>
          <span>
            Enter the last 10 digits of your Rampver Online Account Number as
            your Reference Number in BPI. Your Rampver Online account number is
            RF
            <span className=" font-bold">{acctNum}</span>
          </span>
          {copyUserId(acctNum)}
        
        </>
      ),
      gifFile: Giffour,
    },
    {
      id: 5,
      text: "Enter your BPI account using your username and password",
      gifFile: Giffive,
    },
    {
      id: 6,
      text: "Select your BPI account for enrollment (Note: this account must be funded for us to debit and book your investment)",
      gifFile: Gifsix,
    },
    {
      id: 7,
      text: "Enter the One-Time PIN sent to your registered mobile number",
      gifFile: Gifseven,
    },
    {
      id: 8,
      text: "A successful page will be displayed to confirm your registration.",
      gifFile: Gifeight,
    },
  ];
  return (
    <>
      <ul>
        {instructions.map(({ id, text, gifFile }) => {
          const handleOpenGif = () => {
            setInstructionGifLink(gifFile);
            setModalOpen(true);
            setInstructionText(text);
          };

          return (
            <div key={id}>
              <p className="relative font-normal text-base align-middle ">
                <Grid container>
                  <Grid item lg={1} md={2} sm={2} xs={2}>
                    <img
                      src={instructionGif}
                      alt="Instruction Icon"
                      className="relative mx-0 lg:mx-5 cursor-pointer bg-primary w-10 h-10 rounded-full"
                      onClick={handleOpenGif}
                    />
                  </Grid>
                  <Grid item lg={11} md={10} sm={10} xs={10}>
                    <span className="relative top-2">{text}</span>
                  </Grid>
                </Grid>
              </p>
            </div>
          );
        })}
      </ul>
      {modalOpen && (
        <GifModal
          text={instructionText}
          onClose={() => setModalOpen(false)}
          gifLink={instructionGifLink}
        />
      )}
    </>
  );
};
export default Instruction;