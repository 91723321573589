import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import Navbar from "../Navbar";
import { Container } from "@material-ui/core";

const TermsAndConditionsPage = () => {
  return <TermsAndCondition />;
};

class TermsAndConditionsBase extends Component {
  constructor(props) {
    super(props);

    this.state = { text: "" };
  }
  componentDidMount() {
    const terms = this.props.firebase.db
      .collection("miscellaneous")
      .doc("termsAndConditions")
      .get();
    terms
      .then((result) => {
        var text = result
          .data()
          .content.replaceAll("Open Sans", "Gill Sans, Poppins")
          .replaceAll("DauphinPlain", "Gill Sans, Poppins");
        this.setState({ text: text });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <>
        <Navbar />
        <Container container maxWidth="md">
          <h1>Terms and Conditions</h1>
          <div style={{ paddingBottom: 30 }}>
            <div dangerouslySetInnerHTML={{ __html: this.state.text }}></div>
          </div>
        </Container>
      </>
    );
  }
}

const TermsAndCondition = compose(
  withRouter,
  withFirebase
)(TermsAndConditionsBase);

export default TermsAndConditionsPage;

export { TermsAndCondition };
