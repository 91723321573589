import axios from "axios";
import * as ROUTES from "../../constants/route";
import Helpers from "../Helpers";

export default class DashboardFunctions {
  constructor() {
    this.helpers = new Helpers();
  }

  createInvestmentSummary = (userUid) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${ROUTES.FUNCTION_BASE_URL}clients-client/createSummary`,
          JSON.stringify({
            userUid,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  updateInvestmentSummary = (userUid) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${ROUTES.FUNCTION_BASE_URL}clients-client/updateSummary`,
          JSON.stringify({
            userUid,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  getInvestmentGraphData = (userUid) => {
    const token = this.helpers.getCookie("token");

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ROUTES.FUNCTION_BASE_URL}clients-client/get-user-graph/${userUid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
