import axios from 'axios';
import Helpers from "../components/Helpers/index";

import * as ROUTES from "../constants/route";

export const assignStatus = async (status) => {
  try {
    const helpers = new Helpers();

    const token = helpers.getCookie("token");

    const url = `${ROUTES.FUNCTION_BASE_URL}/metrics-metrics/assign-status`

    const response = await axios.post(url, status, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};