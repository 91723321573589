import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";

class AlertBox extends Component {
  render() {
    const { severity, message } = this.props;
    return <Alert severity={severity}>{message}</Alert>;
  }
}

export default AlertBox;
