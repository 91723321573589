import React, { useState } from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import {
  Breadcrumbs,
  Button,
  Container,
  makeStyles,
  Paper,
  useMediaQuery,
  useTheme,
  withStyles
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import LandingLayout from "@/components/LandingLayout";
import NavigationMenus from "../NavigationMenus";
import { withAuthorization } from "../Session";
import * as ROUTES from "../../constants/route";
import { NavLink } from "react-router-dom";
import data from './FAQs.json';

const FAQsPage = () => {
  return <NavigationMenus content={<FrequentlyAskedQuestions />} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
  },
  container: {
    maxWidth: "7xl",
    margin: "auto",
    padding: "4px",
    paddingTop: "16px",
  },
  topic: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80px",
    marginTop: "8px",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:not(:last-child)": {
      marginRight: "8px",
    },
  },
  inactiveTopic: {
    backgroundColor: "#fff",
    color: "#CACACA",
  },
  faqContainer: {
    marginTop: "16px",
  },
  faqSelectorText: {
    padding: "0px 20px",
    fontWeight: 500,
    textAlign: "center"
  },
  bottomBar: {
    padding: theme.breakpoints.down("sm") ? "5px 25px" : "0px 225px",
    backgroundColor: "#C8932A",
    color: "white",
    maxWidth: "100%",
    position: "fixed", 
    bottom: 0, 
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.breakpoints.down("sm") ? "0px" : "30px",
  },
}));

const CustomAccordion = withStyles({
  root: {
    backgroundColor: "#C8932A",
    color: "white",
  },
  expanded: {},
})(Accordion);

const CustomAccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    minHeight: 56,
  },
  expanded: {},
})(AccordionSummary);

const CustomAccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    color: "black",
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

function FAQsAccordion() {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));

  const [activeSubjectIndex, setActiveSubjectIndex] = useState(0);
  return (
    <div className={classes.root}>
      {isMobile ?
        <Container className={classes.container}>
          {data.subjects.map((item, index) => (
            <CustomAccordion key={index} style={{ marginBottom: '5px' }}>
              <CustomAccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              >
                <Typography className={classes.heading}>{item.topic}</Typography>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                <div className={classes.faqContainer}>
                  {data.subjects[index].faqs.map((faq, idx) => (
                    <Accordion key={idx} square >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>
                          {faq.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </CustomAccordionDetails>
            </CustomAccordion>
          ))}
        </Container>
        :
        <Container className={classes.container}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            {data.subjects.map((item, index) => (
              <Grid item key={index} xs={3}>
                <Paper
                  variant="outlined"
                  className={`${classes.topic} ${index === activeSubjectIndex ? "" : classes.inactiveTopic}`}
                  onClick={() => setActiveSubjectIndex(index)}
                >
                  <Typography className={classes.faqSelectorText} >
                    {item.topic}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>

          <div className={classes.faqContainer}>
            {data.subjects[activeSubjectIndex].faqs.map((faq, index) => (
              <Accordion key={index} >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Container>
      }
    </div>
  );
}

const FrequentlyAskedQuestionsBase = () => {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink color="textSecondary" to={ROUTES.DASHBOARD}>
          Dashboard
        </NavLink>
        <Typography color="textPrimary" aria-current="page">
          Frequently Asked Questions
        </Typography>
      </Breadcrumbs>

      <Container container maxWidth="lg">
        <h1>Frequently Asked Questions</h1>
        <FAQsAccordion />
      </Container>
    </>
  );
};

const FrequentlyAskedQuestionsNoAuth = () => {
  const classes = useStyles();

  return (
    <LandingLayout>
      <Container container maxWidth="lg" style={{marginBottom: '140px'}} >
        <h1>Frequently Asked Questions</h1>
        <FAQsAccordion />
      </Container>

     <div className={classes.bottomBar}>
      <div className={classes.buttonContainer}>
        <h4 style={{ marginRight: "20px" }}>
          Learn more about Rampver Financials
        </h4>
        <Button
          variant="contained"
          color="secondary"
          style={{ color: "white", padding: "10px 65px" }}
          href={`https://rampver.com/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Rampver Website
        </Button>
      </div>
    </div>
    </LandingLayout>
  )
};

const condition = (authUser) => !!authUser;

const FrequentlyAskedQuestions = compose(
  withAuthorization(condition),
  withFirebase
)(FrequentlyAskedQuestionsBase);

const FrequentlyAskedQuestionsDashboard = compose(
  withFirebase
)(FrequentlyAskedQuestionsNoAuth);

export { FAQsPage, FrequentlyAskedQuestionsDashboard };
