import { ValidatorForm } from "react-material-ui-form-validator";

class Validators {
  changePasswordValue = (value) => {
    this.mount(value);
  };

  mount = (password) => {
    ValidatorForm.addValidationRule("isValidPassword", (value) => {
      var strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,64}$)"
      );
      if (!strongRegex.test(value)) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("passwordMatched", (value) => {
      if (password !== value) {
        return false;
      }
      return true;
    });
  };

  unmount = () => {
    ValidatorForm.removeValidationRule("isValidPassword");
    ValidatorForm.removeValidationRule("passwordMatched");
  };
}

export default Validators;
