import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/route";

const withoutAuthorization = (condition) => (Component) => {
  class WithOutAuthorization extends React.Component {
    state = { role: null };

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (authUser) {
            this.props.firebase
              .getCustomClaims()
              .then((res) => {
                if (res.claims.role === "client" && condition(authUser)) {
                  this.setState({ role: "client" });
                  this.props.history.push(ROUTES.DASHBOARD);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          // if (condition(authUser)) {
          //   this.props.history.push(ROUTES.DASHBOARD);
          // }
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      if (this.state.role !== null) {
        return (
          <AuthUserContext.Consumer>
            {(authUser) =>
              !condition(authUser) ? <Component {...this.props} /> : null
            }
          </AuthUserContext.Consumer>
        );
      } else {
        return <Component {...this.props} />;
      }
    }
  }

  return compose(withRouter, withFirebase)(WithOutAuthorization);
};

export default withoutAuthorization;
