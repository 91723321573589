import React from "react";
import { VerifiedUser } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/route";

function OverlayAlertBox(props) {
  const history = useHistory();
  const { firstInvestment } = props;
  const okay = () => {
    history.push(ROUTES.MY_INVESTMENTS);
  };

  return (
    <div className="overlay-alert">
      <div className="alert-box">
        <div className="icon-bgRampver">
          <VerifiedUser fontSize="large" />
        </div>
        <h2>Thank you for investing on Rampver Online!</h2>
        <span>
          We will verify your payment and process your investment within the
          next business day. We will email you with the complete investment
          details.
        </span>
        <br /> <br />
        {firstInvestment ? (
          <span>
            For your first investment, we will process within 2-3 business days.
          </span>
        ) : (
          ""
        )}
        <br />
        <Button
          variant="contained"
          color="primary"
          className="custom-btn"
          size="large"
          onClick={okay}
          fullWidth
        >
          Okay
        </Button>
      </div>
    </div>
  );
}

export default OverlayAlertBox;
