import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import withoutAuthorization from "../Session/withoutAuthorization";
import { withRouter } from "react-router-dom";
import logo from "../../contents/img/Rampver_Online_Logo-main.png";
import * as ROUTES from "../../constants/route";

import { compose } from "recompose";

import { Container, Grid, Fade, Button, Paper } from "@material-ui/core";

class SessionEnded extends Component {
  constructor(props) {
    super(props);
    this.props.firebase.doSignOut();
  }

  signout() {
    this.props.history.push(ROUTES.SIGN_IN);
  }

  render() {
    return (
      <div
        className="inverted-bg"
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        }}
      >
        <Container maxWidth="sm" style={{ marginTop: "270px" }}>
          <Fade in={true}>
            <Paper style={{ padding: "30px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      style={{
                        height: "auto",
                        width: "150px",
                      }}
                      src={logo}
                      alt="rampver-logo.png"
                    ></img>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                  <span style={{ fontWeight: "600" }}>
                    For your security, you have been logged out of Rampver
                    Online after 15 minutes of inactivity.
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    onClick={() => this.signout()}
                    variant="contained"
                    color="secondary"
                    style={{ color: "white", padding: "10px 40px" }}
                  >
                    Return to Log In
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Fade>
        </Container>
      </div>
    );
  }
}

const condition = (authUser) => !!authUser;

const SessionEndedPage = compose(
  withoutAuthorization(condition),
  withRouter,
  withFirebase
)(SessionEnded);

export default SessionEndedPage;
