import React, { Component, useEffect, useState } from "react";
import {
  Breadcrumbs,
  LinearProgress,
  Link,
  Typography,
} from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import NavigationMenus from "../NavigationMenus";
import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { compose } from "recompose";
import Helpers from "../Helpers";
import { Container } from "@material-ui/core";
import moment from "moment";

export default class NavpsPage extends Component {
  render() {
    return (
      <>
        <NavigationMenus content={<Navps />} />
      </>
    );
  }
}

const NavpsBase = (props) => {
  const [funds, setFunds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");

  const helpers = new Helpers();

  useEffect(() => {
    const fetchData = async () => {
      const fundsRef = props.firebase.db
        .collection("funds")
        .orderBy("name", "asc");
      const fundRatesRef = props.firebase.db.collection("fundRates");

      try {
        setLoading(true);
        const [fundsSnapshot, fundRatesSnapshot] = await Promise.all([
          fundsRef.get(),
          fundRatesRef.orderBy("date", "desc").limit(1).get(),
        ]);

        const latestFundRate = fundRatesSnapshot.docs[0].data();

        const updatedFunds = fundsSnapshot.docs.map((doc) => {
          const fund = doc.data();

          const fundRateFundIndex = latestFundRate.fundDetails.findIndex(
            (value) => value.fundName === fund.name
          );

          return {
            name: fund.name,
            navps: latestFundRate.fundDetails[fundRateFundIndex].rate,
            ytd: latestFundRate.fundDetails[fundRateFundIndex].ytd
          };
        });
        setLoading(false);
        setDate(moment.unix(latestFundRate.date.seconds).format("MM/DD/YYYY"));
        setFunds(updatedFunds);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const renderFunds = funds.map((fund) => (
    <tr key={fund.name}>
      <td>{fund.name}</td>
      <td>{helpers.formatToPHP(fund.navps, 4)}</td>
      <td
        className={
          fund.ytd > 0
            ? "text-ytd-above"
            : fund.ytd < 0
            ? "text-ytd-below"
            : null
        }
      >
        {parseFloat(fund.ytd * 100).toFixed(2) + "%"}
      </td>
    </tr>
  ));

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="textSecondary" href={ROUTES.DASHBOARD}>
          Dashboard
        </Link>
        <Typography color="textPrimary" aria-current="page">
          Net Asset Value Per Share
        </Typography>
      </Breadcrumbs>

      <div>
        <br /> <br />
        <Container maxWidth="lg">
          <div className="net-asset">
            <div className="title">
              <h2>Net Asset Value per Share as of: {date}</h2>
            </div>
            {loading && <LinearProgress />}
            <table>
              <thead>
                <tr>
                  <th>Mutual Funds</th>
                  <th>NAVPS</th>
                  <th>YTD</th>
                </tr>
              </thead>
              <tbody>{renderFunds}</tbody>
            </table>
            <br />
            <div className="note">
              <span>
                <b>Note: </b>
                The value or price per share changes daily, depending on the
                performance of the whole investment mix.
              </span>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const condition = (authUser) => !!authUser;

const Navps = compose(withAuthorization(condition), withFirebase)(NavpsBase);

export { Navps };
