import React from "react";
import {
  FormControlLabel,
  Checkbox,
  Fade,
  FormHelperText,
} from "@material-ui/core";
import { ValidatorComponent } from "react-material-ui-form-validator";

class CheckboxField extends ValidatorComponent {
  renderValidatorComponent() {
    const { formName, checked, color, label, onClick, checkboxName, value } =
      this.props;
    return (
      <div>
        <FormControlLabel
          name={formName}
          checked={checked}
          control={<Checkbox name={checkboxName} color={color} value={value} />}
          label={label}
          onClick={onClick}
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <Fade in={true}>
        <FormHelperText style={{ color: "red" }}>
          {this.getErrorMessage()}
        </FormHelperText>
      </Fade>
    );
  }
}

export default CheckboxField;
