import React, { ReactNode, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import RampverLogoMobile from "../contents/img/Rampver Online Logo Square.png";
import RampverLogo from "@/assets/landing/rampver-logo.png";

import { COLOR_SUCCESS } from "@/constants/colors";
import { Drawer, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import * as ROUTES from "../constants/route";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: COLOR_SUCCESS
  },
  logo: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(0, 2),
    color: "white",
    textDecoration: "none"
  },
  loginButton: {
    padding: "0.5rem 4.5rem",
    color: "white"
  },
  secondaryButtonContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1)
  },
  menuIcon: {
    marginRight: theme.spacing(2)
  },
  logoMobile: {
    height: "auto",
    width: "200px",
    marginLeft: "-25px",
    marginTop: "5px",
    marginBottom: "10px",
    alignSelf: "center"
  },
  drawerPaperMobile: {
    backgroundColor: "#256141",
    width: "270px"
  }
}));

type Props = {
  children: ReactNode;
};

export default function LandingLayout({ children }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const showSecondaryButton = location.pathname !== "/";

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const handleMenuToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <main>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <div className={classes.logo}>
            <Link to="/">
              <img
                src={RampverLogo}
                alt="Rampver Logo"
                width="auto"
                height={isMobile ? "35px" : "50px"}
              />
            </Link>
          </div>
          {isMobile ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleMenuToggle}
                className={classes.menuIcon}
              >
                <MenuIcon style={{ color: "white" }} />
              </IconButton>
              <Drawer
                variant={"temporary"}
                anchor={"right"}
                open={mobileOpen}
                onClose={handleMenuToggle}
                classes={{
                  paper: classes.drawerPaperMobile
                }}
                ModalProps={{
                  keepMounted: true
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <img
                    className={classes.logoMobile}
                    src={RampverLogoMobile}
                    alt="rampver-logo-mobile"
                  />
                  <List>
                    <ListItem button>
                      <NavLink to={ROUTES.DASHBOARD}>
                        <ListItemText
                          primary={"Home"}
                          style={{ color: "white" }}
                        />
                      </NavLink>
                    </ListItem>

                    <ListItem button>
                      <NavLink to={`/#learning-center`}>
                        <ListItemText
                          primary={"Learning Center"}
                          style={{ color: "white" }}
                        />
                      </NavLink>
                    </ListItem>

                    <ListItem button>
                      <NavLink
                        to={"/#learning-center"}
                        //to={ROUTES.FAQS_NOAUTH}
                      >
                        <ListItemText
                          primary={"FAQs"}
                          style={{ color: "white" }}
                        />
                      </NavLink>
                    </ListItem>
                    <br />
                    <br />

                    <ListItem>
                      <Button
                        fullWidth
                        className={classes.loginButton}
                        component={Link}
                        to={ROUTES.SIGN_IN}
                        variant="contained"
                        color="primary"
                      >
                        Login
                      </Button>
                    </ListItem>
                  </List>
                </div>
              </Drawer>
            </>
          ) : (
            <div className={classes.secondaryButtonContainer}>
              <NavLink to="/#learning-center" className={classes.link}>
                <Button color="inherit">Learning Center</Button>
              </NavLink>
              <NavLink to="/faqs" className={classes.link}>
                <Button color="inherit">FAQs</Button>
              </NavLink>
              <Button
                className={classes.loginButton}
                component={Link}
                to={ROUTES.SIGN_IN}
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {children}
    </main>
  );
}
