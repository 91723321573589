import axios from "axios";
import * as ROUTES from "../../../constants/route";
import Helpers from "../../Helpers";

export default class Functions {
  constructor() {
    this.helpers = new Helpers();
  }

  createFormDataPromise = (name, data, token, uid) => {
    const formData = new FormData();
    formData.append(name, data);
    return axios.post(
      `${ROUTES.FUNCTION_BASE_URL}clients-kyc-kyc/AddKYCImage/${uid}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  doAddLongKYC = (uid, clientInfo, riskProfile, uploadImg) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      const submittedData = {
        useruid: uid,

        name: clientInfo.name,
        email: clientInfo.email,
        mobileNumber: clientInfo.mobileNumber,
        gender: clientInfo.gender,
        civil: {
          civilStatus: clientInfo.civil.civilStatus,
          spouseName: "N/A",
        },
        dateOfBirth: clientInfo.dateOfBirth,
        placeOfBirth: clientInfo.placeOfBirth,
        cityOfBirth: clientInfo.cityOfBirth,
        nationality: clientInfo.nationality,
        ids: {
          idNumber: "",
          idType: clientInfo.ids.idType,
          SSS:
            clientInfo.ids.idType === "None"
              ? ""
              : clientInfo.ids.SSS !== undefined
              ? clientInfo.ids.SSS
              : "",
          TIN:
            clientInfo.ids.idType === "None"
              ? ""
              : clientInfo.ids.TIN !== undefined
              ? clientInfo.ids.TIN
              : "",
        },

        currentAddress: {
          street: clientInfo.currentAddress.street,
          barangay: "",
          city: clientInfo.currentAddress.city,
          province: clientInfo.currentAddress.province,
          zip: clientInfo.currentAddress.zip,
          country: clientInfo.currentAddress.country,
        },
        permanentAddress: {
          street: "",
          barangay: "",
          city: null,
          province: null,
          zip: "",
          country: "Philippines",
        },
        businessAddress: {
          street: "",
          barangay: "",
          city: null,
          province: null,
          zip: "",
          country: "",
        },
        contact: {
          mailingAddress: "",
        },
        work: {
          primaryOccupation: clientInfo.work.primaryOccupation,
          sourceOfFunds: clientInfo.work.sourceOfFunds,
          annualIncomeAmount: 0,
          businessNature: "",
          nameOfEmployer: "",
          businessNum: "",
        },
        investmentManager: {
          investmentManagerName: "",
          investmentManagerId: "",
        },
        annualIncome: {
          annualIncome1: null,
          annualIncome2: null,
          annualIncome3: null,
          annualIncome4: null,
        },
        otherProducts: "",
        regularBasis: "",
        PEP: {
          governmentWork: null,
          governmentRelative: null,
          banks: null,
        },
        FATCA: {
          usCitizen: null,
          usResident: null,
          usAlien: null,
          usPhone: null,
          usBorn: null,
          usAddressResidence: null,
          usAddressMailing: null,
          usAccount: null,
          usPOA: null,
          usCareOfAddress: null,
        },
        instrumentsInvested: {
          instrumentsInvested1: false,
          instrumentsInvested2: false,
          instrumentsInvested3: false,
        },
        investmentPriority: {
          investmentPriority1: false,
          investmentPriority2: false,
          investmentPriority3: false,
        },
        investmentFamiliarity: {
          investmentFamiliarity1: false,
          investmentFamiliarity2: false,
          investmentFamiliarity3: false,
        },
        investmentType: {
          investmentType1: false,
          investmentType2: false,
          investmentType3: false,
        },
        incomeInvestment: {
          incomeInvestment1: false,
          incomeInvestment2: false,
          incomeInvestment3: false,
        },
        incomeStability: {
          incomeStability1: false,
          incomeStability2: false,
          incomeStability3: false,
        },
        periodWithoutRedemption: {
          periodWithoutRedemption1: false,
          periodWithoutRedemption2: false,
          periodWithoutRedemption3: false,
        },
        yearsUntilRetirement: {
          yearsUntilRetirement1: false,
          yearsUntilRetirement2: false,
          yearsUntilRetirement3: false,
        },
        willingRisk: {
          willingRisk1: false,
          willingRisk2: false,
          willingRisk3: false,
        },
        portfolioComfort: {
          portfolioComfort1: false,
          portfolioComfort2: false,
          portfolioComfort3: false,
        },
        stocksWillingness: {
          stocksWillingness1: false,
          stocksWillingness2: false,
          stocksWillingness3: false,
        },
        accountName: "",
        bankName: "",
        bankAccountNumber: "",
        savingsAccount: null,
      };

      const riskQuesArr = [
        {
          category: "instrumentsInvested",
          target: "selectedInstrumentsInvested",
        },
        {
          category: "investmentPriority",
          target: "selectedInvestmentPriority",
        },
        {
          category: "investmentFamiliarity",
          target: "selectedInvestmentFamiliarity",
        },
        {
          category: "investmentType",
          target: "selectedInvestmentType",
        },
        {
          category: "incomeStability",
          target: "selectedIncomeStability",
        },
        {
          category: "periodWithoutRedemption",
          target: "selectedPeriodWithoutRedemption",
        },
        {
          category: "yearsUntilRetirement",
          target: "selectedYearsUntilRetirement",
        },
        {
          category: "willingRisk",
          target: "selectedWillingRisk",
        },
        {
          category: "portfolioComfort",
          target: "selectedPortfolioComfort",
        },
      ];

      riskQuesArr.map((item) => {
        const filter = submittedData[item.category];
        const selected = parseInt(riskProfile[item.target]);
        const target = Object.keys(filter)[selected - 1];
        filter[target] = true;
        submittedData[item.category] = { ...filter };
      });

      console.log(submittedData);

      const formData = new FormData();
      formData.append("req", JSON.stringify(submittedData));

      let arrPromise = [];

      arrPromise.push(
        this.createFormDataPromise(
          "idPhotoFront",
          uploadImg.idPhotoFront,
          token,
          uid
        )
      );
      arrPromise.push(
        this.createFormDataPromise(
          "idPhotoBack",
          uploadImg.idPhotoBack,
          token,
          uid
        )
      );

      arrPromise.push(
        this.createFormDataPromise("selfie", uploadImg.selfie, token, uid)
      );

      if (uploadImg.marriageCert !== null)
        arrPromise.push(
          this.createFormDataPromise(
            "marriageCert",
            uploadImg.marriageCert,
            token,
            uid
          )
        );

      Promise.all(arrPromise)
        .then((res) => {
          let ok = true,
            msg = "";
          res.forEach((item) => {
            if (!item.data.ok) {
              ok = false;
              msg = item.data.message;
            }
          });

          if (!ok) throw new Error(msg);

          return axios.post(
            `${ROUTES.FUNCTION_BASE_URL}clients-kyc-kyc/AddKYCLong/${uid}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  doUpdateLongKYC = (uid, clientInfo, riskProfile, uploadImg) => {
    const token = this.helpers.getCookie("token");

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      let arrPromise = [];

          const submittedData = {
            useruid: uid,

            name: clientInfo.name,
            email: clientInfo.email,
            mobileNumber: clientInfo.mobileNumber,
            gender: clientInfo.gender,
            civil: {
              civilStatus: clientInfo.civil.civilStatus,
              spouseName: "N/A",
            },
            dateOfBirth: clientInfo.dateOfBirth,
            placeOfBirth: clientInfo.placeOfBirth,
            cityOfBirth: clientInfo.cityOfBirth,
            nationality: clientInfo.nationality,
            ids: {
              idNumber: "",
              idType: clientInfo.ids.idType,
              SSS:
                clientInfo.ids.idType === "None"
                  ? ""
                  : clientInfo.ids.SSS !== undefined
                  ? clientInfo.ids.SSS
                  : "",
              TIN:
                clientInfo.ids.idType === "None"
                  ? ""
                  : clientInfo.ids.TIN !== undefined
                  ? clientInfo.ids.TIN
                  : "",
            },

            currentAddress: {
              street: clientInfo.currentAddress.street,
              barangay: "",
              city: clientInfo.currentAddress.city,
              province: clientInfo.currentAddress.province,
              zip: clientInfo.currentAddress.zip,
              country: clientInfo.currentAddress.country,
            },
            permanentAddress: {
              street: "",
              barangay: "",
              city: null,
              province: null,
              zip: "",
              country: "Philippines",
            },
            businessAddress: {
              street: "",
              barangay: "",
              city: null,
              province: null,
              zip: "",
              country: "",
            },
            contact: {
              mailingAddress: "",
            },
            work: {
              primaryOccupation: clientInfo.work.primaryOccupation,
              sourceOfFunds: clientInfo.work.sourceOfFunds,
              annualIncomeAmount: 0,
              businessNature: "",
              nameOfEmployer: "",
              businessNum: "",
            },
            investmentManager: {
              investmentManagerName: "",
              investmentManagerId: "",
            },
            annualIncome: {
              annualIncome1: null,
              annualIncome2: null,
              annualIncome3: null,
              annualIncome4: null,
            },
            otherProducts: "",
            regularBasis: "",
            PEP: {
              governmentWork: null,
              governmentRelative: null,
              banks: null,
            },
            FATCA: {
              usCitizen: null,
              usResident: null,
              usAlien: null,
              usPhone: null,
              usBorn: null,
              usAddressResidence: null,
              usAddressMailing: null,
              usAccount: null,
              usPOA: null,
              usCareOfAddress: null,
            },
            instrumentsInvested: {
              instrumentsInvested1: false,
              instrumentsInvested2: false,
              instrumentsInvested3: false,
            },
            investmentPriority: {
              investmentPriority1: false,
              investmentPriority2: false,
              investmentPriority3: false,
            },
            investmentFamiliarity: {
              investmentFamiliarity1: false,
              investmentFamiliarity2: false,
              investmentFamiliarity3: false,
            },
            investmentType: {
              investmentType1: false,
              investmentType2: false,
              investmentType3: false,
            },
            incomeInvestment: {
              incomeInvestment1: false,
              incomeInvestment2: false,
              incomeInvestment3: false,
            },
            incomeStability: {
              incomeStability1: false,
              incomeStability2: false,
              incomeStability3: false,
            },
            periodWithoutRedemption: {
              periodWithoutRedemption1: false,
              periodWithoutRedemption2: false,
              periodWithoutRedemption3: false,
            },
            yearsUntilRetirement: {
              yearsUntilRetirement1: false,
              yearsUntilRetirement2: false,
              yearsUntilRetirement3: false,
            },
            willingRisk: {
              willingRisk1: false,
              willingRisk2: false,
              willingRisk3: false,
            },
            portfolioComfort: {
              portfolioComfort1: false,
              portfolioComfort2: false,
              portfolioComfort3: false,
            },
            stocksWillingness: {
              stocksWillingness1: false,
              stocksWillingness2: false,
              stocksWillingness3: false,
            },
            accountName: "",
            bankName: "",
            bankAccountNumber: "",
            savingsAccount: null,
          };

          const riskQuesArr = [
            {
              category: "instrumentsInvested",
              target: "selectedInstrumentsInvested",
            },
            {
              category: "investmentPriority",
              target: "selectedInvestmentPriority",
            },
            {
              category: "investmentFamiliarity",
              target: "selectedInvestmentFamiliarity",
            },
            {
              category: "investmentType",
              target: "selectedInvestmentType",
            },
            {
              category: "incomeStability",
              target: "selectedIncomeStability",
            },
            {
              category: "periodWithoutRedemption",
              target: "selectedPeriodWithoutRedemption",
            },
            {
              category: "yearsUntilRetirement",
              target: "selectedYearsUntilRetirement",
            },
            {
              category: "willingRisk",
              target: "selectedWillingRisk",
            },
            {
              category: "portfolioComfort",
              target: "selectedPortfolioComfort",
            },
          ];

          riskQuesArr.map((item) => {
            const filter = submittedData[item.category];
            const selected = parseInt(riskProfile[item.target]);
            const target = Object.keys(filter)[selected - 1];
            filter[target] = true;
            submittedData[item.category] = { ...filter };
          });
          console.log(submittedData)
      formData.append("req", JSON.stringify(submittedData));

      if (uploadImg.idPhotoFront !== ""){
        arrPromise.push(
          this.createFormDataPromise(
            "idPhotoFront",
            uploadImg.idPhotoFront,
            token,
            uid
          )
        );
      }

      if (uploadImg.idPhotoBack !== ""){
        arrPromise.push(
          this.createFormDataPromise(
            "idPhotoBack",
            uploadImg.idPhotoBack,
            token,
            uid
          )
        );
      }

      if (uploadImg.selfie !== ""){
        arrPromise.push(
          this.createFormDataPromise("selfie", uploadImg.selfie, token, uid)
        );
      }

      if (uploadImg.marriageCert !== ""){
        arrPromise.push(
          this.createFormDataPromise(
            "marriageCert",
            uploadImg.marriageCert,
            token,
            uid
          )
        );
      }

      Promise.all(arrPromise)
        .then((res) => {
          let ok = true,
            msg = "";

          res.forEach((item) => {
            if (!item.data.ok) {
              ok = false;
              msg = item.data.message;
            }
          });

          if (!ok) throw new Error(msg);

          return axios.post(
            `${ROUTES.FUNCTION_BASE_URL}clients-kyc-kyc/UpdateKYCLong/${uid}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((res) => {
          console.log(res)
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
