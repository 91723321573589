import React from "react";
import { Fade, FormHelperText } from "@material-ui/core";
import { ValidatorComponent } from "react-material-ui-form-validator";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "400px", 
    padding: "100px", 
    [theme.breakpoints.down("sm")]: {
      // Styles for screens smaller than 600px
      height: "280px",
      padding: "50px",
    },
    [theme.breakpoints.up("md")]: {
      // Styles for screens 600px and up
      height: "330px",
      padding: "35px",
    },
    [theme.breakpoints.up("lg")]: {
      // Styles for screens 1280px and up
      height: "410px",
      padding: "75px",
    },
  },
}));

function withStyles(Component) {

  return function StyledComponent(props) {
    const classes = useStyles();
    return <Component {...props} classes={classes} />;
  };
}

class DropDownField extends ValidatorComponent {
  renderValidatorComponent() {
    const { onAdd, onDrop, onDelete, value, image, classes, className } = this.props;
    let fileObjects = [];
    if (image) {
      fileObjects.push(image);
    }

    return (
      <div>
        <DropzoneAreaBase
          acceptedFiles={[
            "image/png",
            "image/jpeg",
            "image/webp",
            "image/heic",
            "image/heif",
            "application/octet-stream",
            "application/pdf",
          ]}
          classes={!className ?"":{ root: classes.root }}
          value={value}
          clearOnUnmount={false}
          maxFileSize={9500000}
          filesLimit={1}
          fileObjects={fileObjects}
          useChipsForPreview={true}
          onAdd={onAdd}
          onDrop={onDrop}
          onDelete={onDelete}
        />
        {this.errorText()}
      </div>
    );
  }

  errorText()  {
    const { isValid } = this.state;
    if (isValid) {
      return null;
    }
    return (
      <Fade in={true}>
        <FormHelperText style={{ color: "red" }}>
          {this.getErrorMessage()}
        </FormHelperText>
      </Fade>
    );
  }
}

export default withStyles(DropDownField);