export const INITIAL_STATE = {
  riskProfile: {
    selectedAnnualIncome: "",
    selectedInstrumentsInvested: "",
    selectedInvestmentPriority: "",
    selectedInvestmentFamiliarity: "",
    selectedInvestmentType: "",
    selectedIncomeStability: "",
    selectedPeriodWithoutRedemption: "",
    selectedYearsUntilRetirement: "",
    selectedWillingRisk: "",
    selectedPortfolioComfort: "",
  },
  images: {
    oldIdPhotoFront: null,
    oldIdPhotoBack: null,
    oldSelfie: null,
    oldMarriageCert: null,
    idPhotoFront: null,
    idPhotoBack: null,
    selfie: null,
    marriageCert: null,
    idPhotoFrontLabel: "",
    idPhotoBackLabel: "",
    selfieLabel: "",
    marriageCertLabel: "",
    marriageCertPreview: null,
    idPhotoFrontPreview: null,
    idPhotoBackPreview: null,
    selfiePreview: null,
  },
  clientInfo: {
    name: {
      firstName: "",
      middleName: "",
      lastName: "",
      suffix: "-",
    },
    email: "",
    mobileNumber: "",
    gender: "",
    civil: {
      civilStatus: "",
    },
    dateOfBirth: "",
    placeOfBirth: "",
    cityOfBirth: "",
    nationality: "",
    ids: {
      idType: "",
      SSS: "",
      TIN: "",
    },
    currentAddress: {
      street: "",
      city: null,
      province: null,
      zip: "",
      country: "Philippines",
    },
    work: {
      primaryOccupation: "",
      sourceOfFunds: "",
    },
  },

  modalAlert: {
    modalOpen: false,
    modalTitle: "",
    modalContent: "",
    modalSubText: "",
    modalSuccess: "",
  },

};


export const shortOccupation = {
  name:[
 "Private Employee",
	"Government Employee",
	"Self-Employed / Freelance",
	"Business Owner",
	"Overseas Filipino / Migrant Worker",
	"Student",
	"Unemployed",
	"Retiree"

],
  value:[
 "Private Employee",
	"Government Employee",
	"Self-Employed / Freelance",
	"Business Owner",
	"Overseas Filipino / Migrant Worker",
	"Student",
	"Unemployed",
	"Retiree"

]
};
export const sourceIncome = {
  name:[
    "Salary",
    "Saving/Investments",
    "Variable Income",
    "Pension",
    "Inheritance",
    "Allowance/Remittance"

],
  value:[
    "Salary",
    "Saving/Investments",
    "Variable Income",
    "Pension",
    "Inheritance",
    "Allowance/Remittance"

],
};
    export const optionCivilStatus = {
      name: ["Single", "Married", "Widowed", "Legally Separated"],
      value: ["single", "married", "widowed", "legallySeparated"],
    };
    export const optionGender = {
      name: ["Male", "Female"],
      value: ["male", "female"],
    };
    export const optionNationality = {
      name: ["Filipino", "Others"],
      value: ["Filipino", "Others"],
    };
    export const optionSelectedID = {
      name: ["TIN", "SSS/GSIS", "I don't have any of the above"],
      value: ["TIN", "SSS", "None"],
    };
