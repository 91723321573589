import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import rampverLogo from "../../contents/img/Rampver_Online_Logo-main.png";

const useStyles = makeStyles(() => ({
  success: {
    background: "#256141",
    color: "white",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
      background: "#3a9464",
    },
  },
  message: {
    fontWeight: "600",
  },
  warning: {
    background: "rgb(255,152,0)",
    color: "white",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
      background: "rgb(255,152,0)",
    },
  },
  agree: {
    background: "#256141",
    fontStyle: "Gill Sans, Poppins",
    color: "white",
    textTransform: "none",
    "&:hover": {
      background: "#23593c",
    },
  },
  cancel: {
    background: "#C8932A",
    fontStyle: "Gill Sans, Poppins",
    color: "white",
    textTransform: "none",
    "&:hover": {
      background: "rgb(140, 102, 29)",
    },
  },
  inputStyle: {},
  paperPadding: {
    padding: "20px",
  },
}));

export default function ModalAlert(props) {
  let history = useHistory();

  const agree = () => {
    history.push(props.route);
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.function}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.message}
          >
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.success} onClick={agree}>
            {!props.validate ? "Okay" : "Agree"}
          </Button>
          {!props.validate ? (
            ""
          ) : (
            <Button className={classes.warning} onClick={props.function}>
              Disagree
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ModalAlertCustom(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.function}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <div>
            <img
              style={{ marginBottom: 20 }}
              src={rampverLogo}
              width={120}
              height="auto"
              alt="rampver-logo"
            />
          </div>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{props.button}</DialogActions>
      </Dialog>
    </div>
  );
}

export function ModalAlertFunctional(props) {
  const agree = () => {
    props.executeFuncOnAgree();
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.function}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div>
            <img
              style={{ marginBottom: 20 }}
              src={rampverLogo}
              width={120}
              height="auto"
              alt="rampver-logo"
            />
          </div>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.success} onClick={agree}>
            {!props.validate ? "Okay" : "Agree"}
          </Button>
          {!props.validate ? (
            ""
          ) : (
            <Button className={classes.warning} onClick={props.function}>
              Disagree
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function RedeemModal(props) {
  const [shares, setShares] = React.useState(props.shares);

  React.useEffect(() => {
    setShares(props.shares);
  }, [props.shares]);

  const agree = () => {
    props.executeFuncOnAgree();
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.function}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        fullWidth={true}
      >
        <ValidatorForm onSubmit={agree}>
          <DialogContent>
            <div>
              <img
                src={rampverLogo}
                width={120}
                height="auto"
                alt="rampver-logo"
              />
            </div>
            <div style={{ marginBottom: 10, marginTop: 20 }}>
              Select number of shares you want to redeem.
            </div>
            <TextValidator
              name="shares"
              value={shares}
              style={classes.mt10px}
              inputProps={{ style: { padding: "12px 0px 12px 12px" } }}
              variant="outlined"
              fullWidth
              autoComplete="off"
              autoFocus={true}
              onChange={(e) => props.onChange(e.target.value)}
              validators={["required"]}
              errorMessages={["This field is required."]}
            />
          </DialogContent>
          <DialogActions>
            <Button className={classes.agree} type="submit">
              Redeem
            </Button>
            <Button className={classes.cancel} onClick={props.function}>
              Cancel
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
}

export function ModalOneButtonAgree(props) {
  const agree = () => {
    props.executeFuncOnAgree();
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.function}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ minWidth: 350 }}>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.agree} onClick={agree}>
            {"OK"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
