import React, { Component } from "react";
import logo from "../../contents/img/Rampver_Online_Logo-main.png";
import {
  Container,
  Grid,
  LinearProgress,
  Fade,
  Button,
} from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import CustomButton from "../Button";
import AlertBox from "../Alert";
import PasswordField from "../ValidatedFields/PasswordField";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Footer, { LoginRegistration, Registration } from "../Footer";

class SigninWidget extends Component {
  render() {
    const {
      email,
      password,
      showPass,
      toggleAlert,
      severity,
      messageAlert,
      loading,
      submitDisabled,
      resendPage,
    } = this.props.state;
    const props = this.props;
    const classes = {
      mt10px: { marginTop: "10px" },
      mt20px: { marginTop: "20px" },
      logoStyle: {
        height: "auto",
        width: "200px",
      },
      mt50px: { marginTop: "50px" },
      mtb30px: { marginTop: "30px", marginBottom: "30px" },
      gridLeft: {
        width: "100%",
        backgroundColor: "#fff",
      },
      gridRight: {
        width: "100%",
        backgroundColor: "#fff6e3",
      },
      gridLeftInside: {
        margin: "auto",
        width: "90%",
      },
      gridRightInside: {
        margin: "auto",
        width: "90%",
      },
    };
    return (
      <>
        <Container id="signin-container" maxWidth="sm" component="main">
          {/* <form onSubmit={props.onSubmit()}> */}
          <ValidatorForm
            onSubmit={props.onSubmit()}
            ref={(r) => (this.form = r)}
          >
            {!resendPage ? (
              <Fade in={true}>
                <Grid container spacing={2}>
                  <Grid style={classes.gridLeft} item xs={12} md={12}>
                    <Grid item style={classes.gridLeftInside} xs={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={classes.logoStyle}
                          src={logo}
                          alt="rampver-logo.png"
                        ></img>
                      </div>
                      <LinearProgress
                        style={{ display: loading ? "block" : "none" }}
                      />
                      <Grid style={classes.mt50px} item xs={12}>
                        <label className="signup-title">
                          Welcome to {process.env.REACT_APP_PROJECT_NAME}!
                        </label>
                        <p className="sub-title">
                          Don&apos;t have an account yet?&nbsp;
                          <a href={ROUTES.SIGN_UP}>Create an account now!</a>
                        </p>
                        {toggleAlert && (
                          <AlertBox
                            severity={severity}
                            message={messageAlert}
                          />
                        )}
                      </Grid>
                      <Grid style={classes.mt20px} item xs={12}>
                        <label className="dark-label">Email Address</label>
                        <TextValidator
                          name="email"
                          value={email}
                          style={classes.mt10px}
                          inputProps={{
                            style: { padding: "12px 0px 12px 12px" },
                          }}
                          variant="outlined"
                          fullWidth
                          placeholder="Enter your email"
                          autoComplete="off"
                          autoFocus={true}
                          onChange={props.onChange()}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "This field is required.",
                            "Please input a valid email address.",
                          ]}
                        />
                      </Grid>
                      <Grid style={classes.mt20px} item xs={12}>
                        <label className="dark-label">Password</label>
                        <PasswordField
                          name="password"
                          inputProps={{
                            style: { padding: "12px 0px 12px 12px" },
                          }}
                          value={password}
                          placeholder="Password"
                          autoComplete="off"
                          type={showPass ? "text" : "password"}
                          showPass={showPass}
                          iconButtonOnClick={props.onClickShowPassword()}
                          iconButtononMouseDown={props.onMouseDownPassword()}
                          onChange={props.onChange()}
                          validators={["required"]}
                          errorMessages={["This field is required."]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <p className="sub-title">
                          Forgot your Password?&nbsp;
                          <a href={ROUTES.FORGOT_PASSWORD}>Reset here!</a>
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <p className="sub-title">
                          Didn&apos;t get a verification email?&nbsp;
                          <a onClick={props.changePage()}>Click here!</a>
                        </p>
                      </Grid>
                      <Grid style={classes.mtb30px} item xs={12}>
                        <CustomButton
                          text="Log in"
                          disabled={submitDisabled}
                          type="submit"
                        />
                      </Grid>
                      <span >{LoginRegistration}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            ) : (
              <Fade in={true}>
                <Grid container spacing={2}>
                  <Grid style={classes.gridLeft} item xs={12} md={12}>
                    <Grid item style={classes.gridLeftInside} xs={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={classes.logoStyle}
                          src={logo}
                          alt="rampver-logo.png"
                        ></img>
                      </div>
                      <LinearProgress
                        style={{ display: loading ? "block" : "none" }}
                      />
                      <Grid style={classes.mt50px} item xs={12}>
                        <label className="signup-title">
                          Welcome to {process.env.REACT_APP_PROJECT_NAME}!
                        </label>
                        <br />
                        <br />
                        <Button color="primary" onClick={props.changePage()}>
                          <span
                            style={{ fontFamily: "Gill Sans, Poppins" }}
                          >{`< Return to Login`}</span>
                        </Button>
                        {toggleAlert && (
                          <AlertBox
                            severity={severity}
                            message={messageAlert}
                          />
                        )}
                      </Grid>
                      <Grid style={classes.mt20px} item xs={12}>
                        <label className="dark-label">Email Address</label>
                        <TextValidator
                          name="email"
                          value={email}
                          style={classes.mt10px}
                          inputProps={{
                            style: { padding: "12px 0px 12px 12px" },
                          }}
                          variant="outlined"
                          fullWidth
                          placeholder="Enter your email"
                          autoComplete="off"
                          autoFocus={true}
                          onChange={props.onChange()}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "This field is required.",
                            "Please input a valid email address.",
                          ]}
                        />
                      </Grid>
                      <Grid style={classes.mtb30px} item xs={12}>
                        <CustomButton
                          text="Resend Verification Email"
                          disabled={submitDisabled}
                          type="submit"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}

            {/* </form> */}
          </ValidatorForm>
        </Container>
        <Footer isSignIn={true} color={"#EEEEEE"} />
      </>
    );
  }
}

export default SigninWidget;
