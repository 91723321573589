export const getLatestApprovedTransaction = async (
  investedAmount,
  fundName,
  userID
) => {
  try {
    const transactions = await investedAmount
      .where("fundDetails.fundName", "==", fundName)
      .where("status", "==", "approved")
      .orderBy("datePlaced", "desc")
      .get();

    const userTransactions = transactions.docs.filter(
      (doc) => doc.data().clientDetails.userUid === userID
    );
    if (userTransactions.length > 0) {
      return userTransactions[0].data();
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};
