export const LANDING = "/";
export const SIGN_UP = "/signup";
export const FORGOT_PASSWORD = "/forgot-password";
export const FORGOT_PASSWORD_VERIFICATION = "/forgot-password-verification";
export const CONFIRM_NEW_PASSWORD = "/confirm-password";
export const CONFIRM_NEW_PASSWORD_VERIFICATION =
  "/confirm-password-verification";
export const SIGN_UP_LANDING = "/signup/check-email-verification";
export const SIGN_IN = "/login";
export const PRIVACY_POLICY = "/privacy-policy";
export const EMAIL_VERIFY_FAILED = "/email-verification-failed";
export const EMAIL_VERIFY_SUCCESS = "/email-verification-success";
export const EMAIL_VERIFY_HANDLER = "/email-handler";
export const LONG_KYC = "/short-application";
export const LONG_KYC_LAST = "/full-application";
export const UPDATE_KYC = "/update-kyc-application";
export const DASHBOARD = "/dashboard";
export const MY_INVESTMENTS = "/investments";
export const FUNDS = "/investments/funds";
export const MY_INVESTMENTS_CATEGORY = "/investments/category";
export const INVESTMENT_HISTORY = "/investments/history";
export const INVESTMENT_HISTORY_BY_TRANSACTION_ID = "/investments/history/:id";
export const INVESTMENT_BY_COMPANY = "/investments/company";
export const INVESTMENT_PAYMENT = "/investments/funds";
export const INVESTMENT_UPLOAD_DEPOSIT_SLIP = "/investments/history/upload";
export const ACCOUNT_PROFILE = "/account/profile";
export const DECLINED_PAGE = "/account/declined-short-application";
export const DECLINEDUPGRADE_PAGE = "/account/declined-full-application";
export const REDEMPTION_PAGE = "/redemption";
export const INVALID = "/invalid";
export const SESSION_ENDED = "/session-end";
export var FUNCTION_BASE_URL;

if (process.env.REACT_APP_FIREBASE_CONFIG === "nonprod") {
  FUNCTION_BASE_URL = "https://asia-east2-dip-nonprod.cloudfunctions.net/";
} else if (process.env.REACT_APP_FIREBASE_CONFIG === "live") {
  FUNCTION_BASE_URL = "https://asia-southeast2-rampver.cloudfunctions.net/";
} else if (process.env.REACT_APP_FIREBASE_CONFIG === "staging") {
  FUNCTION_BASE_URL = "https://asia-southeast1-dip-staging.cloudfunctions.net/";
} else {
  FUNCTION_BASE_URL = "https://asia-east2-dip-dev-882b0.cloudfunctions.net/";
}

export const TEST_SIGN_OUT = "/test-sign-out";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const FAQS = "/frequently-asked-questions";
export const NAVPS = "/net-asset-value-per-share";
export const LANDING_WITH_REFERRAL_CODE = "/ref/:referralCode";
export const FAQS_NOAUTH = "/faqs"
export const RECURRING_INVESTMENT_INFO= "/recurring-investment-information"
export const RECURRING_INVESTMENT_HISTORY= "/investments/recurring"
