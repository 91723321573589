import React, { Component } from "react";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import NavigationMenus from "../NavigationMenus";
import { AuthUserContext } from "../Session";
import {
  Breadcrumbs,
  Typography,
  Link,
  Button,
  Grid,
  Divider,
  FormControlLabel,
  FormControl,
  Tabs,
  Tab,
  Fade,
  Paper,
} from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import ModalAlert from "./ModalAlert";
import Helpers from "../Helpers";
import { TabPanel } from "./TabPanel";
import Documents from "../MyInvestments/Documents";
import PerformanceGraph from "./PerformanceGraph";
import { makeStyles } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.getContrastText(orange[900]),
    backgroundColor: "#C8932A",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    padding: "10px 45px",
    "&:hover": {
      backgroundColor: "#b8892e",
    },
  },
  returnsText: {
    fontSize: "14px",
    fontWeight: "600",
  },
  returnsAnswer: {
    fontSize: "18px",
    fontWeight: "600",
  },
}));

const FundsPage = () => {
  const classes = useStyles();
  return (
    <>
      <NavigationMenus content={<Funds classes={classes} />} />
    </>
  );
};

class FundsBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      fundDetails: [
        {
          text: "Mutual Fund Type",
          value: "",
        },
        {
          text: "Fund Manager",
          value: "",
        },
        {
          text: "Year-to-Date Return",
          value: "",
        },
        {
          text: "Risk Profile",
          value: "",
        },
        {
          text: "Currency",
          value: "Philippine Peso",
        },
        {
          text: "Minimum Initial Investment",
          value: "",
        },
        {
          text: "Minimum Additional Investment",
          value: "",
        },
        {
          text: "Recommended length of stay",
          value: "",
        },
        {
          text: "Inception Date",
          value: "",
        },
        {
          text: "Management Fee",
          value: "",
        },
        {
          text: "Entry Fee",
          value: "",
        },
        {
          text: "Holding Period",
          value: "",
        },
        {
          text: "Early Redemption Fee",
          value: "",
        },
      ],
      doneLoading: false,
      fund: [],
      hasLongKYC: null,
      modalOpen: true,
      validated: null,
      documents: null,
      performanceGraphData: [],
      ytdReturn: 0,
      oneYearReturn: 0,
      thirdYearReturn: 0,
      fifthYearReturn: 0,
    };
    this.helpers = new Helpers();
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    window.scrollTo(0, 0);

    var id = this.props.match.params.id;
    let fundDetails = this.state.fundDetails;
    let fund = [];
    const fundRef = this.props.firebase.db.collection("funds");
    const fundRatesRef = this.props.firebase.db.collection("fundRates");

    let oneYearReturn = "n/a";
    let thirdYearReturn = "n/a";
    let fifthYearReturn = "n/a";

    fundRef
      .doc(id)
      .get()
      .then((snapshot) => {
        const data = snapshot.data();

        fund.push({
          fundName: data.name,
          overView: data.overview,
          documents: data.supportingDocuments,
          riskType: data.riskType,
        });
        this.setState({ fund: fund, documents: data.supportingDocuments });
        const formattedDate = new moment(data.inceptionDate).format('MM/DD/YYYY');

        fundDetails[0].value = data.mutualFundType;
        fundDetails[1].value = data.fundManager;
        fundDetails[3].value =
          data.riskType.charAt(0).toUpperCase() + data.riskType.slice(1);
        fundDetails[5].value = this.helpers.formatToPHP(parseFloat(data.minInitialInvestment), 2);
        fundDetails[6].value = this.helpers.formatToPHP(parseFloat(data.minAdditionalInvestment), 2);
        fundDetails[7].value = data.recommendedLength;
        fundDetails[8].value = formattedDate;
        fundDetails[9].value = data.managementFee;
        fundDetails[10].value = data.entryFee;
        fundDetails[11].value = data.holdingPeriod;
        fundDetails[12].value = data.redemptionFee;
        this.getPerformanceGraph();

        let ratesPerFundName;
        fundRatesRef
          .orderBy("date", "desc")
          .limit(1)
          .get()
          .then((snapshot) => {
            const fundRates = snapshot.docs[0].data();
            ratesPerFundName = fundRates.fundDetails.find(
              (x) => x.fundName === data.name
            );

            fundDetails[2].value = isNaN(ratesPerFundName.ytd) ? 'N/A' : `${parseFloat(ratesPerFundName.ytd * 100).toFixed(2)}%`,
            fund.push({
              rate: this.helpers.formatToPHP(ratesPerFundName.rate, 4),
              rateChange: ratesPerFundName.rateChange,
              date: new Date(ratesPerFundName.date.seconds * 1000),
              ytd: isNaN(ratesPerFundName.ytd) ? 'N/A' : `${parseFloat(ratesPerFundName.ytd * 100).toFixed(2)}%`,
            });

            if ("firstYear" in data)
              oneYearReturn =
                data.firstYear === "n/a"
                  ? data.firstYear
                  : `${(data.firstYear * 100).toFixed(2)}%`;

            if ("thirdYear" in data)
              thirdYearReturn =
                data.thirdYear === "n/a"
                  ? data.thirdYear
                  : `${(data.thirdYear * 100).toFixed(2)}%`;

            if ("fifthYear" in data)
              fifthYearReturn =
                data.fifthYear === "n/a"
                  ? data.fifthYear
                  : `${(data.fifthYear * 100).toFixed(2)}%`;

            return this.setState({
              doneLoading: true,
              fundDetails: fundDetails,
              ytdReturn: fund[1].ytd,
              oneYearReturn: oneYearReturn,
              thirdYearReturn: thirdYearReturn,
              fifthYearReturn: fifthYearReturn,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toISOString = (date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0];
  };

  queryReturns = (fundRatesRef, year) => {
    return fundRatesRef
      .where("date", ">=", new Date(year.getFullYear(), 0, 1))
      .where("date", "<=", year)
      .orderBy("date", "desc")
      .limit(1)
      .get();
  };

  getReturn = (doc, fundName, currentRate) => {
    let rate = doc.fundDetails.find((x) => x.fundName === fundName);
    let rateReturn = (currentRate.rate / rate.rate - 1) * 100;
    return rateReturn.toFixed(2) + "%";
  };

  getPerformanceGraph = () => {
    let data = [];

    this.props.firebase.db
      .collection("funds")
      .doc(this.props.match.params.id)
      .collection("summary")
      .orderBy("dateCompiled", "asc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.data().months.forEach((item) => {
            if (item.week1.length > 0) {
              item.week1.forEach((item) => {
                data.push([item.date.seconds * 1000, item.rate]);
              });
            }

            if (item.week2.length > 0) {
              item.week2.forEach((item) => {
                data.push([item.date.seconds * 1000, item.rate]);
              });
            }

            if (item.week3.length > 0) {
              item.week3.forEach((item) => {
                data.push([item.date.seconds * 1000, item.rate]);
              });
            }

            if (item.week4.length > 0) {
              item.week4.forEach((item) => {
                data.push([item.date.seconds * 1000, item.rate]);
              });
            }
          });
        });
        this.setState({
          performanceGraphData: data,
        });
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });
  };

  tabChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  payment = () => {
    var fundId = this.state.fund[0].fundName;
    var uid = this.context.uid;
    var userRef = this.props.firebase.db.collection("users");
    userRef
      .doc(uid)
      .collection("KYC")
      .doc("KYC")
      .get()
      .then((result) => {
        if (result.data() !== undefined) {
          if (result.data().validated === "pending") {
            this.setState({ validated: false });
          } else {
            this.setState({ validated: true });
            userRef
              .doc(uid)
              .get()
              .then((result) => {
                if ("hasLongKYC" in result.data()) {
                  if (result.data().hasLongKYC) {
                    this.setState({
                      hasLongKYC: true,
                    });
                    this.props.history.push(
                      ROUTES.INVESTMENT_PAYMENT + "/" + fundId + "" + "/payment"
                    );
                  } else {
                    this.setState({
                      hasLongKYC: false,
                    });
                  }
                } else {
                  this.setState({
                    hasLongKYC: false,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          this.setState({
            hasLongKYC: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  modal = () => {
    this.setState({
      validated: null,
    });
  };

  disclaimer = () => {
    this.setState({ disclaimer: null });
    this.payment();
  };

  downloadFile = (doc) => {
    var storageRef = this.props.firebase.storage;
    var documentsRef = storageRef.child(doc).getDownloadURL();
    documentsRef
      .then((url) => {
        window.open(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  stringToWhiteSpaceConverter = (text) => {
    let returnText = text.replace(/\/n /gi, `\n`);
    return returnText;
  };

  render() {
    const { classes } = this.props;

    document.body.style.backgroundColor = "white";
    var modal;
    const {
      fundDetails,
      fund,
      hasLongKYC,
      modalOpen,
      validated,
      tab,
      documents,
      performanceGraphData,
      ytdReturn,
      oneYearReturn,
      thirdYearReturn,
      fifthYearReturn,
      doneLoading,
    } = this.state;
    const renderFundDetails = fundDetails.map((item, index) => (
      <tr key={index}>
        <td>{item.text}</td>
        <td>{item.value}</td>
      </tr>
    ));
    if (validated !== null && !validated) {
      modal = (
        <ModalAlert
          function={this.modal}
          open={modalOpen}
          message="Your application is still pending. Please wait for confirmation"
          route={ROUTES.DASHBOARD}
        />
      );
    }
    if (hasLongKYC !== null && !hasLongKYC) {
      modal = (
        <ModalAlert
          function={this.modal}
          open={modalOpen}
          validate={true}
          message="Oops! Before investing, you need to submit your Client Application first so we get to know you more.
          Once approved, you can already start investing!"
          route={ROUTES.LONG_KYC}
        />
      );
    }
    let navpsDate = "";
    if (fund.length !== 0 && fund.length >= 2) {
      navpsDate = `${
        fund[1].date.getMonth() + 1
      }/${fund[1].date.getDate()}/${fund[1].date.getFullYear()}`;
    }

     const tier = parseFloat(sessionStorage.getItem("tier"));

    const canInvestment = tier === 1 || (parseInt(sessionStorage.getItem("total")) >= 50000 && tier === 2);

    return (
      <>
        {modal}
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textSecondary" href={ROUTES.DASHBOARD}>
            Dashboard
          </Link>
          <Link color="textSecondary" href={ROUTES.MY_INVESTMENTS}>
            Funds
          </Link>
          <Typography color="textPrimary" aria-current="page">
            {fund.length !== 0 ? fund[0].fundName : ""}
          </Typography>
        </Breadcrumbs>
        <div className="body-content">
          <div className="funds">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <h2>{fund.length !== 0 ? fund[0].fundName : ""}</h2>
                    <span>{`For ${
                      fund.length !== 0 ? fund[0].riskType : ""
                    }-type investors.`}</span>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => this.payment()}
                        fullWidth={true}
                        disabled={canInvestment}
                      >
                        Invest Now
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={2} md={2} lg={2} xl={2}></Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Paper elevation={3} className="investment-box">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <h1>
                              {fund.length !== 0 && fund.length >= 2
                                ? fund[1].rate
                                : ""}
                            </h1>
                          }
                          label={
                            <span
                              style={{ fontWeight: "100", fontStyle: "italic" }}
                            >
                              {`NAVPS as of ${navpsDate}`}
                            </span>
                          }
                          labelPlacement="bottom"
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Paper elevation={3} className="investment-box">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <h1>
                              {fund.length !== 0 && fund.length >= 2
                                ? fund[1].ytd
                                : ""}
                            </h1>
                          }
                          label={
                            <span
                              style={{ fontWeight: "100", fontStyle: "italic" }}
                            >
                              {`Year-to-Date Return`}
                            </span>
                          }
                          labelPlacement="bottom"
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2} xl={2}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}></Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Paper elevation={3} className="graphs">
                      <PerformanceGraph
                        title="Fund Graph"
                        firebase={this.props.firebase}
                        data={performanceGraphData}
                        type="stockChart"
                        doneLoading={doneLoading}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}></Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Paper elevation={3} style={{ textAlign: "center" }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                          <span className={classes.returnsText}>
                            YTD Return:{" "}
                          </span>
                          <br />
                          <br />
                          <span className={classes.returnsAnswer}>
                            {ytdReturn}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                          <span className={classes.returnsText}>
                            1-Year Return:{" "}
                          </span>
                          <br />
                          <br />
                          <span className={classes.returnsAnswer}>
                            {oneYearReturn}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                          <span className={classes.returnsText}>
                            3-Year Return:{" "}
                          </span>
                          <br />
                          <br />
                          <span className={classes.returnsAnswer}>
                            {thirdYearReturn}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                          <span className={classes.returnsText}>
                            5-Year Return:{" "}
                          </span>
                          <br />
                          <br />
                          <span className={classes.returnsAnswer}>
                            {fifthYearReturn}
                          </span>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}></Grid>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <br />
            <Divider />
            <br />
            <Tabs
              value={tab}
              indicatorColor="secondary"
              textColor="secondary"
              onChange={this.tabChange}
              className="custom-tabs"
            >
              <Tab label="Fund Overview" />
              <Tab label="Documents" />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Fade in={true}>
                <div className="fund-preview">
                  <Paper elevation={3} style={{ padding: "20px" }}>
                    {fund.length !== 0 ? (
                      <span style={{ "white-space": "pre-line" }}>
                        {this.stringToWhiteSpaceConverter(fund[0].overView)}
                      </span>
                    ) : (
                      ""
                    )}
                  </Paper>
                  <br /> <br />
                  <Divider />
                  <br />
                  <Paper elevation={3} style={{ padding: "20px" }}>
                    <h3>Fund Details</h3>
                    <table className="tbl-border-bottom">
                      <tbody>{renderFundDetails}</tbody>
                    </table>
                  </Paper>
                </div>
              </Fade>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Paper elevation={3} style={{ padding: "20px" }}>
                <h3>Fund Documents</h3>
                <span>Documents related to the fund can be found here.</span>
                <Documents docs={documents} downloadFile={this.downloadFile} />
              </Paper>
            </TabPanel>
          </div>
        </div>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const Funds = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(FundsBase);

export default FundsPage;

export { Funds };
