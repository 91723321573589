import React from "react";
import { Container } from "@material-ui/core";
import moment from "moment";

import Accordion from "./Accordion";
import conservativeLogo from "../../images/conservative.png";
import moderateLogo from "../../images/moderate.png";
import aggressiveLogo from "../../images/aggressive.png";
import CustomButton from "../Button";
import { Download } from "./Export";

function MyApplication(props) {
  const { my_application, risk_profile, account_details, mobile_number } =
    props;

 const birthDate = moment(my_application.dateOfBirth).format("LL");

  //var incomeSelection;
  var riskProfile = "N/A";
  var savings;
  var firstName =
    my_application.name.firstName !== ""
      ? my_application.name.firstName
      : "N/A";
  var middleName =
    my_application.name.middleName !== ""
      ? my_application.name.middleName
      : "N/A";
  var lastName =
    my_application.name.lastName !== "" ? my_application.name.lastName : "N/A";
  var suffix =
    my_application.name.suffix !== "" ? my_application.name.suffix : "N/A";
  var email = my_application.email !== "" ? my_application.email : "N/A";
  var civil =
    my_application.civil.civilStatus !== ""
      ? my_application.civil.civilStatus
      : "N/A";
  var SSS = my_application.ids.SSS !== "" ? my_application.ids.SSS : "N/A";
  var TIN = my_application.ids.TIN !== "" ? my_application.ids.TIN : "N/A";
  var gender = my_application.gender !== "" ? my_application.gender : "N/A";
  var mobileNumber = mobile_number !== "" ? mobile_number : "N/A";
  var dateOfBirth =
    my_application.dateOfBirth !== ""
      ? birthDate
      : "N/A";
  var nationality =
    my_application.nationality !== "" ? my_application.nationality : "N/A";
  var placeOfBirth =
    my_application.placeOfBirth !== "" ? my_application.placeOfBirth : "N/A";
  var curCountry =
    my_application.currentAddress.country !== ""
      ? my_application.currentAddress.country
      : "N/A";
  var curProv =
    my_application.currentAddress.province !== ""
      ? my_application.currentAddress.province
      : "N/A";
  var curCity =
    my_application.currentAddress.city !== ""
      ? my_application.currentAddress.city
      : "N/A";
  // var curBrgy =
  //   my_application.currentAddress.barangay !== ""
  //     ? my_application.currentAddress.barangay
  //     : "N/A";
  var curStreet =
    my_application.currentAddress.street !== ""
      ? my_application.currentAddress.street
      : "N/A";
  var curZip =
    my_application.currentAddress.zip !== ""
      ? my_application.currentAddress.zip
      : "N/A";
  var permCountry =
    my_application.permanentAddress.country !== ""
      ? my_application.permanentAddress.country
      : "N/A";
  var permProv =
    my_application.permanentAddress.province !== ""
      ? my_application.permanentAddress.province
      : "N/A";
  var permCity =
    my_application.permanentAddress.city !== ""
      ? my_application.permanentAddress.city
      : "N/A";
  // var permBrgy =
  //   my_application.permanentAddress.barangay !== ""
  //     ? my_application.permanentAddress.barangay
  //     : "N/A";
  var permZip =
    my_application.permanentAddress.zip !== ""
      ? my_application.permanentAddress.zip
      : "N/A";
  var permStreet =
    my_application.permanentAddress.street !== ""
      ? my_application.permanentAddress.street
      : "N/A";
  var busCountry =
    my_application.businessAddress.country !== ""
      ? my_application.businessAddress.country
      : "N/A";
  var busProv =
    my_application.businessAddress.province !== null
      ? my_application.businessAddress.province
      : "N/A";
  var busCity =
    my_application.businessAddress.city !== null
      ? my_application.businessAddress.city
      : "N/A";
  // var busBrgy =
  //   my_application.businessAddress.barangay !== ""
  //     ? my_application.businessAddress.barangay
  //     : "N/A";
  var busZip =
    my_application.businessAddress.zip !== ""
      ? my_application.businessAddress.zip
      : "N/A";
  var busStreet =
    my_application.businessAddress.street !== ""
      ? my_application.businessAddress.street
      : "N/A";
  var primaryOcc =
    my_application.work.primaryOccupation !== ""
      ? my_application.work.primaryOccupation
      : "N/A";
  var nameOfEmployer =
    my_application.work.nameOfEmployer !== ""
      ? my_application.work.nameOfEmployer
      : "N/A";
  var natureOfBusiness =
    my_application.work.businessNature !== ""
      ? my_application.work.businessNature
      : "N/A";
  var officeTel =
    my_application.work.businessNum !== ""
      ? my_application.work.businessNum
      : "N/A";
  // var annualIncome =
  //     my_application.work.annualIncomeAmount !== ""
  //         ? my_application.work.annualIncomeAmount
  //         : "N/A";
  var annualIncome;
  switch (my_application.work.annualIncomeAmount) {
    case 1:
      annualIncome = "PHP 50,000 or less";
      break;
    case 2:
      annualIncome = "PHP 50,001 to PHP 100,000";
      break;
    case 3:
      annualIncome = "PHP 100,001 to PHP 500,000";
      break;
    case 4:
      annualIncome = "PHP 500,001 to PHP 1,000,000";
      break;
    case 5:
      annualIncome = "PHP 1,000,001 - PHP 5,000,000";
      break;
    case 6:
      annualIncome = "5,000,001 - PHP 10,000,000";
      break;
    case 7:
      annualIncome = "PHP 10,000,001 or more";
      break;
    default:
      annualIncome = "n/a";
      break;
  }

  var sourceFunds =
    my_application.work.sourceOfFunds !== ""
      ? my_application.work.sourceOfFunds
      : "N/A";
  
  var governmentWork = my_application.PEP.governmentWork ? "Yes" : "No";
  var governmentRelative = my_application.PEP.governmentRelative ? "Yes" : "No";
  var usResident = my_application.FATCA.usResident ? "Yes" : "No";
  var usPhone = my_application.FATCA.usPhone ? "Yes" : "No";
  var usPOA = my_application.FATCA.usPOA ? "Yes" : "No";
  var usCitizen = my_application.FATCA.usCitizen ? "Yes" : "No";
  var usCareOfAddress = my_application.FATCA.usCareOfAddress ? "Yes" : "No";
  var usBorn = my_application.FATCA.usBorn ? "Yes" : "No";
  var usAlien = my_application.FATCA.usAlien ? "Yes" : "No";
  var usAddressResidence = my_application.FATCA.usAddressResidence
    ? "Yes"
    : "No";
  var usAddressMailing = my_application.FATCA.usAddressMailing ? "Yes" : "No";
  var usAccount = my_application.FATCA.usAccount ? "Yes" : "No";
  var accName =
    account_details.accountName !== "" ? account_details.accountName : "N/A";
  var bankAccNo =
    account_details.bankAccountNumber !== ""
      ? account_details.bankAccountNumber
      : "N/A";
  var bankName =
    account_details.bankName !== "" ? account_details.bankName : "N/A";
  if (account_details.savingsAccount === "N/A")
    savings = account_details.savingsAccount;
  else
    savings = account_details.savingsAccount
      ? "Savings Account"
      : "Current Account";

  if (risk_profile === "moderate") {
    riskProfile = (
      <div className="risk-app moderate">
        <img className="iconSize" src={moderateLogo} alt="moderate" />
        <span>
          <h4>Moderate</h4>
          You are ready to invest and are currently looking at a long-term
          investing horizon.
        </span>
      </div>
    );
  } else if (risk_profile === "conservative") {
    riskProfile = (
      <div className="risk-app conservative">
        <img className="iconSize" src={conservativeLogo} alt="conservative" />
        <span>
          <h4>Conservative</h4>
          You are an investor with a low appetite for risk and you’re amenable
          to modest returns.
        </span>
      </div>
    );
  } else if (risk_profile === "aggressive") {
    riskProfile = (
      <div className="risk-app aggressive">
        <img className="iconSize" src={aggressiveLogo} alt="aggressive" />
        <span>
          <h4>Aggresive</h4>
          You have a high appetite for risk and willing to invest for the
          long-term.
        </span>
      </div>
    );
  }
  // if (my_application.annualIncome.annualIncome1) {
  //     incomeSelection = "Less than 250,000.00";
  // } else if (my_application.annualIncome.annualIncome2) {
  //     incomeSelection = "250,000.00 to 500,000.00";
  // } else if (my_application.annualIncome.annualIncome3) {
  //     incomeSelection = "500,000.00 to less than 1,000,000.00";
  // } else if (my_application.annualIncome.annualIncome4) {
  //     incomeSelection = "Greater than 1,000,000.00";
  // } else {
  //     incomeSelection = "N/A";
  // }
  var dataApplication = [
    {
      title: "Personal Information",
      table: [
        {
          title: "First Name",
          value: firstName,
        },
        {
          title: "Middle Name",
          value: middleName,
        },
        {
          title: "Last Name",
          value: lastName,
        },
        {
          title: "Suffix",
          value: suffix,
        },
        {
          title: "Email Address",
          value: email,
        },
        {
          title: "Mobile Number",
          value: mobileNumber,
        },
        {
          title: "Gender",
          value: gender,
        },
        {
          title: "Civil Status",
          value: civil,
        },
        {
          title: "Date of Birth",
          value: dateOfBirth,
        },
        {
          title: "Place of Birth",
          value: placeOfBirth,
        },
        {
          title: "Nationality",
          value: nationality,
        },
        {
          title: "SSS/GSIS",
          value: SSS,
        },
        {
          title: "TIN",
          value: TIN,
        },
      ],
    },
    {
      title: "Address Details",
      table: [
        {
          title: "Current Address",
          header: 1,
        },
        {
          title: "Country",
          value: curCountry,
        },
        {
          title: "Province",
          value: curProv,
        },
        {
          title: "City",
          value: curCity,
        },
        {
          title: "Street",
          value: curStreet,
        },
        {
          title: "Zip Code",
          value: curZip,
        },
        {
          title: "Permanent Address",
          header: 1,
        },
        {
          title: "Country",
          value: permCountry,
        },
        {
          title: "Province",
          value: permProv,
        },
        {
          title: "City",
          value: permCity,
        },
        {
          title: "Street",
          value: permStreet,
        },
        {
          title: "Zip Code",
          value: permZip,
        },
        {
          title: "Work or Business Address",
          header: 1,
        },
        {
          title: "Country",
          value: busCountry,
        },
        {
          title: "Province",
          value: busProv,
        },
        {
          title: "City",
          value: busCity,
        },
        {
          title: "Street",
          value: busStreet,
        },
        {
          title: "Zip Code",
          value: busZip,
        },
      ],
    },
    {
      title: "Professional Details",
      table: [
        {
          title: "Primary Occupation",
          value: primaryOcc,
        },
        {
          title: "Name of Employer",
          value: nameOfEmployer,
        },
        {
          title: "Nature of Business",
          value: natureOfBusiness,
        },
        {
          title: "Office Tel. No.",
          value: officeTel,
        },
        {
          title: "Source Of Funds",
          value: sourceFunds,
        },
   
      ],
    },
    {
      title: "Financial Profile",
      table: [
        {
          title: "Annual Income",
          value: annualIncome,
        },

      ],
    },
    {
      title: "PEP Declaration",
      table: [
        {
          title: "Have You Work In A Government Agency Or Insitution?",
          value: governmentWork,
        },
        {
          title:
            "Do You Have Any Relative Who Is / Was An Elected Governemnt Offical Or Who Is/Was An Appointed Government Offical?",
          value: governmentRelative,
        },
      ],
    },
    {
      title: "Risk Profile",
      table: [
        {
          title: riskProfile,
          header: 1,
        },
      ],
    },
    {
      title: "FATCA",
      table: [
        {
          title: "Are You A US Citizen?",
          value: usCitizen,
        },

        {
          title: "US Resident?",
          value: usResident,
        },
        {
          title: "US Resident Alien?",
          value: usAlien,
        },
        {
          title: "Do You Have A Current US Telephone Number?",
          value: usPhone,
        },
        {
          title: "Where You Born In US?",
          value: usBorn,
        },
        {
          title: "Do You Have A Current US Residence Address?",
          value: usAddressResidence,
        },
        {
          title: "Do You Have A Current US Mailing Address? (Including US P.O)",
          value: usAddressMailing,
        },
        {
          title:
            "Have You Issued A Standing Instruction To Transferfunds To An Account",
          value: usAccount,
        },
        {
          title:
            "Have You Issued A Power Of Attorney Or Granted Signatory Authority To A Person With US Address?",
          value: usPOA,
        },
        {
          title:
            "Do You Have “In Care Of” Address Or “Hold Mail” Address That Is The Sole Address For An Account (Whether Such Address In The US Or Outside The US) ?",
          value: usCareOfAddress,
        },
      ],
    },
    {
      title: "Settlement Details",
      table: [
        {
          title: "Account Name",
          value: accName,
        },
        {
          title: "Name Of Bank",
          value: bankName,
        },
        {
          title: "Bank Account Number",
          value: bankAccNo,
        },
        {
          title: "Current Or Savings Account",
          value: savings,
        },
      ],
    },
  ];
  return (
    <>
      <Container>
        <h1>My Application</h1>
        {dataApplication.map((data, index) => (
          <Accordion
            title={data.title}
            table={data.table}
            index={index}
            key={index}
            defaultExpanded={false}
          />
        ))}
        <br />
        <Download
          data={props}
          button={
            <CustomButton
              text="Download Details"
              buttonTheme="secondary"
              className="float-right"
            />
          }
          filename={`My-KYC-Details`}
        />
      </Container>
    </>
  );
}

export default MyApplication;
