import React, { Component } from "react";
import { Container, Grid, LinearProgress } from "@material-ui/core";
import CustomButton from "../Button";
import AlertBox from "../Alert";
import * as ROUTES from "../../constants/route";
import { ValidatorForm } from "react-material-ui-form-validator";
import PasswordField from "../ValidatedFields/PasswordField";
import Validators from "../Helpers/validators";

class ConfirmNewPasswordWidget extends Component {
  constructor(props) {
    super(props);
    this.validators = new Validators();
  }

  componentDidMount() {
    this.validators.mount();
  }

  componentWillUnmount() {
    this.validators.unmount();
  }

  handleChange = (event) => {
    this.props.onPasswordChange(event);
    if (event.target.name === "password") {
      this.form.isFormValid(false);
    }
  };

  render() {
    const {
      password,
      showPass,
      toggleAlert,
      severity,
      messageAlert,
      loading,
      submitDisabled,
    } = this.props.state;
    var props = this.props;

    return (
      <>
        <LinearProgress style={{ display: loading ? "block" : "none" }} />
        <Container component="main" maxWidth="xs">
          <div className="sign-paper">
            <ValidatorForm
              onSubmit={props.onSubmit()}
              ref={(r) => (this.form = r)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <label className="signup-title">Reset Password</label>
                  <p className="sub-title">
                    Remembered it?&nbsp;
                    <a href={ROUTES.SIGN_IN}>Return to log in here!</a>
                  </p>
                  {toggleAlert && (
                    <AlertBox severity={severity} message={messageAlert} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label className="dark-label">
                    Enter your new password here
                  </label>
                  <PasswordField
                    name="password"
                    inputProps={{ style: { padding: "12px 0px 12px 12px" } }}
                    value={password}
                    placeholder="Password"
                    autoComplete="off"
                    type={showPass ? "text" : "password"}
                    showPass={showPass}
                    iconButtonOnClick={props.onClickShowPassword()}
                    iconButtononMouseDown={props.onMouseDownPassword()}
                    onChange={this.handleChange}
                    onCut={props.onCopyPasteCut()}
                    onCopy={props.onCopyPasteCut()}
                    onPaste={props.onCopyPasteCut()}
                    validators={["required", "isValidPassword"]}
                    errorMessages={[
                      "This field is required.",
                      "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomButton
                    text="Set New Password"
                    disabled={submitDisabled}
                    type="submit"
                  />
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>
        </Container>
      </>
    );
  }
}

export default ConfirmNewPasswordWidget;
