import React from "react";
import {
  FormControl,
  OutlinedInput,
  Fade,
  FormHelperText,
} from "@material-ui/core";
import { ValidatorComponent } from "react-material-ui-form-validator";

class PasswordField extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      value,
      name,
      inputProps,
      placeholder,
      autoComplete,
      type,
      onChange,
      onCut,
      onCopy,
      onPaste,
    } = this.props;
    return (
      <div>
        <FormControl fullWidth>
          <OutlinedInput
            name={name}
            value={value}
            inputProps={inputProps}
            placeholder={placeholder}
            autoComplete={autoComplete}
            type={type}
            error={this.errorText() !== null ? true : false}
            // endAdornment={
            //   <InputAdornment position="end">
            //     <IconButton
            //       aria-label="toggle password visibility"
            //       edge="end"
            //       onClick={iconButtonOnClick}
            //       onMouseDown={iconButtononMouseDown}
            //     >
            //       {showPass ? <Visibility /> : <VisibilityOff />}
            //     </IconButton>
            //   </InputAdornment>
            // }
            onChange={onChange}
            onCut={onCut}
            onCopy={onCopy}
            onPaste={onPaste}
          />
        </FormControl>
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <Fade in={true}>
        <FormHelperText style={{ color: "red" }}>
          {this.getErrorMessage()}
        </FormHelperText>
      </Fade>
    );
  }
}

export default PasswordField;
