import React, { Component } from "react";
import ConfirmPasswordWidget from "./ConfirmNewPasswordWidget";
import * as ROUTES from "../../constants/route";
import Validators from "../Helpers/validators";

const INITIAL_STATE = {
  password: "",
  showPass: false,
  isValid: false,
  loaded: false,
  submitDisabled: false,
  toggleAlert: false,
  severity: "warning",
  messageAlert: "",
  errors: null,
  email:"",
};

class ConfirmNewPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.fb = props.props.firebase;
    this.validators = new Validators();
    this.code = new URLSearchParams();
  }

  componentDidMount() {
    this.verifyCodeAndRetrieveEmail(this.props.props.match.params.code);
  }

  verifyCodeAndRetrieveEmail = (code) => {
    this.fb
      .doVerifyPasswordResetCode(code)
      .then((email) => {
        console.log(email)
        this.setState({ email });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          submitDisabled: false,
          toggleAlert: true,
          severity: "error",
          messageAlert: "Invalid or expired reset code.",
        });
        console.error("Invalid or expired reset code:", error);
      });
  };

  onConfirmPassword(code, password) {
    let promise = new Promise((resolve, reject) => {
      this.fb
        .doConfirmPasswordReset(code, password)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  onPasswordChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validators.changePasswordValue(this.state.password);
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, submitDisabled: true });
    const { password } = this.state;

    if (password !== null) {
      this.onConfirmPassword(this.props.props.match.params.code, password)
        .then(() => {
          this.setState({
            loading: false,
            submitDisabled: false,
          });
        this.props.props.history.push({
          pathname: ROUTES.CONFIRM_NEW_PASSWORD_VERIFICATION,
          state: { email: this.state.email },
        });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            submitDisabled: false,
            toggleAlert: true,
            severity: "error",
            messageAlert: error.message,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        submitDisabled: false,
        toggleAlert: true,
        severity: "error",
        messageAlert: "Invalid Data.",
      });
      console.log("Invalid Data");
    }
  };

  onCopyPasteCut = (event) => {
    event.preventDefault();
  };

  onClickShowPassword = () => {
    this.setState({ showPass: true });
  };

  onMouseDownPassword = () => {
    this.setState({ showPass: false });
  };

  render() {
    console.log(this.props);
    return (
      <ConfirmPasswordWidget
        state={this.state}
        onSubmit={() => this.onSubmit.bind(this)}
        onCopyPasteCut={() => this.onCopyPasteCut.bind(this)}
        onClickShowPassword={() => this.onClickShowPassword.bind(this)}
        onMouseDownPassword={() => this.onMouseDownPassword.bind(this)}
        onPasswordChange={this.onPasswordChange}
        firebase={this.props.props.firebase}
      />
    );
  }
}

export default ConfirmNewPasswordContainer;
