import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { breadcrumb } from "./RecurringHelper";


 const FAQsData = ({ close }) => {
  const accordionData = [
    {
      title: "Why should I register to a monthly recurring investment?",
      content: (
        <>
          A monthly recurring investment through Rampver Online will allow you
          to practice disciplined investing and peso-cost averaging. This means
          that you are investing regularly, thus your investment is less
          affected by market timing.
          <p />
          All you need to do is register to a monthly recurring investment once
          and we will book your investments for you on a monthly basis.
        </>
      ),
    },
    {
      title:
        "How is this different from investing via Unionbank, GCash, or InstaPay?",
      content: (
        <>
          Payment through Unionbank, GCash, or InstaPay are for one-time
          investments only. If you want to invest every month through these
          means, you will need to submit investment transactions every month.
          Registering to a monthly recurring investment makes this process
          automatic.
          <p />
          Currently, monthly recurring investments are only available through
          BPI’s Auto-debit Arrangement.
        </>
      ),
    },
    {
      title: "Can I register multiple monthly recurring investments?",
      content: (
        <>
          Yes, you can! We recommend registering multiple monthly recurring
          investments for the funds you want to regularly invest in and grow
          over time.
        </>
      ),
    },
    {
      title: "How do I confirm if my monthly recurring investments are booked?",
      content: (
        <>
          You will receive a confirmation email and notification for all of your
          investment transactions. For monthly recurring investments, you will
          receive a confirmation email and notification every month.
        </>
      ),
    },
    {
      title:
        "Will I be able to apply for the monthly recurring investment in Rampver Online even if I don’t have a BPI Account?",
      content: (
        <>
          Unfortunately no. As for now, only BPI account users will be able to
          apply for the monthly recurring investment feature. With our
          commitment in improving the platform, this feature will be made
          available soon to other bank users as well.
        </>
      ),
    },
  ];

  return (
    <>
      {breadcrumb}
      <br />
      <span className=" font-poppins text-xl lg:text-4xl font-bold ">
        Frequently Asked Questions (FAQs):
      </span>
      <div className="relative grid lg:bottom-10 lg:mr-4 my-5 lg:my-0 lg:justify-items-end ">
        <Button
          variant="contained"
          color="secondary"
          className=" w-full  2xl:w-56 xl:w-60 lg:w-40 "
          style={{ border: "2px solid #246040" }}
          onClick={close}
        >
          <div className="text-white text-base font-bold">Go Back</div>
        </Button>
      </div>
       <div
      
        className=" mt-0 lg:ml-8 lg:mr-4 lg:mb-8"
      >
        {accordionData.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                border: "solid 0.15rem",
                borderColor: "#C4C4C4",
              }}
              className=" font-poppins font-medium text-base"
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`FAQs-${index}`}
                >
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: "1.25rem",
                      padding: "10px",
                    }}
                  >
                    {item.title}
                  </Typography>
                </AccordionSummary>

                <div className="relative mx-5 h-1 my-1 bg-gray-200 bottom-3">
                  <Divider />
                </div>
                <AccordionDetails>
                  <span className=" text-lg px-3 pb-8">{item.content}</span>
                </AccordionDetails>

              </Accordion>
            </div>
          );
        })}
      </div>
      <div className="text-black text-base font-semibold text-center">
        If you have any further questions, email us at{" "}
        <a
          href="mailto:online@rampver.com"
          className=" text-green-800 underline"
        >
          online@rampver.com
        </a>
      </div>

   
     

    </>
  );
};
export default FAQsData;
