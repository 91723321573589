import React from "react";
import logo from "../../contents/img/Rampver_Online_Logo-main.png";
import logoInverted from "../../contents/img/rampver-logo-inverted.png";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import * as ROUTES from "../../constants/route";
import { AuthUserContext } from "../Session";

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: "#000",
  },
  navBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  brand: {
    maxWidth: "120px",
  },
  navBarTransparent: {
    backgroundColor: "transparent",
    boxShadow: "none",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const NavBar = ({ transparent }) => {
  const classes = useStyle();

  return (
    <AuthUserContext.Consumer>
      {() => (
        <AppBar
          className={transparent ? classes.navBarTransparent : classes.navBar}
          position="static"
        >
          <Container>
            <Toolbar>
              <a href={ROUTES.LANDING}>
                <img
                  className={classes.brand}
                  src={transparent ? logoInverted : logo}
                  alt="rampver-logo"
                ></img>
              </a>
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </AuthUserContext.Consumer>
  );
};

export default NavBar;
