import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import Slide from "@material-ui/core/Slide";
import { Grid } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExpiryPopup(props) {
  let secondsDisplay =
    props.time < 1
      ? 0
      : Math.floor(props.time % 60)
          .toString()
          .padStart(2, "0");

  return (
    <div>
      <Dialog
        open={props.popupOpen}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        aria-labelledby="expiry-dialog"
        aria-describedby="expiry-dialog-description"
      >
        <div style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ padding: "10px", textAlign: "center" }}>
              <span style={{ fontSize: "18px" }}>
                For your security, you will be logged out of Rampver Online
                after 15 minutes of inactivity.
              </span>
            </Grid>
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid item xs={12} style={{ padding: "10px", textAlign: "center" }}>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "800",
                  color: "#c8932a",
                }}
              >
                Your session will end in 0:{secondsDisplay}
              </span>
            </Grid>
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid item xs={6} style={{ padding: "10px", textAlign: "center" }}>
              <Button
                style={{ width: "100%", padding: "10px" }}
                color="secondary"
                variant="outlined"
                onClick={props.signOut()}
              >
                Log Out
              </Button>
            </Grid>
            <Grid item xs={6} style={{ padding: "10px", textAlign: "center" }}>
              <Button
                onClick={props.continue()}
                variant="contained"
                color="secondary"
                style={{ color: "white", width: "100%", padding: "10px" }}
              >
                Continue Session
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
