import React, { useState, useEffect } from "react";
import { Typography, Container, Grid, Hidden } from "@material-ui/core";
import LandingLayout from "../LandingLayout";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";

import { useCookies } from "react-cookie";
import WebImg from "../../assets/maintenance/Web_600x500px.png";
import MobImg from "../../assets/maintenance/Mob_375x360px.png";

import * as ROUTES from "../../constants/route";
import moment from "moment";

const MaintenancePage = (props) => {
  const [typedPhrase, setTypedPhrase] = useState("");
  const [passphrase, setPassphrase] = useState(null);

  const [cookies, setCookie] = useCookies(["token"]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const maintenanceListener = props.firebase.db
      .collection("miscellaneous")
      .doc("maintenance")
      .onSnapshot((doc) => {
        setStartDate(moment(doc.data().startDate.toDate()));
        setEndDate(moment(doc.data().endDate.toDate()));
        setPassphrase(doc.data().passphrase);
      });
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      maintenanceListener();
    };
  }, []);

  const handleKeyPress = (event) => {
    if (/^[a-zA-Z0-9\s]$/.test(event.key)) {
      setTypedPhrase((prevPhrase) => prevPhrase + event.key);
    }
  };

  useEffect(() => {
    if (!passphrase) return;

    if (typedPhrase.toLowerCase().includes(passphrase)) {
      const email = prompt("Enter email:");
      const password = prompt("Enter password:");

      if (!email || !password) {
        return;
      }

      props.firebase
        .doSigninWithEmailAndPassword(email, password)
        .then((res) => {
          if (res) {
            return props.firebase.getCustomClaims();
          }
        })
        .then((res) => {
          if (
            res.claims.role === "client" &&
            res.claims?.dev &&
            res.claims.email_verified
          ) {
            setCookie("token", res.token, { path: "/" });
            props.history.push(ROUTES.DASHBOARD);
          } else {
            props.firebase.doSignOut();
          }
        })
        .catch((err) => {
          setTypedPhrase("");
          props.firebase.doSignOut();
        });
    }
  }, [typedPhrase]);

  return (
    <LandingLayout>
      <Container maxWidth={"xl"} style={{ backgroundColor: "white" }}>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          style={{ paddingTop: "8rem" }}
        >
          <Grid item xs={12} xl={6}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h2"
                align="center"
                color="secondary"
                style={{ paddingBottom: "2rem" }}
              >
                Rampver Online will be back soon!
              </Typography>
              <Typography variant="h6" align="center">
                We are currently working on improving the overall client
                experience and releasing new features on Rampver Online.
              </Typography>
              <Typography
                variant="h6"
                align="center"
                style={{ padding: "1rem" }}
              >
                {`We will be back at approximately ${
                  endDate && endDate.format("h:mmA MMMM Do, YYYY")
                } (Philippine Standard Time).`}
              </Typography>
              <Typography
                variant="h6"
                align="center"
                style={{ padding: "1rem" }}
              >
                We apologize for the inconvenience and appreciate your patience.
                For any urgent concerns, please email <b>online@rampver.com</b>
              </Typography>
              <Typography
                variant="h6"
                align="center"
                style={{ padding: "1rem" }}
              >
                Thank you for using Rampver Online to simplify your journey to
                wealth creation!
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Hidden xsDown implementation="css">
              <img src={WebImg} alt="maintenance-img" />
            </Hidden>
            <Hidden smUp implementation="css">
              <img src={MobImg} alt="maintenance-img" />
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </LandingLayout>
  );
};

export default compose(withFirebase)(MaintenancePage);
