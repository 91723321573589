import {
  Breadcrumbs,
  Typography,
  Grid,
  IconButton, 
  Tooltip
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";

import * as ROUTES from "../../../constants/route";
import RecurringLogo from "../../../assets/recurringPage/Rampver_Online_Logo.png";

export const RecurringLandingPage = ({ children }) => (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "15px 20px 0px 20px",
            padding: "0px 30px 0px 30px",
        }}
    >
        <span className="text-xl font-poppins font-medium text-center">
            {children}
        </span>
    </div>
);

export const formatNumber = (num) => {
  return (num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export  const breadcrumb = (
  <Breadcrumbs aria-label="breadcrumb">
    <Link
      style={{ color: "inherit", textDecoration: "none" }}
      to={ROUTES.DASHBOARD}
    >
      Dashboard
    </Link>
    <Typography color="textPrimary" aria-current="page">
      Recurring Investment
    </Typography>
  </Breadcrumbs>
);


export function SubmitModalDialogs() {

  return (
    <div className=" my-20 lg:m-28 text-center font-poppins">
      <img
        src={RecurringLogo}
        alt="rampver-logo"
      />
      <p className="text-2xl lg:text-3xl font-bold">
        Thank you for registering your Monthly Recurring Investment
      </p>
      <p className="px-5 lg:px-36 text-base ">
        We will verify your BPI eADA registration and start booking your monthly
        recurring investment by next month. We will email you with the complete
        investment details.
        <p /> For any questions or concerns, please email us at{" "}
        <a
          href="mailto:online@rampver.com"
          className="text-blue-500 hover:underline"
        >
          online@rampver.com
        </a>
      </p>
    </div>
  );
}
export const header = (
  <>
    {breadcrumb}
    <br />
    <Grid container>
      <Grid lg={10}>
        <span className=" font-poppins text-xl lg:text-4xl  font-bold ">
          Monthly Recurring Investment (BETA)
        </span>
      </Grid>
    </Grid>
  </>
);

export const copyUserId = (acctNum) => {
  const [copied, setCopied] = useState(false);
    const handleCopy = () => {
    copy(acctNum);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };
  return (
    <span>
    <Tooltip title={copied ? "Copied!" : "Copy to Clipboard"}>
      <IconButton color="secondary" onClick={handleCopy}>
        <FileCopyIcon /> &nbsp;
        <u className=" font-normal font-poppins text-base text-black cursor-pointer">
          Copy Reference Number
        </u>
      </IconButton>
    </Tooltip>
  </span>

  )
  
}






