import React, { Component } from "react";
import SigninContainer from "./signinContainer";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import withoutAuthorization from "../Session/withoutAuthorization";
import bgImage from "../../contents/img/rampver-bg-lines.png";

const SignInPage = () => {
  return <SignInForm />;
};

class SignInPageBase extends Component {
  componentDidMount() {
    const bgcolor = "#256141";
    const bodyElt = document.querySelector("body");
    bodyElt.style.backgroundColor = bgcolor;
    bodyElt.style.backgroundImage = `url(${bgImage})`;
  }

  render() {
    return <SigninContainer props={this.props} />;
  }
}

const condition = (authUser) => !!authUser;

const SignInForm = compose(
  // withoutAuthorization(condition),
  withRouter,
  withFirebase
)(SignInPageBase);

export default SignInPage;

export { SignInForm };
