export const INITIAL_STATE = {
  FATCA: {
    usPerson: "",
    usCitizen: "",
    usResident: "",
    usAlien: "",
    usPhone: "",
    usBorn: "",
    usAddressResidence: "",
    usAddressMailing: "",
    usAccount: "",
    usPOA: "",
    usCareOfAddress: "",
  },
  PEPDeclaration: {
    governmentWork: "",
    governmentRelative: "",
    banks: "",
  },
  signature:{
    signatureUrl:null
  },
  images: {
    oldIdPhotoFront: null,
    oldIdPhotoBack: null,
    oldPEPDeclaration: null,
    idPhotoFront: null,
    idPhotoBack: null,
    idPEPDeclaration: null,
    idPhotoFrontLabel: "",
    idPhotoBackLabel: "",
    idPEPDeclarationLabel: "",
    idPhotoFrontPreview: null,
    idPhotoBackPreview: null,
    idPEPDeclarationPreview: null,
  },
  additionalInfo: {
    existingID: false,
    isPrimaryId: false,
    ids: {
      idType: "",
      SSS: "",
      TIN: "",
    },
    businessAddress: {
      street: "",
      city: null,
      province: null,
      zip: "",
      country: "Philippines",
    },
    work: {
      primaryOccupation: "",
      nameOfEmployer: "",
      businessNature: "",
      annualIncomeAmount: "",
    },
  },

  modalAlert: {
    modalOpen: false,
    modalTitle: "",
    modalContent: "",
    modalSubText: "",
    modalSuccess: "",
  },
};

export const natureOfBusinessList = {
  name: [
    "Accounting / Bookkeeping / Tax Practice",
    "Advertising / Marketing / Sales Activities",
    "Agriculture / Hunting / Forestry / Animal Farming / Fishing",
    "Banking - Foreign Banks",
    "Beauty and Health Services (like Spa, Beauty Parlors, Fitness Centers)",
    "Banking — Local — Rural",
    "Banking — Local — Universal / Commercial / Savings",
    "Banking",
    "Business Process Outsourcing (like Cal Centers, Billing / Credit / Collections)",
    "Brokerage",
    "Charities",
    "Commodities Trader",
    "Communication (Telecommunications includes postal)",
    "Consultancy (like Computer-related Consultancy-hardware/software, Technical Engineering/Architectural, Scientific Related Consultation, Business Consultancy)",
    "Construction (like Building, Plumbing, Electrical, Carpentry)",
    "Education (including private tutorials, special education)",
    "Economically Inactive (Not employed or no business)",
    "Embassies / Foreign Consulates",
    "Entertainment (Recreational / Cultural / Sporting Services like Party Planning Services, Event Organizing, Carnival Rides Rental, Fireworks, Displays, Media Network, Film Production)",
    "Food Industry / Food Manufacturing / Food Preparation / Processing / Food Packaging",
    "Financial Services",
    "FX Dealer / Money Changer",
    "Gaming Clubs / Casino",
    "Government Service — NP",
    "Insurance Activities",
    "Legal Practice",
    "Leasing / Rental Activities (like Land, Building, Machinery, etc.)",
    "Medical Services (includes caregiving)",
    "Manufacturing (Non-food, like Garments, Vehicles, Jewelry, Heavy Equipment, etc.)",
    "Military NP",
    "Mining",
    "Manning or Employment Agencies Foreign",
    "Manning or Employment Agencies Local",
    "Foundation (NGO)",
    "Publishing / Printing / Reproduction of Recorded Media",
    "Pawnshops",
    "Recycling",
    "Real Estate (like development, sales, etc.)",
    "Remittance Agent",
    "Repairs Services",
    "Sanitation / Cleaning / Housekeeping Services (like Janitorial Services, Building Maintenance, Laundry, etc.)",
    "Maritime or Shipping",
    "Tourism (like Hotels, Inns, Resorts, Tour Agencies, Restaurants)",
    "Wholesale / Retail Trade",
    "Transport (Air, Water or Land)",
    "Utilities (Electricity, Gas & Water Supply)",
    "Collection, Purification and Distribution of Water",
    "Others",
  ],
  value: [
    "Accounting / Bookkeeping / Tax Practice",
    "Advertising / Marketing / Sales Activities",
    "Agriculture / Hunting / Forestry / Animal Farming / Fishing",
    "Banking - Foreign Banks",
    "Beauty and Health Services (like Spa, Beauty Parlors, Fitness Centers)",
    "Banking — Local — Rural",
    "Banking — Local — Universal / Commercial / Savings",
    "Banking",
    "Business Process Outsourcing (like Cal Centers, Billing / Credit / Collections)",
    "Brokerage",
    "Charities",
    "Commodities Trader",
    "Communication (Telecommunications includes postal)",
    "Consultancy (like Computer-related Consultancy-hardware/software, Technical Engineering/Architectural, Scientific Related Consultation, Business Consultancy)",
    "Construction (like Building, Plumbing, Electrical, Carpentry)",
    "Education (including private tutorials, special education)",
    "Economically Inactive (Not employed or no business)",
    "Embassies / Foreign Consulates",
    "Entertainment (Recreational / Cultural / Sporting Services like Party Planning Services, Event Organizing, Carnival Rides Rental, Fireworks, Displays, Media Network, Film Production)",
    "Food Industry / Food Manufacturing / Food Preparation / Processing / Food Packaging",
    "Financial Services",
    "FX Dealer / Money Changer",
    "Gaming Clubs / Casino",
    "Government Service — NP",
    "Insurance Activities",
    "Legal Practice",
    "Leasing / Rental Activities (like Land, Building, Machinery, etc.)",
    "Medical Services (includes caregiving)",
    "Manufacturing (Non-food, like Garments, Vehicles, Jewelry, Heavy Equipment, etc.)",
    "Military NP",
    "Mining",
    "Manning or Employment Agencies Foreign",
    "Manning or Employment Agencies Local",
    "Foundation (NGO)",
    "Publishing / Printing / Reproduction of Recorded Media",
    "Pawnshops",
    "Recycling",
    "Real Estate (like development, sales, etc.)",
    "Remittance Agent",
    "Repairs Services",
    "Sanitation / Cleaning / Housekeeping Services (like Janitorial Services, Building Maintenance, Laundry, etc.)",
    "Maritime or Shipping",
    "Tourism (like Hotels, Inns, Resorts, Tour Agencies, Restaurants)",
    "Wholesale / Retail Trade",
    "Transport (Air, Water or Land)",
    "Utilities (Electricity, Gas & Water Supply)",
    "Collection, Purification and Distribution of Water",
    "Others",
  ],
};
  export const optionSelectedID = {
    name: ["TIN", "SSS/GSIS" ],
    value: ["TIN", "SSS"],
  };
  export const optionSelectedAnnualIncome = {
    name: [
        "PHP 50,000 or less",
        "PHP 50,001 - PHP 100,000",
        "PHP 100,001 - PHP 500,000",
        "PHP 500,001 - PHP 1,000,000",
        "PHP 1,000,001 - PHP 5,000,000",
        "5,000,001 - PHP 10,000,000",
        "PHP 10,000,001 or more",
      ],
    value: [1, 2,3,4,5,6,7],
  };
