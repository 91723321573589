import * as ROUTES from "../../constants/route";
import axios from "axios";
import Helpers from "../Helpers";

export default class Functions {
  constructor() {
    this.helpers = new Helpers();
  }

  doReadNotification = (notifId) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${ROUTES.FUNCTION_BASE_URL}notification-notifications/readNotification`,
          JSON.stringify({ notificationUid: notifId }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.ok) resolve(res);
          else reject(res.data.message);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  doReadAllNotifications = (uid) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${ROUTES.FUNCTION_BASE_URL}notification-notifications/readAllNotification`,
          JSON.stringify({ userUid: uid }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.ok) resolve(res);
          else reject(res.data.message);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
