import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";


const DialogsTitle = withStyles((theme) => ({
  root: {
    margin: "10px 0px 0px 10px",
  },
}))(DialogTitle);

const DialogsContent = withStyles((theme) => ({
  root: {
    margin: "-20px 20px 20px 20px",
    padding: "0px 10px 10px 10px",
    fontSize: "12px",
    fontWeight: "900",
    lineHeight: "9px",
    color: "black !important",
  },
}))(DialogContent);


export default function GifModal({
  text,
  onClose,
  gifLink,
}) {
 

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogsTitle>
        <span className=" text-2xl font-poppins font-extrabold"> Preview</span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ float: "right", bottom: "20px", right: "-20px" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogsTitle>

      <DialogsContent dividers>
        <p className="font-normal text-base inline-block align-middle ml-2">
           {text}
        </p>
        <div className="relative justify-center text-center">
          <img src={gifLink} alt="Instruction Image" className=" w-80 lg:w-full" />
        </div>
      </DialogsContent>
    </Dialog>
  );
}
