import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import {  IconButton } from "@material-ui/core";

import WebImg from "../../assets/maintenance/Mob_375x360px.png";
import Logo from "../../images/Ramp_Logo.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpComing({ upComing, onChange }) {

  const startDate = moment(upComing.startDate.toDate());
  const endDate = moment(upComing.endDate.toDate());

  return (
    <div>
      <Dialog
        open={upComing.hasUpcoming}
        TransitionComponent={Transition}
        maxWidth={"md"}
        keepMounted
        onClose={onChange}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onChange}
            style={{ float: "right", bottom: "5px" }}
          >
            <CloseIcon />
          </IconButton>
          <img
            className="w-1/3 px-0 pt-0 md:w-auto md:px-3 lg:w-auto lg:px-12 "
            src={Logo}
            alt="rampver-logo"
          />
          <div className="px-0 text-xl font-poppins font-extrabold md:text-xl md:px-3 lg:text-center lg:text-3xl lg:px-6">
            Rampver Online will be undergoing improvements!
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText >
            <div className="px-0 lg:px-16 lg:text-base text-black">
              We will be having a routine maintenance activity from {""}
              {startDate && (
                <span className=" font-bold">
                  {startDate.format("h:mm A ")}
                </span>
              )}
              {endDate && (
                <>
                  to
                  <span className=" font-bold">
                    {" "}
                    {endDate.format("h:mm A ")}
                  </span>
                </>
              )}
              {startDate && (
                <>
                  on
                  <span className=" font-bold">
                    {` ${startDate.format(
                      "MMMM Do, YYYY"
                    )} (Philippine Standard Time) `}
                  </span>
                </>
              )}
              and the system will be unavailable during that time. Please
              process your investments accordingly.
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  className=" w-4/5 px-0 mb-5 mt-5 md:w-auto md:px-3 lg:w-auto lg:px-12 "
                  src={WebImg}
                  alt="maintenance-img"
                />
              </div>
              We apologize for the inconvenience and appreciate your patience.
              Thank you for using Rampver Online to simplify your journey to
              wealth creation!
            </div>
          </DialogContentText>
         
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", marginBottom: "20px" }}
        >
          <Button
            type="secondary"
            variant="contained"
            color="primary"
            
           style={{color:"white", width:"150px"}}
            onClick={onChange}
            
          >Got it!</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
