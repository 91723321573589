// Constants
import { COLOR_SUCCESS, COLOR_PRIMARY } from "@/constants/colors";

import { useEffect, useRef, useState } from "react";
import { ReactNode } from "react";

import Button from "@/components/ui/Button";
import YoutubeEmbed from "@/components/YoutubeEmbed";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import SignUpContainer from "./SignupSection";
import LandingLayout from "../LandingLayout";
import ArticleCarousel from "./ArticleCarousel";

import FullyDigitalExperiencePNG from "@/assets/landing/fully-digital-experience.png";
import IndustryLeadingFundsPNG from "@/assets/landing/industry-leading-funds.png";
import CertifiedAdvisoryPNG from "@/assets/landing/certified-advisory.png";
import AtramPNG from "@/assets/landing/atram.png";
import PhilEquityPNG from "@/assets/landing/phil-equity.png";
import SunLifePNG from "@/assets/landing/sun-life.png";
import CMIPNG from "@/assets/landing/cmi.png";
import ArrowRightPNG from "@/assets/landing/arrow-right.png";
import CreateRampverAccountPNG from "@/assets/landing/create-rampver-account.png";
import CompleteClientApplicationPNG from "@/assets/landing/complete-client-application.png";
import StartInvesting from "@/assets/landing/start-investing.png";
import CreateRampverAccountArrow from "@/assets/landing/create-rampver-account-with-arrow.png";
import CompleteClientApplicationArrow from "@/assets/landing/complete-client-application-with-arrow.png";

import RampverFinancialsRegisteredWithSEC from "@/assets/landing/sec.png";
import RampverFinancialsRegisteredWithIC from "@/assets/landing/ic.png";
import RampverFinancialsRegisteredWithAMLC from "@/assets/landing/amlc.png";
import RampverFinancialsRegisteredWithNPC from "@/assets/landing/npc.png";
import ViberIcon from "@/assets/icons/viber-white.png";

import { Grid, GridSize, useMediaQuery, useTheme } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

import AOS from "aos";
import "aos/dist/aos.css";

const RAMPVER_FINANCIALS_REGISTERED_WITH = [
  {
    image: RampverFinancialsRegisteredWithSEC,
    title: "Securities and Exchange Commission"
  },
  {
    image: RampverFinancialsRegisteredWithIC,
    title: "Insurance Commission"
  },
  {
    image: RampverFinancialsRegisteredWithAMLC,
    title: "Anti-Money Laundering Council"
  },
  {
    image: RampverFinancialsRegisteredWithNPC,
    title: "National Privacy Commission"
  }
];

const RAMPVER_ONLINE_STEPS = [
  {
    image: CreateRampverAccountPNG,
    alt: CreateRampverAccountArrow,
    title: "Create a Rampver Online Account",
    description: "Register and verify your email address"
  },
  {
    image: CompleteClientApplicationPNG,
    alt: CompleteClientApplicationArrow,
    title: "Complete Client Application (KYC)",
    description: "Accomplish application form as quickly as 5-10 minutes"
  },
  {
    image: StartInvesting,
    title: "Start investing in a recommended fund!",
    description: "Choose your fund, proceed to payment, and monitor performance"
  }
];

const WHY_RAMPVER_ONLINE_CARDS = [
  {
    image: FullyDigitalExperiencePNG,
    title: "Fully Digital Experience",
    description: "End-to-end from account opening to investing, and monitoring"
  },
  {
    image: IndustryLeadingFundsPNG,
    title: "Industry-Leading Funds",
    description: "Partnered with the country’s best fund providers"
  },
  {
    image: CertifiedAdvisoryPNG,
    title: "Certified Advisory",
    description: "We guide you towards achieving your financial goals"
  }
];

const INVESTMENT_PARTNERS = [
  {
    image: AtramPNG,
    title: "ATRAM"
  },
  {
    image: SunLifePNG,
    title: "Sun Life Management"
  },
  {
    image: PhilEquityPNG,
    title: "Phil-Equity"
  },
  {
    image: CMIPNG,
    title: "CMI"
  }
];

export default function LandingPage() {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (window.location.hash && sectionRef.current) {
      const hash = window.location.hash.substring(1);
      const element = document.getElementById(hash);
      if (element) {
        const adjustedScrollPosition: number =
          sectionRef.current.getBoundingClientRect().top - 50;
        window.scrollTo({
          top: window.scrollY + adjustedScrollPosition,
          behavior: "smooth"
        });
      }
    }
  }, [window.location.href]);

  return (
    <LandingLayout>
      <SignUpContainer />

      <div
        style={{
          padding: isMobile ? "20px 25px 20px" : "30px 225px 50px",
          maxWidth: "100%"
        }}
      >
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className="centered-flex-column"
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: isMobile ? "center" : "flex-start"
              }}
            >
              <h1
                style={{
                  color: COLOR_SUCCESS,
                  fontSize: isMobile ? "1.5rem" : "2rem"
                }}
              >
                What is Rampver Online
              </h1>
            </div>

            <p style={{ fontSize: isMobile ? "18px" : "24px" }}>
              Rampver Online is your companion to simplify your journey to
              wealth creation! It is Rampver Financials’ fully-digital
              investment platform that gives you easy access to Rampver’s
              industry-leading partners and products with the essential guidance
              to achieve your financial goals.
            </p>

            {isMobile ? (
              ""
            ) : (
              <Button
                fullWidth
                href="https://rampver.com/home/"
                target="_blank"
                variant="contained"
                color="secondary"
                style={{ padding: "25px" }}
              >
                <span style={{ fontSize: "20px" }}>
                  Click here to know more about Rampver Financials
                </span>
              </Button>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <YoutubeEmbed embedId="JL27zluj_qQ" />
          </Grid>
          {isMobile ?? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                fullWidth
                href="https://rampver.com/home/"
                target="_blank"
                variant="contained"
                color="primary"
              >
                Click here to know more about Rampver Financials
              </Button>
            </Grid>
          )}
        </Grid>
      </div>

      <div
        style={{
          padding: isMobile ? "20px 25px 20px" : "30px 225px 50px",
          backgroundColor: COLOR_SUCCESS,
          maxWidth: "100%",
          color: "white"
        }}
      >
        <Grid data-aos="fade-up" container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="centered-flex-column"
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: isMobile ? "center" : "flex-start"
              }}
            >
              <h1 style={{ fontSize: isMobile ? "1.5rem" : "2rem" }}>
                Why Rampver Online?
              </h1>
            </div>
          </Grid>

          {WHY_RAMPVER_ONLINE_CARDS.map((card, index) => (
            <WhyRampverOnlineCard
              key={index}
              image={card.image}
              title={card.title}
              description={card.description}
            />
          ))}
        </Grid>
      </div>

      <div
        style={{
          padding: isMobile ? "20px 25px 20px" : "30px 225px 50px",
          maxWidth: "100%"
        }}
      >
        <Grid data-aos="fade-up" container spacing={5}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="centered-flex-column"
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: isMobile ? "center" : "flex-start"
              }}
            >
              <h1
                style={{
                  color: COLOR_SUCCESS,
                  fontSize: isMobile ? "1.5rem" : "2rem"
                }}
              >
                Our Investment Partners
              </h1>
            </div>
          </Grid>

          {INVESTMENT_PARTNERS.map((partner, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={6}
              key={index}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <img key={index} src={partner.image} alt={partner.title} style={{ maxWidth: '100%', height: 'auto' }}  />
            </Grid>
          ))}
        </Grid>
      </div>

      <div
        style={{
          padding: isMobile ? "20px 25px 20px" : "30px 225px 50px",
          maxWidth: "100%",
          backgroundColor: "#F2F2F2"
        }}
      >
        <Grid data-aos="fade-up" container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="centered-flex-column"
          >
            <h1
              style={{
                color: COLOR_SUCCESS,
                display: "flex",
                width: "100%",
                justifyContent: isMobile ? "center" : "flex-start",
                fontSize: isMobile ? "1.5rem" : "2rem"
              }}
            >
              3 Easy Steps to Invest in Rampver Online
            </h1>
          </Grid>

          {RAMPVER_ONLINE_STEPS.map((step, index) => (
            <>
              {useMediaQuery(theme.breakpoints.down("lg")) ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="centered-flex-column"
                  >
                    <img src={step.image} alt={step.title} />
                    <h3
                      style={{
                        color: COLOR_SUCCESS
                      }}
                    >
                      {step.title}
                    </h3>
                    <p className="text-sm">{step.description}</p>
                  </Grid>

                  {index !== RAMPVER_ONLINE_STEPS.length - 1 && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="centered-flex-column"
                    >
                      <img
                        src={ArrowRightPNG}
                        alt="arrow right"
                        style={{
                          transform: "rotate(90deg)",
                          width: "auto",
                          height: "50px",
                          padding: "30px 0px"
                        }}
                      />
                    </Grid>
                  )}
                </>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={4}
                  xl={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <div className="text-center">
                    {step?.alt ? (
                      <img
                        style={{ marginLeft: "140px" }}
                        src={step.alt}
                        alt={step.title}
                      />
                    ) : (
                      <img src={step.image} alt={step.title} />
                    )}
                    <h3
                      style={{
                        color: COLOR_SUCCESS,
                        maxWidth: "20ch",
                        marginInline: "auto"
                      }}
                    >
                      {step.title}
                    </h3>
                    <p
                      className="text-sm"
                      style={{ maxWidth: "18rem", marginInline: "auto" }}
                    >
                      {step.description}
                    </p>
                  </div>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </div>

      <div
        id="learning-center"
        data-aos="fade-up"
        style={{
          padding: isMobile ? "20px 25px 10px" : "30px 225px 50px",
          maxWidth: "100%"
        }}
        ref={sectionRef}
      >
        <h1
          style={{
            color: COLOR_SUCCESS,
            fontSize: isMobile ? "1.5rem" : "2rem"
          }}
        >
          Rampver Learning Center
        </h1>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12}>
            <ArticleCarousel />
          </Grid>

          {/* {RAMPVER_LEARNING_CENTER_CARDS.map((card, index) => (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
              <Card style={{ maxWidth: "100%" }}>
                <img className="w-full" src={card.image} title={card.title} />

                <CardContent>
                  <p className="font-semibold m-0">{card.title}</p>
                  <p
                    className="text-sm"
                    style={{
                      minHeight: "3em"
                    }}
                  >
                    {card.description}
                  </p>
                </CardContent>
                <CardActions>
                  <Button
                    target="_blank"
                    href={card.href}
                    variant={undefined}
                    size="small"
                    style={{
                      color: COLOR_PRIMARY
                    }}
                  >
                    Find out more &gt;&gt;&gt;
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))} */}
        </Grid>
      </div>

      <div
        style={{
          padding: isMobile ? "20px 25px 20px" : "50px 225px 50px",
          maxWidth: "100%"
        }}
      >
        <h2>Frequently Asked Questions</h2>

        <div className="flex flex-col mt-12 w-full">
          <div className="w-full">
            <Accordion question="How do I invest in mutual funds on Rampver Online?">
              <p className="m-0">
                Start investing in mutual funds on Rampver Online in just 3 easy
                steps!
              </p>
              <ul className="list-none mt-8">
                <li>
                  <span className="font-bold mr-2">Step 1:</span>
                  Create a Rampver Online account by registering & validating
                  your email address.
                </li>
                <li className="mt-2">
                  <span className="font-bold mr-2">Step 2:</span>
                  Accomplish the Client Application (KYC) and standby for our
                  approval within 2-3 business days.
                </li>
                <li className="mt-2">
                  <span className="font-bold mr-2">Step 3:</span>
                  Once your account is approved, start investing in a mutual
                  fund that we recommend just for you!
                </li>
              </ul>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion question="How do I pay for my Rampver Online investment?">
              <p className="m-0">
                You can pay for your Rampver Online investment with any e-wallet
                or bank! Rampver Online accepts payments from Unionbank, GCash,
                Maya, or any of your favorite banks via InstaPay or PESONet.
              </p>
              <p className="m-0">
                Here’s how to pay for your Rampver Online investment:
              </p>
              <ul className="list-none mt-8">
                <li>
                  <span className="font-bold mr-2">Step 1:</span>
                  Select the mutual fund you want to invest in. We will
                  recommend funds based on your investment objectives and risk
                  profile.
                </li>
                <li className="mt-2">
                  <span className="font-bold mr-2">Step 2:</span>
                  Indicate the amount you want to invest and select your
                  preferred payment method.
                </li>
                <li className="mt-2">
                  <span className="font-bold mr-2">Step 3:</span>
                  Follow the instructions to transfer your payment and upload
                  your confirmation details. We will process your investment on
                  the next business day.
                </li>
              </ul>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion question="Is there a minimum amount to invest on Rampver Online?">
              The minimum investment amount on Rampver Online is Php 1,000.00.
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion question="I am an existing Rampver client. Do I need to go through the application process on Rampver Online?">
              <p>
                Yes. Rampver Online is a new investment platform, so all clients
                are required to go through the application process. Rampver
                Online accounts are separate from the investment accounts that
                you opened manually through a Rampver Business Development
                Officer or Rampver Independent Financial Advisor.
              </p>

              <p className="mt-4">
                If you would like to know more about the status of your
                investments, you may directly contact your assigned Rampver
                Business Development Officer or Rampver Independent Financial
                Advisor or you may contact{" "}
                <span className="text-blue-500">info@rampver.com</span>.
              </p>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion question="Is it safe to invest on Rampver Online?">
              Yes. Rampver Online is at par with security and safety standards
              in the industry. Furthermore, Rampver Financials is regulated by
              the Securities and Exchange Commission (SEC), Anti-Money
              Laundering Council (AMLC), Insurance Commission (IC), National
              Privacy Commission (NPC), and adheres to their rules and
              regulations.
            </Accordion>
          </div>
        </div>

        <div className="text-center mt-16">
          <Button
            href="/faqs"
            size="large"
            style={{
              backgroundColor: COLOR_SUCCESS,
              paddingInline: "4em"
            }}
          >
            Click here to see more FAQs
          </Button>
        </div>
      </div>

      <div className="mt-4">
        <Divider />
      </div>

      <footer className="max-w-7xl mx-auto py-8" data-aos="fade-up">
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="text-center">
              <h3>We help people fulfill financial dreams.</h3>
              <p className="text-sm">
                &#169; Rampver Financials 2023 | All Rights Reserved
              </p>
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="text-center">
              <h3>Terms and Conditions | Data Privacy Policy</h3>
              <address className="text-sm">
                14B Petron Megaplaza Building,
                <br />
                Buendia Avenue, Makati City 1227
                <br />
                Metro Manila, Philippines
                <br />
                (+632) 8812-1995 | (+632)8894-1811
                <br />
                info@rampver.com
              </address>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: "45px" }}>
          {RAMPVER_FINANCIALS_REGISTERED_WITH.map((registeredWith, index) => (
            <Grid
              xs={6}
              sm={6}
              md={6}
              lg={3}
              xl={3}
              key={index}
              style={{ maxWidth: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "100%"
                }}
              >
                <img src={registeredWith.image} alt={registeredWith.title} />
              </div>
            </Grid>
          ))}
        </Grid>
      </footer>

      <div
        className="px-16 h-12 flex items-center mt-4"
        style={{
          backgroundColor: COLOR_SUCCESS
        }}
      >
        <p className="text-white flex-grow">
          {`Rampver Financials ${new Date().getFullYear()} | All rights reserved.`}
        </p>
        <div className="flex">
          <IconButton
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/rampverfinancials"
          >
            <FacebookIcon style={{ color: "white" }} />
          </IconButton>
          <IconButton
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/rampverfinancials"
          >
            <InstagramIcon style={{ color: "white" }} />
          </IconButton>
          <IconButton
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.viber.com/"
          >
            <img src={ViberIcon} alt="viber" />
          </IconButton>
        </div>
      </div>
    </LandingLayout>
  );
}

type WhyRampverOnlineCardProp = {
  image: string;
  title: string;
  description: string;
};

function WhyRampverOnlineCard({
  image,
  title,
  description
}: WhyRampverOnlineCardProp) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img src={image} alt={title} />
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </Grid>
  );
}

type AccordionProps = {
  question: string;
  className?: string;
  children?: ReactNode;
};

function Accordion({ question, className, children }: AccordionProps) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={`px-4 ${className}`}
      style={{
        border: "1px solid #E0E0E0"
      }}
    >
      <div className="flex">
        <p className="flex-grow font-semibold">{question}</p>

        <IconButton onClick={() => setExpanded((prev) => !prev)}>
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>

      {expanded && <div className="text-sm py-4">{children}</div>}
    </div>
  );
}
