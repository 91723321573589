import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function Download(props) {
  let myApp = props.data.my_application;

  let convertedData = [];
  let incomeSelection;

  let annIncome;
  switch (myApp.work.annualIncomeAmount) {
    case 1:
      annIncome = "PHP 50,000 or less";
      break;

    case 2:
      annIncome = "PHP 50,001 - PHP 100,000";
      break;

    case 3:
      annIncome = "PHP 100,001 - PHP 500,000";
      break;

    case 4:
      annIncome = "PHP 500,001 - PHP 1,000,000";
      break;

    case 5:
      annIncome = "More than PHP 1,000,000";
      break;

    default:
      annIncome = "N/A";
      break;
  }

  let savings;
  if (props.data.account_details.savingsAccount === "N/A")
    savings = props.data.account_details.savingsAccount;
  else
    savings = props.data.account_details.savingsAccount
      ? "Savings Account"
      : "Current Account";

  let managerCode = "N/A";
  let managerName = "N/A";
  if ("investmentManager" in myApp) {
    managerCode = myApp.investmentManager.investmentManagerId;
    managerName = myApp.investmentManager.investmentManagerName;
  }

  let data = {
    name: `${myApp.name.firstName} ${myApp.name.middleName} ${myApp.name.lastName}`,
    email: myApp.email,
    mobNum: `+63${myApp.mobileNumber}`,
    gender: myApp.gender,
    civilStatus: myApp.civil.civilStatus,
    birthday: myApp.dateOfBirth,
    placeOfBirth: myApp.placeOfBirth,
    nationality: myApp.nationality,
    SSS: myApp.ids.SSS,
    TIN: myApp.ids.TIN,
    currentAdd: `${myApp.currentAddress.street} ${myApp.currentAddress.barangay} ${myApp.currentAddress.zip}, ${myApp.currentAddress.city} ${myApp.currentAddress.province}`,
    permAdd: `${myApp.permanentAddress.street} ${myApp.permanentAddress.barangay} ${myApp.permanentAddress.zip}, ${myApp.permanentAddress.city} ${myApp.permanentAddress.province}`,
    businessAdd: `${myApp.businessAddress.street} ${myApp.businessAddress.barangay} ${myApp.businessAddress.zip}, ${myApp.businessAddress.city} ${myApp.businessAddress.province}`,

    occupation:
      myApp.work.primaryOccupation[0] + myApp.work.primaryOccupation[1],
    nameOfEmployer: myApp.work.nameOfEmployer,
    businessNature: myApp.work.businessNature,
    businessNum: myApp.work.businessNum,
    annualIncome: annIncome,
    sourceOfFunds: myApp.work.sourceOfFunds,

    investmentManagerId: managerCode,
    investmentManagerName: managerName,

    incomeSelection: incomeSelection,
    otherProducts: myApp.otherProducts,
    regularBasis: myApp.regularBasis,

    governmentWork: myApp.PEP.governmentWork ? "Yes" : "No",
    governmentRelative: myApp.PEP.governmentRelative ? "Yes" : "No",

    riskProfile: props.data.risk_profile,

    usCitizen: myApp.FATCA.usCitizen ? "Yes" : "No",
    usResident: myApp.FATCA.usResident ? "Yes" : "No",
    usAlien: myApp.FATCA.usAlien ? "Yes" : "No",
    usPhone: myApp.FATCA.usPhone ? "Yes" : "No",
    usBorn: myApp.FATCA.usBorn ? "Yes" : "No",
    usAddressResidence: myApp.FATCA.usAddressResidence ? "Yes" : "No",
    usAddressMailing: myApp.FATCA.usAddressMailing ? "Yes" : "No",
    usAccount: myApp.FATCA.usAccount ? "Yes" : "No",
    usPOA: myApp.FATCA.usPOA ? "Yes" : "No",
    usCareOfAddress: myApp.FATCA.usCareOfAddress ? "Yes" : "No",

    accName: props.data.account_details.accountName,
    bankName: props.data.account_details.bankName,
    bankAccNo: props.data.account_details.bankAccountNumber,
    savings: savings,
  };
  convertedData.push(data);
  console.log(data)

  return (
    <ExcelFile element={props.button} filename={props.filename}>
      <ExcelSheet data={convertedData} name="Personal Info">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Mobile Number" value="mobNum" />
        <ExcelColumn label="Gender" value="gender" />
        <ExcelColumn label="Civil Status" value="civilStatus" />
        <ExcelColumn label="Birthday" value="birthday" />
        <ExcelColumn label="Place of Birth" value="placeOfBirth" />
        <ExcelColumn label="Nationality" value="nationality" />
        <ExcelColumn label="SSS/GSIS" value="SSS" />
        <ExcelColumn label="TIN" value="TIN" />
        <ExcelColumn label="Current Address" value="currentAdd" />
        <ExcelColumn label="Permanent Address" value="permAdd" />
        <ExcelColumn label="Work or Business Address" value="businessAdd" />
      </ExcelSheet>
      <ExcelSheet data={convertedData} name="Professional Details">
        <ExcelColumn label="Occupation" value="occupation" />
        <ExcelColumn label="Name Of Employer" value="nameOfEmployer" />
        <ExcelColumn label="Nature of Business" value="businessNature" />
        <ExcelColumn label="Office Tel. Number" value="businessNum" />
        <ExcelColumn label="Source of Funds" value="sourceOfFunds" />
      </ExcelSheet>
      <ExcelSheet data={convertedData} name="Investment Info">
        <ExcelColumn label="Risk Profile" value="riskProfile" />
        <ExcelColumn
          label="Investment Manager ID"
          value="investmentManagerId"
        />
        <ExcelColumn
          label="Investment Manager Name"
          value="investmentManagerName"
        />
      </ExcelSheet>
      <ExcelSheet data={convertedData} name="Financial Info">
        <ExcelColumn label="Annual Income" value="annualIncome" />
        <ExcelColumn label="Other Funds/Projects?" value="otherProducts" />
        <ExcelColumn
          label="Buying More Shares On A Regular Basis?"
          value="regularBasis"
        />
        <ExcelColumn
          label="Working In A Gov't Agency?"
          value="governmentWork"
        />
        <ExcelColumn label="Relatives in Gov't?" value="governmentRelative" />
      </ExcelSheet>
      <ExcelSheet data={convertedData} name="FATCA">
        <ExcelColumn label="US Citizen?" value="usCitizen" />
        <ExcelColumn label="US Resident?" value="usResident" />
        <ExcelColumn label="US Resident Alien?" value="usAlien" />
        <ExcelColumn label="US Phone Number?" value="usPhone" />
        <ExcelColumn label="Born in the USA?" value="usBorn" />
        <ExcelColumn
          label="Current US Resident Address?"
          value="usAddressResidence"
        />
        <ExcelColumn
          label="Current US Mailing Address?"
          value="usAddressMailing"
        />
        <ExcelColumn label="US Account?" value="usAccount" />
        <ExcelColumn
          label="Power Of Attorney Or Granted Signatory Authority To A Person With US Address?"
          value="usPOA"
        />
        <ExcelColumn label="In Care Of Address?" value="usCareOfAddress" />
      </ExcelSheet>
      <ExcelSheet data={convertedData} name="Account Details">
        <ExcelColumn label="Account Name" value="accName" />
        <ExcelColumn label="Bank Name" value="bankName" />
        <ExcelColumn label="Bank Account Number" value="bankAccNo" />
        <ExcelColumn label="Type of Account" value="savings" />
      </ExcelSheet>
    </ExcelFile>
  );
}
