import React from "react";
import { FormControl, Fade, FormHelperText } from "@material-ui/core";
import { ValidatorComponent } from "react-material-ui-form-validator";

class RadioField extends ValidatorComponent {
  renderValidatorComponent() {
    const { label, onChange, className, value } = this.props;
    return (
      <div>
        <FormControl component="fieldset" value={value}>
          <label>{label}</label>
          <div className={className} onChange={onChange}>
            {this.props.children}
          </div>
        </FormControl>
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <Fade in={true}>
        <FormHelperText style={{ color: "red" }}>
          {this.getErrorMessage()}
        </FormHelperText>
      </Fade>
    );
  }
}

export default RadioField;
