import React from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function ExportTransactionData(props) {
  return (
    <ExcelFile element={props.button} filename={props.filename}>
      <ExcelSheet data={props.data} name={"My Transactions"}>
        <ExcelColumn label="Transaction ID" value="trans_id" />
        <ExcelColumn
          label="Date Processed"
          value={(value) => {
            return value.process_date === "-"
              ? "n/a"
              : moment.unix(value.process_date).format("MM/DD/YYYY HH:MM");
          }}
        />
        <ExcelColumn label="Fund Name" value="fund_name" />
        <ExcelColumn label="Amount Invested / Redeemed" value="amount" />
        <ExcelColumn label="Status" value="status" />
      </ExcelSheet>
    </ExcelFile>
  );
}
